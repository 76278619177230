import React, { createContext, useState } from "react";
import axios from "axios";
import { backendUrl } from "../contants/contants";
 import { toast } from "react-toastify";
export const searchResumeLogContext = createContext({});
const SearchResumeLogProvider = ({ children }) => {

  const [loading, setLoading] = useState(false);
  const [searchResumeData, setSearchResumeData] = useState([]);
  const [searchResumeCount, setSearchResumeCount] = useState(0);

  const handleSearchResumeLogs = (data) => {
    setLoading(true);
    axios
      .post(backendUrl + "/api/crm/searchresume/list", data)
      .then((response) => {
        setSearchResumeData(response.data.data.searchResumelogList);
        setSearchResumeCount(response.data.data.count);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  return (
    <searchResumeLogContext.Provider
      value={{
        loading,
        handleSearchResumeLogs,
        searchResumeData,
        searchResumeCount,
      }}
    >
      {children}
    </searchResumeLogContext.Provider>
  );
};
export default SearchResumeLogProvider
