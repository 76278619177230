import React, { useContext, useState, useEffect } from "react";
import { planContext } from "../../contexts/PlanContext";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  Button,
  Slide,
  DialogTitle,
  CircularProgress,
  Stack,
  Box,
  TextField,
  Autocomplete,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function PlanEdit() {
  const {
    loading,
    viewPlans,
    plansEdit,
    handleEditPlans,
    closePlanEditModel,
    showPlanEdit,
  } = useContext(planContext);
  const params = useParams();
  useEffect(() => {
    plansEdit(viewPlans?._id);
  }, []);

  useEffect(() => {
    setValues({
      name: viewPlans?.name ? viewPlans?.name : "",
      planType: viewPlans?.planType ? viewPlans?.planType : null,
      expiryDays: viewPlans?.expiryDays ? viewPlans?.expiryDays : "",
      numberOfResume: viewPlans?.numberOfResume
        ? viewPlans?.numberOfResume
        : 0,
      numberOfHiring: viewPlans?.numberOfHiring
        ? viewPlans?.numberOfHiring
        : "",
      discount: viewPlans?.discount ? viewPlans?.discount : 0,

      status: viewPlans?.status ? viewPlans?.status : false,
      view: viewPlans?.view ? viewPlans?.view : 0,
      planDetails: viewPlans?.planDetails?.benefits
        ? viewPlans?.planDetails?.benefits
        : "",
      amount: viewPlans?.amount ? viewPlans?.amount : 0,
      discountedAmount: viewPlans?.discountedAmount
        ? viewPlans?.discountedAmount
        : 0,
    });
  }, [viewPlans]);

  const [values, setValues] = useState({});

  const handleSubmit = () => {
    handleEditPlans(values, viewPlans?._id);
    closePlanEditModel();
  };
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleChangeAutoComplete = (prop, value) => {
    setValues({ ...values, [prop]: value });
  };
  let navigate = useNavigate();
  return (
    <Dialog
      maxWidth={"lg"}
      open={showPlanEdit}
      onClose={closePlanEditModel}
      aria-labelledby="customized-dialog-title"
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          fontWeight: "bold",
          background: "rgb(35, 48, 68)",
          color: "White",
          textAlign: "center",
        }}
        id="customized-dialog-title"
      >
        PLANS EDIT
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closePlanEditModel}
        sx={{
          position: "absolute",
          right: 8,
          top: 5,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers sx={{ height: "100vh" }}>
        {loading ? (
          <CircularProgress
            className="Loading"
            loading={loading}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
            sx={{ marginLeft: "40%" }}
          />
        ) : (
          <Box>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-evenly"
              marginTop={1.5}
            >
              <Stack direction="column">
                <label>Plan Name: </label>
                <TextField
                  sx={{ width: "25vw" }}
                  size="small"
                  placeholder="Plan Name"
                  value={values.name}
                  onChange={handleChange("name")}
                ></TextField>
              </Stack>

              <Stack direction="column">
                <label>Plan Type: </label>
                <Autocomplete
                  sx={{ width: "25vw" }}
                  size="small"
                  options={planType}
                  getOptionLabel={(option) => option.label}
                  value={
                    planType.find(
                      (planType) => planType.value === values?.planType
                    ) || null
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("planType", value?.value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Plan Type" />
                  )}
                />
              </Stack>
              <Stack direction="column">
                <label>Expiry Days: </label>
                <TextField
                  sx={{ width: "25vw" }}
                  size="small"
                  type="number"
                  inputProps={{ min: 0 }}
                  placeholder="Expiry Days"
                  value={values.expiryDays}
                  onChange={handleChange("expiryDays")}
                ></TextField>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-evenly"
              marginTop={1.5}
            >
              <Stack direction="column">
                <label>Number Of Resume: </label>
                <TextField
                  sx={{ width: "25vw" }}
                  size="small"
                  type="number"
                  inputProps={{ min: 0 }}
                  placeholder="Number Of Resume"
                  value={values?.numberOfResume}
                  onChange={handleChange("numberOfResume")}
                />
              </Stack>
              <Stack direction="column">
                <label>Number Of Hiring: </label>
                <TextField
                  sx={{ width: "25vw" }}
                  size="small"
                  type="number"
                  inputProps={{ min: 0 }}
                  placeholder="Number Of Hiring"
                  value={values?.numberOfHiring}
                  onChange={handleChange("numberOfHiring")}
                />
              </Stack>
              <Stack direction="column">
                <label>Discount: </label>
                <TextField
                  sx={{ width: "25vw" }}
                  size="small"
                  type="number"
                  inputProps={{ min: 0 }}
                  placeholder="Discount"
                  value={values.discount}
                  onChange={handleChange("discount")}
                ></TextField>
              </Stack>
            
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-evenly"
               marginTop={1.5}
            >
                <Stack direction="column">
                <label>Amount: </label>
                <TextField
                  sx={{ width: "19vw" }}
                  size="small"
                  type="number"
                  inputProps={{ min: 0 }}
                  placeholder="Amount"
                  value={values?.amount}
                  onChange={handleChange("amount")}
                />
              </Stack>
               <Stack direction="column">
                <label>Discounted Amount: </label>
                <TextField
                  sx={{ width: "18vw" }}
                  size="small"
                  type="number"
                  inputProps={{ min: 0 }}
                  placeholder="Discounted Amount"
                  value={values?.discountedAmount}
                  onChange={handleChange("discountedAmount")}
                />
              </Stack>
              <Stack direction="column">
                <label>View: </label>
                <TextField
                  sx={{ width: "18vw" }}
                  size="small"
                  type="number"
                  inputProps={{ min: 0 }}
                  placeholder="View"
                  value={values?.view}
                  onChange={handleChange("view")}
                />
              </Stack>
              <Stack direction="column">
                <label>Status: </label>
                <Autocomplete
                  sx={{ width: "19vw" }}
                  size="small"
                  options={Status}
                  getOptionLabel={(option) => option.label}
                  value={Status.find(
                    (status) => status.value === values?.status
                  )}
                  onChange={(event, value) => {
                    handleChangeAutoComplete("status", value?.value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="status" />
                  )}
                />
              </Stack>
             
            </Stack>

            <Stack
              direction="row"
              spacing={3}
              justifyContent="space-evenly"
              sx={{ marginTop: "10px" }}
            >
              <Stack direction="column">
                <label>Benefits: </label>
                <TextField
                  sx={{ width: "76.5vw" }}
                  size="small"
                  placeholder="Benefits"
                  value={values.planDetails}
                  onChange={handleChange("planDetails")}
                ></TextField>
              </Stack>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{
                marginTop: "15px",
                justifyContent: "center",
              }}
            >
              <Button
               className="search-button"
               variant="contained"
                onClick={handleSubmit}
              >
                update
              </Button>
              <Button
               className="reset-button"
               variant="contained"
                onClick={closePlanEditModel}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}

const Status = [
  { label: "Active", value: true },
  { label: "Inactive", value: false },
];

const planType = [
  { label: "JobPosting", value: "JobPosting" },
  { label: "Database", value: "Database" },
];
