import React, { createContext, useState } from "react";
import axios from "axios";
import { backendUrl } from "../contants/contants";

export const jobApplicationContext = createContext({});

const JobApplicationProvider = ({ children }) => {
  
  const [loading, setLoading] = useState(false);
  const [jobApplicationData, setJobApplicationData] = useState([]);
  const [jobApplicationCount, setJobApplicationCount] = useState(0);
  const handleSearchJobApplication = (data) => {
    setLoading(true);
    axios
      .post(backendUrl + "/api/crm/jobApplication/list", data)
      .then((response) => {
        setJobApplicationData(response.data.data.jobApplications);
        setJobApplicationCount(response.data.data.count);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  return (
    <jobApplicationContext.Provider
      value={{
        loading,
        handleSearchJobApplication,
        jobApplicationData,
        jobApplicationCount,
      }}
    >
      {children}
    </jobApplicationContext.Provider>
  );
};
export default JobApplicationProvider;
