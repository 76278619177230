import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useContext } from "react";
import { authContext } from "../contexts/AuthContext";
import { toast } from "react-toastify";
import loginimage from "../images/loginimage.jpg";
import { appContext } from "../contexts/AppContext";
import CircleAppLogo from "../images/CircleAppLogo.png";

export default function Login() {
  let navigate = useNavigate();
  const { loading } = useContext(appContext);
  const { setAuthData } = useContext(authContext);

  const [values, setValues] = React.useState({
    pass: "",
    email: "",
    showPassword: false,
  });

  useEffect(() => {
    // localStorage.removeItem('auth_token')
  })
  
  const handleLogin = (event) => {
    event.preventDefault();
    axios
      .post("https://staging.crm.theincircle.com/api/crm/login", values)
      .then(function (response) {
        console.log(response);
        if (response.data.status === 200) {
          localStorage.setItem("auth_token", response.data?.data?.auth_token);
          localStorage.setItem(
            "userInfo",
            JSON.stringify(response.data?.data?.userInfo)
          );
          navigate("/dashboard");
          setAuthData(response.data?.data.userInfo);
          toast.success("Successfully logged in!");
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((error) => {});
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={0}
      sx={{ height: "100vh", width: "100vw", backgroundColor: "black" }}
    >
      <Paper
        sx={{ width: "64vw", height: "96vh", borderRadius: "5px 0px 0px 5px" }}
      >
        <Stack justifyContent="center" alignItems="center" direction="row">
          <Paper elevation={5} className="paper-image" >
            <img
              src={`${loginimage}?fit=crop&auto=format`}
              srcSet={`${loginimage}?fit=crop&auto=format&dpr=2 2x`}
              alt={"logo"}
              loading="lazy"
              className="login-image"
            />
          </Paper>
        </Stack>
      </Paper>

      <Paper
        sx={{ width: "34vw", height: "96vh", borderRadius: "0px 5px 5px 0px" }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ marginTop: "20vh" }}
        >
          <Container>
            <Typography
              color="Black"
              variant="h4"
              align="center"
              sx={{
                letterSpacing: "0px",
                fontSize: "600",
                fontWeight: "bold",
                lineHeight: "1.334",
                color: "#3a3541",
              }}
            >
              Welcome to CRM !👋🏻
            </Typography>
            <Typography
  
              align="center"
              sx={{
                letterSpacing: "0px",
                fontSize: " 0.875rem",
                fontWeight: "400",
                lineHeight: "1.5",
                color: "#3a3541",
              }}
            >
               Please sign-in to your account and start the adventure
            </Typography>
          </Container>
        
          <img
            src={`${CircleAppLogo}?fit=crop&auto=format`}
            srcSet={`${CircleAppLogo}?fit=crop&auto=format&dpr=2 2x`}
            alt={"logo"}
            loading="lazy"
            className="imglogin"
          />

          <FormControl sx={{ m: 1, width: "22vw" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
            <OutlinedInput
              id="outlined-adornment-email"
              type="text"
              value={values.email}
              onChange={handleChange("email")}
              endAdornment={
                <InputAdornment position="end">
                  <AccountCircleIcon />
                </InputAdornment>
              }
              autoFocus={true}
              label="Email"
            />
          </FormControl>
          <FormControl
            sx={{ m: 1, width: "22vw", borderRadius: "2px" }}
            variant="outlined"
            // onClick={handleLogin}
          >
            <InputLabel>Password</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={values.pass}
              onChange={handleChange("pass")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <LoadingButton
            className="login"
            loading={loading}
            // loading={loading ? 'true': 'false'}
            loadingPosition="center"
            onClick={handleLogin}
            variant="contained"
          >
            LOGIN
          </LoadingButton>
        </Stack>
      </Paper>
    </Stack>
  );
  
}