import React, { useContext, useState, useEffect } from "react";
import { appContext } from "../../contexts/AppContext";
import { skillContext } from "../../contexts/SkillContext";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  Dialog,
  Switch,
  DialogContent,
  Typography,
  Button,
  Slide,
  DialogTitle,
  CircularProgress,
  Stack,
  Box,
  TextField,
  Autocomplete,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
export default function SkillEdit() {
  const {
    profile,
    employeeProfile,
    loading,
    employeeLocation,
    functionalArea,
    editFunctional,
    editIndustry,

    editQualification,
  } = useContext(appContext);
  const {
    skillEdit,
    viewSkill,
    handleEditSkill,
    closeSkillEditModel,
    showSkillEdit,
  } = useContext(skillContext);
  const params = useParams();
  useEffect(() => {
    employeeProfile();
    employeeLocation();
    editFunctional();
    editIndustry();
    editQualification();
    skillEdit(viewSkill?._id);
  }, []);

  useEffect(() => {
    setValues({
      functionalArea: viewSkill?.functionalArea
        ? viewSkill?.functionalArea
        : null,
      name: viewSkill?.name ? viewSkill?.name : "",
      alias: viewSkill?.alias ? viewSkill?.alias : "",
      status: viewSkill?.status ? viewSkill?.status : 0,
      view: viewSkill?.view ? viewSkill?.view : 0,
      parent: viewSkill?.parent?._id ? viewSkill?.parent?._id : null,
      isParent: viewSkill?.isParent ? viewSkill?.isParent : false,
      showParentSkill: viewSkill?.showParentSkill
        ? viewSkill?.showParentSkill
        : null,
      profileImage: viewSkill?.profileImage ? viewSkill?.profileImage : "",
      showSubSkill: viewSkill?.showSubSkill ? viewSkill?.showSubSkill : false,
      multilingual: viewSkill?.multilingual,
      // English: viewSkill?.multilingual?.English
      //   ? viewSkill?.multilingual?.English
      //   : "",
      // Hindi: viewSkill?.multilingual?.Hindi
      //   ? viewSkill?.multilingual?.Hindi
      //   : "",
      // Marathi: viewSkill?.multilingual?.Marathi
      //   ? viewSkill?.multilingual?.Marathi
      //   : "",
      // Tamil: viewSkill?.multilingual?.Tamil
      //   ? viewSkill?.multilingual?.Tamil
      //   : "",
      // Telugu: viewSkill?.multilingual?.Telugu
      //   ? viewSkill?.multilingual?.Telugu
      //   : "",
      // Kannada: viewSkill?.multilingual?.Kannada
      //   ? viewSkill?.multilingual?.Kannada
      //   : "",
    });
  }, [viewSkill]);

  const [values, setValues] = useState({});

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleMultilingualChange = (prop) => (event) => {
    setValues({
      ...values,
      multilingual: {
        ...values?.multilingual,
        [prop]: event.target.value,
      },
    });
  };

  const handleChangeAutoComplete = (prop, value) => {
    setValues({ ...values, [prop]: value });
  };
  const handleCheck = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.checked });
  };

  const handleSubmit = () => {
    handleEditSkill(values, viewSkill?._id);
    closeSkillEditModel();
  };
  let navigate = useNavigate();
  return (
    <Dialog
      maxWidth={"lg"}
      open={showSkillEdit}
      onClose={closeSkillEditModel}
      aria-labelledby="customized-dialog-title"
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          fontWeight: "bold",
          background: "rgb(35, 48, 68)",
          color: "White",
          textAlign: "center",
        }}
        id="customized-dialog-title"
      >
        UPDATE SKILL
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeSkillEditModel}
        sx={{
          position: "absolute",
          right: 8,
          top: 5,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        {loading ? (
          <CircularProgress
            className="Loading"
            loading={loading}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
            sx={{ marginLeft: "40%" }}
          />
        ) : (
          <Box>
            <Stack
              direction="row"
              spacing={3}
              justifyContent="space-evenly"
              sx={{ marginTop: "10px" }}
            >
              <Stack direction="column">
                <label>Name: </label>
                <TextField
                  value={values.name}
                  onChange={handleChange("name")}
                  sx={{ width: "35vw" }}
                  size="small"
                  placeholder="Name"
                />
              </Stack>
              <Stack direction="column">
                <label>Alias: </label>
                <TextField
                  value={values.alias}
                  onChange={handleChange("alias")}
                  sx={{ width: "35vw" }}
                  size="small"
                  placeholder="Alias"
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              spacing={3}
              justifyContent="space-evenly"
              sx={{ marginTop: "10px" }}
            >
              <Stack direction="column">
                <label>Functional Area: </label>
                <Autocomplete
                  sx={{ width: "72vw" }}
                  size="small"
                  options={functionalArea}
                  getOptionLabel={(option) => option.name}
                  value={
                    functionalArea.find(
                      (functionalArea) =>
                        functionalArea._id === values?.functionalArea
                    ) || values?.functionalArea
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("functionalArea", value?._id);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Functional Area" />
                  )}
                />
              </Stack>
            </Stack>

            <Stack
              direction="row"
              spacing={3}
              justifyContent="space-evenly"
              sx={{ marginTop: "10px" }}
            >
              <Stack direction="column">
                <label>Status: </label>

                <Autocomplete
                  sx={{ width: "35vw" }}
                  size="small"
                  options={Status}
                  getOptionLabel={(option) => option.label}
                  value={Status.find(
                    (status) => status.value === values?.status
                  )}
                  onChange={(event, value) => {
                    handleChangeAutoComplete("status", value?.value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="status" />
                  )}
                />
              </Stack>

              <Stack direction="column">
                <label>View: </label>

                <TextField
                  inputProps={{ min: 0 }}
                  value={values.view}
                  onChange={handleChange("view")}
                  sx={{ width: "35vw" }}
                  size="small"
                  placeholder="View"
                  type="number"
                />
              </Stack>
            </Stack>

            <Stack
              direction="row"
              spacing={15}
              justifyContent="space-evenly"
              sx={{ marginTop: "10px", paddingLeft: "10vh" }}
            >
              <Stack direction="column">
                <label>Is Parent</label>
                <Switch
                  checked={values.isParent}
                  onChange={handleCheck("isParent")}
                />
              </Stack>
              {values.isParent ? (
                <>
                  <Stack direction="column">
                    <label>Show Parent Skill</label>
                    <Switch
                      defaultChecked={false}
                      checked={values.showParentSkill}
                      onChange={handleCheck("showParentSkill")}
                    />
                  </Stack>

                  <Stack direction="column" sx={{ paddingLeft: "4vh" }}>
                    <label>Profile Image</label>
                    <TextField
                      placeholder="Profile Image"
                      sx={{ width: "35vw" }}
                      size="small"
                      value={values.profileImage}
                      onChange={handleChange("profileImage")}
                    />
                  </Stack>
                </>
              ) : (
                <>
                  <Stack direction="column">
                    <label>Show Sub Skill</label>

                    <Switch
                      checked={values.showSubSkill}
                      onChange={handleCheck("showSubSkill")}
                    />
                  </Stack>
                  <Stack direction="column" sx={{ paddingLeft: "5vh" }}>
                    <label>Parent: </label>
                    <Autocomplete
                      sx={{ width: "35vw" }}
                      size="small"
                      options={profile}
                      getOptionLabel={(option) => option.name}
                      value={
                        profile.find(
                          (profile) => profile._id === values?.parent
                        ) || null
                      }
                      onChange={(event, value) => {
                        handleChangeAutoComplete("parent", value?._id);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Parent" />
                      )}
                    />
                  </Stack>
                </>
              )}
            </Stack>

            <Typography
              sx={{ fontSize: "25px", paddingLeft: "33px", marginTop: "20px" }}
            >
              Languages
            </Typography>

            <Stack
              direction="row"
              spacing={3}
              justifyContent="space-evenly"
              sx={{ marginTop: "10px" }}
            >
              <Stack direction="column">
                <label>English: </label>
                <TextField
                  sx={{ width: "35vw" }}
                  size="small"
                  placeholder="English"
                  value={values?.multilingual?.English}
                  onChange={handleMultilingualChange("English")}
                />
              </Stack>
              <Stack direction="column">
                <label>Hindi: </label>
                <TextField
                  sx={{ width: "35vw" }}
                  size="small"
                  value={values?.multilingual?.Hindi}
                  onChange={handleMultilingualChange("Hindi")}
                  placeholder=" हिंदी"
                />
              </Stack>
            </Stack>

            <Stack
              direction="row"
              spacing={3}
              justifyContent="space-evenly"
              sx={{ marginTop: "10px" }}
            >
              <Stack direction="column">
                <label>Marathi: </label>
                <TextField
                  sx={{ width: "35vw" }}
                  size="small"
                  value={values?.multilingual?.Marathi}
                  onChange={handleMultilingualChange("Marathi")}
                  placeholder="मराठी"
                />
              </Stack>
              <Stack direction="column">
                <label>Telugu: </label>
                <TextField
                  sx={{ width: "35vw" }}
                  size="small"
                  placeholder="తెలుగు"
                  value={values?.multilingual?.Telugu}
                  onChange={handleMultilingualChange("Telugu")}
                />
              </Stack>
            </Stack>

            <Stack
              direction="row"
              spacing={3}
              justifyContent="space-evenly"
              sx={{ marginTop: "10px" }}
            >
              <Stack direction="column">
                <label>Tamil: </label>
                <TextField
                  sx={{ width: "35vw" }}
                  size="small"
                  placeholder="தமிழ்"
                  value={values?.multilingual?.Tamil}
                  onChange={handleMultilingualChange("Tamil")}
                />
              </Stack>
              <Stack direction="column">
                <label>Kannada: </label>
                <TextField
                  sx={{ width: "35vw" }}
                  size="small"
                  value={values?.multilingual?.Kannada}
                  onChange={handleMultilingualChange("Kannada")}
                  placeholder="ಕನ್ನಡ"
                />
              </Stack>
            </Stack>

            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={3}
              margin={5}
            >
              <Button
                variant="contained"
                className="search-button"
                onClick={handleSubmit}
              >
                update
              </Button>
              <Button
                className="reset-button"
                variant="contained"
                onClick={closeSkillEditModel}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}

const Status = [
  { label: "Active", value: 1 },
  { label: "Inactive", value: 0 },
];
