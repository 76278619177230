import React, { createContext, useState } from "react";
import axios from "axios";
import { backendUrl } from "../contants/contants";
export const appContext = createContext({});
export const AppProvider = ({ children }) => {
  const [skills, setSkills] = useState([]);
  const [skillSuggestion, setSkillSuggestion] = useState([]);
  const [functionalArea, setFunctionalArea] = useState([]);
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [qualification, setQualification] = useState([]);
  const [plan, setPlan] = useState([]);
  const [subProfile, setSubProfile] = useState([]);
  const [locations, setLocations] = useState([]);
  const [dashboard, setDashboard] = useState([]);

  const handleSkillSuggestion = (data) => {
    axios
      .post(backendUrl + "/api/crm/app/skills", data)
      .then((response) => {
        setSkillSuggestion(response.data.data.skills);
      })
      .catch((e) => {});
  };
  const employeeLocation = () => {
    axios
      .get(backendUrl + "/api/crm/app/locations")
      .then((response) => {
        setLocations(response.data.data.locations);
      })
      .catch((e) => {});
  };
  const editQualification = () => {
    setLoading(true);
    axios
      .get(backendUrl + "/api/crm/app/qualification")
      .then((response) => {
        setQualification(response.data.data.qualification);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const employeeProfile = () => {
    axios
      .get(backendUrl + "/api/crm/app/profile")
      .then((response) => {
        setProfile(response.data.data.profile);
      })
      .catch((e) => {});
  };

  const employeeSubProfile = (id) => {
    axios
      .get(backendUrl + "/api/crm/app/subprofile?id=" + id)
      .then((response) => {
        setSubProfile(response.data.data.subProfile);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const editIndustry = () => {
    setLoading(true);
    axios
      .get(backendUrl + "/api/crm/app/industry")
      .then((response) => {
        setIndustry(response.data.data.industry);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const editFunctional = () => {
    setLoading(true);
    axios
      .get(backendUrl + "/api/crm/app/functional")
      .then((response) => {
        setFunctionalArea(response.data.data.functional);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const editSkill = () => {
    setLoading(true);
    axios
      .get(backendUrl + "/api/crm/app/skill")
      .then((response) => {
        setSkills(response.data.data.skills);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const dashboardCount = () => {
    setLoading(true);
    axios
      .get(backendUrl + "/api/crm/app/dashboard")
      .then((response) => {
        setDashboard(response.data.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const editPlan = () => {
    setLoading(true);
    axios
      .get(backendUrl + "/api/crm/app/plan")
      .then((response) => {
        setPlan(response.data.data.plan);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  return (
    <appContext.Provider
      value={{
        loading,
        profile,
        industry,
        qualification,
        plan,
        subProfile,
        locations,
        dashboard,
        skills,
        handleSkillSuggestion,
        employeeSubProfile,
        employeeLocation,
        editIndustry,
        editSkill,
        dashboardCount,
        editPlan,
        editQualification,
        employeeProfile,
        editFunctional,
        skillSuggestion,
        functionalArea,
      }}
    >
      {children}
    </appContext.Provider>
  );
};
