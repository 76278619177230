import React, { useContext } from "react";
import { pendingContext } from "../../contexts/PendingContext";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {DialogContent, Dialog,DialogTitle,Grid, Stack, Box, Chip, Slide, Paper } from "@mui/material";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function PendingView() {
  const { viewPending, closePendingViewModel, showPendingView } =
    useContext(pendingContext);

  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      open={showPendingView}
      onClose={closePendingViewModel}
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          fontWeight: "bold",
          background: "rgb(35, 48, 68)",
          color: "White",
          textAlign: "center",
        }}
        id="customized-dialog-title"
      >
        PENDING JOB DETAILS
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closePendingViewModel}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
      
        dividers
      >
        <Box sx={{ flexGrow: 1 }}>
          <Stack spacing={1}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Job Title:</p>
                  <p>
                    {viewPending?.jobProfile?.name
                      ? viewPending?.jobProfile?.name
                      : "N/A"}
                  </p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Company Name:</p>
                  <p> {viewPending?.companyName} </p>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Contact Person:</p>
                  <p>{viewPending?.contactPerson} </p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Experience:</p>
                  <p> {viewPending?.experience} years</p>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Mobile Number: </p>
                  <p>{viewPending?.contactNumber} </p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Location: </p>
                  <p>{viewPending?.city ? viewPending?.city : "N/A"} </p>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Min Salary: </p>
                  <p>{viewPending?.minSalary} </p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Max Salary: </p>
                  <p>{viewPending?.maxSalary} </p>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Updated at:</p>
                  <p>
                    {viewPending?.updatedAt
                      ? moment(viewPending?.updatedAt).format("D/MM/YYYY")
                      : ""}{" "}
                  </p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Status:</p>
                  
                    <Chip
                      size="small"
                      sx={{
                        backgroundColor:
                          viewPending?.status === 1 ? "#fce6dc" : "orange",
                        color: viewPending?.status === 1 ? "#cc6c19" : "#fff",
                      }}
                      label={viewPending?.status === 1 ? "Active" : "InActive"}
                    />
                
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Created at:</p>
                  <p>
                    {viewPending?.createdAt
                      ? moment(viewPending?.createdAt).format("D/MM/YYYY")
                      : ""}{" "}
                  </p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Expired at:</p>
                  <p>
                    {viewPending?.expiredAt
                      ? moment(viewPending?.expiredAt).format("D/MM/YYYY")
                      : "N/A"}
                  </p>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={15}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Contact Email:</p>
                  <p> {viewPending?.contactEmail} </p>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={15}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Interview Address:</p>
                  <p> {viewPending?.interviewAddress} </p>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
