import React, { useEffect, useContext, useState } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import LoadingButton from "@mui/lab/LoadingButton";
import {Pagination,CircularProgress,TextField, Typography,Button, Paper, Box, Stack } from "@mui/material";
import PendingTable from "./Table";
import { pendingContext } from "../../contexts/PendingContext";
import PendingView from "./View";
import PendingEdit from "./Edit";

export default function Pending() {
  const { pendingCount, handleSearchPending, loading } = useContext(pendingContext);
  const [values, setValues] = useState({
    fromDate: null,
    toDate: null,
    page: 1,
    contactNumber: "",
  });

  useEffect(() => {
    handleSearchPending({ ...values });
  }, []);

  const handlePageChange = (event, value) => {
    setValues({ ...values, page: value });
    handleSearchPending({ ...values, page: value });
  };

  const handleReset = () => {
    setValues({
      fromDate: null,
      toDate: null,
      page: 1,
      contactNumber: "",
    });
    handleSearchPending({
      fromDate: null,
      toDate: null,
      page: 1,
      contactNumber: "",
    });
  };

  const handleChange = (prop) => (event) => {
    if (prop === "fromDate" || prop === "toDate") {
      setValues({ ...values, [prop]: event });
    } else {
      setValues({ ...values, [prop]: event.target.value });
    }
  };

  const handleEnterKeyPress = (event, value) => {
    if (event.key === "Enter") {
      handleSearchPending({ ...values, page: value });
    }
  };

  return (
    <>
      <Paper
        sx={{
          textAlign: "center",
          padding: "5px",
        }}
      >
        <Box
          sx={{ justifyContent: "center", borderRadius: "5px" }}
          className="heading"
        >
          <Typography variant="h5">
            Pending JobPosted(
            {pendingCount ? parseInt(pendingCount).toLocaleString() : "NA"})
          </Typography>
        </Box>
        <div className="Textfield">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <DesktopDatePicker
              sx={{
                width: "17vw",
                textAlign: "center",
                justifyContent: "center",
              }}
              value={values.fromDate}
              onChange={handleChange("fromDate")}
              label="From*"
              format="DD/MM/YYYY"
              maxDate={values.toDate}
              slotProps={{ textField: { size: "small" } }}
              onKeyPress={handleEnterKeyPress}
            />

            <DesktopDatePicker
              sx={{
                width: "17vw",
                textAlign: "center",
                justifyContent: "center",
              }}
              value={values.toDate}
              onChange={handleChange("toDate")}
              label="To*"
              format="DD/MM/YYYY"
              minDate={values.fromDate}
              slotProps={{ textField: { size: "small" } }}
              onKeyPress={handleEnterKeyPress}
            />

            <TextField
              inputProps={{
                maxLength: 10,
              }}
              disabled={loading}
              size="small"
              sx={{
                width: "17vw",
                textAlign: "center",
                justifyContent: "center",
              }}
              label="Contact Number"
              value={values.contactNumber}
              onChange={handleChange("contactNumber")}
              onKeyPress={handleEnterKeyPress}
            />
            <LoadingButton
              loading={loading}
              loadingPosition="center"
              onClick={() => {
                setValues({ ...values, page : 1})
                handleSearchPending({ ...values, page: 1 });
              }}
              variant="contained"
              className="search-button"
            >
              Search
            </LoadingButton>
            <Button
              variant="contained"
              className="reset-button"
              onClick={handleReset}
            >
              Reset
            </Button>
          </Stack>
        </div>

        {loading ? (
          <CircularProgress
            sx={{
              marginTop: "15%",
              marginBottom: "15%",
            }}
            loading={"true"}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <PendingTable pageNumber={values.page} />
        )}

        <Pagination
          sx={{
            marginLeft: "10px",
            marginRight: "10px",
            marginTop: "10px",
            paddingBottom: "10px",
            "Button.MuiPaginationItem-rounded.Mui-selected": {
              bgcolor: "rgb(35, 48, 68)",
              color: "rgb(238, 238, 238)",
            },
          }}
          count={parseInt(pendingCount / 10) + (pendingCount % 10 > 0 ? 1 : 0)}
          page={values.page}
          onChange={handlePageChange}
          shape="rounded"
          variant="outlined"
        />
      </Paper>
      <PendingView />
      <PendingEdit handleSearch={() => handleSearchPending(values)} />
    </>
  );
}
