import React, { useContext } from "react";
import {MenuItem, Menu, IconButton,Table,TableHead,TableBody,TableRow,Paper,TableCell,tableCellClasses, Box, Tooltip,Chip,TableContainer } from "@mui/material";
import moment from "moment";
import { styled } from "@mui/material/styles";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SendIcon from "@mui/icons-material/Send";
import { appContext } from "../../contexts/AppContext";
import { employerContext } from "../../contexts/EmployerContext";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#233044",
    color: "#eeeeee",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    // font: "bold",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default function EmployerTable({ pageNumber }) {
  const { employerData, employerView } = useContext(employerContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRecordId, setSelectedRecordId] = React.useState(null);

  const open = Boolean(anchorEl);
  let navigate = useNavigate();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <div className="TablePadding">
      <TableContainer component={Paper}>
        <Table size="small" >
          <TableHead>
            <TableRow sx={{ borderRadius: "15px" }}>
              <StyledTableCell
                align="center"
                style={{ fontSize: "16px", maxWidth: "2vw" }}
              >
                #
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{ fontSize: "16px", maxWidth: "11vw" }}
              >
                Company Name
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{ fontSize: "16px", maxWidth: "11vw" }}
              >
                Contact Person
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{ fontSize: "16px", maxWidth: "3vw" }}
              >
                City
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{ fontSize: "16px", maxWidth: "3vw" }}
              >
                Mobile
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{ fontSize: "16px", maxWidth: "3vw" }}
              >
                Email
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ fontSize: "16px", maxWidth: "3vw" }}
              >
                Date
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ fontSize: "16px", maxWidth: "8vw" }}
              >
                Post Job
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ fontSize: "16px", maxWidth: "2vw" }}
              >
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {employerData?.map((row, index) => (
              <StyledTableRow
                className="tableRow cell tableDataRow hove"
                key={index}
              >
                <StyledTableCell align="center" style={{ maxWidth: "2vw" }}>
                  {index + 1 + (pageNumber - 1) * 10}
                </StyledTableCell>
                <StyledTableCell align="left" style={{ maxWidth: "11vw" }}>
                  <div>{row?.companyDetails?.companyName}</div>
                </StyledTableCell>
                <StyledTableCell align="left" style={{ maxWidth: "9vw" }}>
                  <div> {row?.companyDetails?.contactPerson}</div>
                </StyledTableCell>
                <StyledTableCell align="left" style={{ maxWidth: "9vw" }}>
                  <div> {row?.companyDetails?.location?.city}</div>
                </StyledTableCell>
                <StyledTableCell align="left" style={{ maxWidth: "6vw" }}>
                  <div> {row?.companyDetails?.contactNumber}</div>
                </StyledTableCell>
                <StyledTableCell align="left" style={{ maxWidth: "9vw" }}>
                  <div> {row?.companyDetails?.emailAddress}</div>
                </StyledTableCell>
                <StyledTableCell align="center" style={{ maxWidth: "9vw" }}>
                  {row?.createdAt
                    ? moment(row?.createdAt).format("D/MM/YY,h:mm:ss a")
                    : ""}
                </StyledTableCell>

                <StyledTableCell align="center" style={{ maxWidth: "4vw" }}>
                  {row.numJobs > 0 ? (
                  "Yes" 
                  ) : (
                    "No" 
                  )}
                </StyledTableCell>

                <StyledTableCell align="center" style={{ minWidth: "3vw" }}>
                  <div>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Tooltip title="Action">
                        <IconButton
                          onClick={(event) => {
                            handleMenu(event)
                            setSelectedRecordId(row?._id)
                          }}
                          size="small"
                          sx={{ ml: 2 }}
                          aria-controls={open ? "account-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      keepMounted
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 1px 1px rgb(211, 211, 211))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            left: 29,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{
                        horizontal: "center",
                        vertical: "top",
                      }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      <MenuItem
                        sx={{ color: "rgb(35, 48, 68)" }}
                        onClick={() => {
                          handleClose();
                          employerView(selectedRecordId);
                        }}
                      >
                        <SendIcon
                          sx={{ color: "#5072A7", marginRight: "10px" }}
                        />
                        View
                      </MenuItem>
                      <MenuItem
                        sx={{ color: "rgb(35, 48, 68)" }}
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        <NotificationsIcon
                          sx={{ color: "#FFD700", marginRight: "10px" }}
                        />
                        Send
                      </MenuItem>
                    </Menu>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
