import React, { useContext } from "react";
import moment from "moment";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import {
  TableContainer,
  tableCellClasses,
  Tooltip,
  Menu,
  MenuItem,
  Chip,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Paper,
  TableCell,
  Box,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { feedbackContext } from "../../contexts/FeedbackContext";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#233044",
    color: "#eeeeee",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default function FeedbackTable({ pageNumber }) {
  const { feedbackData, feedbackView } = useContext(feedbackContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRecordId, setSelectedRecordId] = React.useState(null);
  const open = Boolean(anchorEl);
  let navigate = useNavigate();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <div className="TablePadding">
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left" style={{ maxWidth: "2vw" }}>
                #
              </StyledTableCell>
              <StyledTableCell style={{ maxWidth: "7vw" }} align="left">
                Company Name
              </StyledTableCell>
              <StyledTableCell style={{ maxWidth: "4vw" }} align="left">
                Job Title
              </StyledTableCell>
              <StyledTableCell style={{ maxWidth: "4vw" }} align="center">
                Job Status
              </StyledTableCell>
              <StyledTableCell style={{ maxWidth: "6vw" }} align="center">
                Hiring Completed
              </StyledTableCell>
              <StyledTableCell style={{ maxWidth: "6vw" }} align="left">
                Recommended
              </StyledTableCell>
              <StyledTableCell style={{ maxWidth: "6vw" }} align="left">
                Service Again
              </StyledTableCell>
              <StyledTableCell style={{ maxWidth: "7vw" }} align="left">
                Satisfaction Level
              </StyledTableCell>

              <StyledTableCell style={{ maxWidth: "3vw" }} align="left">
                Date
              </StyledTableCell>

              <StyledTableCell
                align="left"
                style={{ fontSize: "16px", maxWidth: "2vw" }}
              >
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {feedbackData?.map((row, index) => (
              <StyledTableRow
                key={index}
                className="tableRow cell tableDataRow hove"
              >
                <StyledTableCell align="center" style={{ maxWidth: "4vw" }}>
                  {index + 1 + (pageNumber - 1) * 10}
                </StyledTableCell>
                <StyledTableCell align="left" style={{ maxWidth: "7vw" }}>
                  <div> {row?.job?.companyName}</div>
                </StyledTableCell>
                <StyledTableCell align="left" style={{ maxWidth: "6vw" }}>
                  <div> {row?.job?.name}</div>
                </StyledTableCell>
                <StyledTableCell align="center" style={{ maxWidth: "5vw" }}>
                  <Chip
                    size="small"
                    sx={{
                      backgroundColor:
                        row?.job?.status === 1 ? "#97c53c" : "#e48813",
                      color: row?.job?.status === 1 ? "white" : "#fff",
                    }}
                    label={row?.job?.status === 1 ? "Active" : "InActive"}
                  />
                </StyledTableCell>
                <StyledTableCell align="center" style={{ maxWidth: "4vw" }}>
                  No
                </StyledTableCell>
                <StyledTableCell align="center" style={{ maxWidth: "3vw" }}>
                  <Chip
                    size="small"
                    sx={{
                      backgroundColor:
                        row?.recommendService === 1 ? "#97c53c" : "#e48813",
                      color: row?.recommendService === 1 ? "white" : "#fff",
                    }}
                    label={row?.recommendService === 1 ? "Yes" : "No"}
                  />
                </StyledTableCell>
                <StyledTableCell align="center" style={{ maxWidth: "3vw" }}>
                  <Chip
                    size="small"
                    sx={{
                      backgroundColor:
                        row?.serviceAgain === 1 ? "#97c53c" : "#e48813",
                      color: row?.serviceAgain === 1 ? "white" : "#fff",
                    }}
                    label={row?.serviceAgain === 1 ? "Yes" : "No"}
                  />
                </StyledTableCell>
                <StyledTableCell align="center" style={{ maxWidth: "3vw" }}>
                  <div> {row?.satisfactionLevel}</div>
                </StyledTableCell>

                <StyledTableCell align="center" style={{ maxWidth: "3vw" }}>
                  {row?.createdAt
                    ? moment(row?.createdAt).format("D/MM/YYYY")
                    : ""}
                </StyledTableCell>

                <StyledTableCell align="center" style={{ minWidth: "3vw" }}>
                  <div>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Tooltip title="Action">
                        <IconButton
                          onClick={(event) => {
                            handleMenu(event);
                            setSelectedRecordId(row?._id);
                          }}
                          size="small"
                          sx={{ ml: 2 }}
                          aria-controls={open ? "account-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      keepMounted
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 1px 1px rgb(211, 211, 211))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            left: 29,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{
                        horizontal: "center",
                        vertical: "top",
                      }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      <MenuItem
                        sx={{ color: "rgb(35, 48, 68)" }}
                        onClick={() => {
                          handleClose();
                          feedbackView(selectedRecordId);
                        }}
                      >
                        <SendIcon
                          sx={{ color: "#5072A7", marginRight: "10px" }}
                        />
                        View
                      </MenuItem>
                    </Menu>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
