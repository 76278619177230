import React, { createContext, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { backendUrl } from "../contants/contants";
export const locationContext = createContext({});
const LocationProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [showSearchEmployer, setShowSearchEmployer] = useState(false);
  const [locationsData, setLocationsData] = useState([]);
  const [locationsCount, setLocationsCount] = useState(0);
  const [viewLocations, setViewLocations] = useState([]);
  const [showEditLocations, setShowEditLocations] = useState(false);
  const [showLocationEdit, setShowLocationEdit] = useState(false);
  const closeLocationEditModel = () => {
    setShowLocationEdit(false);
  };
  const showLocationEditModel = () => {
    setShowLocationEdit(true);
  };

  const handleSearchLocations = (data) => {
    setLoading(true);
    axios
      .post(backendUrl + "/api/crm/location/list", data)
      .then((response) => {
        setShowSearchEmployer(false);
        setLocationsData(response.data.data.location);
        setLocationsCount(response.data.data.count);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const locationsEdit = (id) => {
    axios
      .get(backendUrl + "/api/crm/location?id=" + id)
      .then((response) => {
        setViewLocations(response.data.data.location);
        setShowLocationEdit(true)
      })
      .catch((e) => {});
  };
  const handleEditLocations = (data, _id) => {
    axios
      .put(backendUrl + "/api/crm/location/update?id=" + _id, data)
      .then((response) => {
        setShowEditLocations(false);
        toast.success("Successfully Updated!");
      })
      .catch((e) => {});
  };
  return (
    <locationContext.Provider
      value={{
        loading,
        handleSearchLocations,
        locationsData,
        locationsCount,
        viewLocations,
        locationsEdit,
        handleEditLocations,
        showEditLocations,
        showSearchEmployer,
        showLocationEdit,
        closeLocationEditModel,
        showLocationEditModel
      }}
    >
      {children}
    </locationContext.Provider>
  );
};
export default LocationProvider;
