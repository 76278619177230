import React, { createContext, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { backendUrl } from "../contants/contants";
export const employeeOrderContext = createContext({});
const EmployeeOrderProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [showEmployeeOrderView, setShowEmployeeOrderView] = useState(false);
  const [showEmployeeCreateOrderView, setShowEmployeeCreateOrderView] =
    useState(false);
  const [employeeOrderData, setEmployeeOrderData] = useState([]);
  const [employeeorderCount, setEmployeeOrderCount] = useState(0);
  const [employeeOrderviewMore, setEmployeeOrderViewMore] = useState([]);
  const showEmployeeOrderViewModel = () => {
    setShowEmployeeOrderView(true);
  };
  const closeEmployeeOrderViewModel = () => {
    setShowEmployeeOrderView(false);
  };

  const showEmployeeCreateOrderViewModel = () => {
    setShowEmployeeCreateOrderView(true);
  };
  const closeEmployeeCreateOrderViewModel = () => {
    setShowEmployeeCreateOrderView(false);
  };
  const handleSearchEmployeeOrder = (data) => {
    setLoading(true);
    axios
      .post(backendUrl + "/api/crm/employeeOrder/list", data)
      .then((response) => {
        setEmployeeOrderData(response.data.data.employeeOrderOrder);
        setEmployeeOrderCount(response.data.data.count);
        setLoading(false);
        setShowEmployeeOrderView(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const employeeOrderView = (id) => {
    axios
      .get(backendUrl + "/api/crm/employeeOrder?id=" + id)
      .then((response) => {
        setShowEmployeeOrderView(true);
        setEmployeeOrderViewMore(response.data.data.employeeOrder);
      })
      .catch((e) => {});
  };
  const handleEmployeeOrderStatus = (data, id) => {
    axios
      .put(backendUrl + "/api/crm/employeeorder/update?id=" + id, data)
      .then((response) => {
        setEmployeeOrderData(
          employeeOrderData.map((employeeOrder) => {
            if (employeeOrder._id === id) {
              employeeOrder.status = data.status;
            }
            return employeeOrder;
          })
        );
        toast.success(
          data.status === false
            ? "Successfully Inactive"
            : "Successfully Active"
        );
      })
      .catch((e) => {});
  };
  return (
    <employeeOrderContext.Provider
      value={{
        loading,
        showEmployeeOrderView,
        handleEmployeeOrderStatus,
        employeeOrderView,
        handleSearchEmployeeOrder,
        handleSearchEmployeeOrder,
        showEmployeeOrderViewModel,
        closeEmployeeOrderViewModel,
        employeeOrderData,
        employeeorderCount,
        employeeOrderviewMore,
        showEmployeeCreateOrderView,
        showEmployeeCreateOrderViewModel,
        closeEmployeeCreateOrderViewModel,
      }}
    >
      {children}
    </employeeOrderContext.Provider>
  );
};
export default EmployeeOrderProvider;
