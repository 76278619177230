import React, { useContext } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {Dialog,DialogTitle,DialogContent,IconButton, Grid, Stack, Box, Chip, Slide, Paper } from "@mui/material";
import moment from "moment";
import { jobPostedContext } from "../../contexts/JobPostedContext";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function ContractView() {
  const { viewJob, closeContractView, showContractView } = useContext( jobPostedContext );

  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      open={showContractView}
      onClose={closeContractView}
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          fontWeight: "bold",
          background: "rgb(35, 48, 68)",
          color: "White",
          textAlign: "center",
        }}
        id="customized-dialog-title"
      >
        CONTRACT JOB POSTED - {viewJob?._id}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeContractView}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent

        dividers
      >
        <Box sx={{ flexGrow: 1 }}>
          <Stack spacing={1}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">name:</p>
                  <p>{viewJob?.name} </p>
                </Stack>
              </Grid>

              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Mobile Number:</p>
                  <p>{viewJob?.contactNumber} </p>
                </Stack>
              </Grid>


            </Grid>

            <Grid container spacing={2}>



              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Contact Person:</p>
                  <p>{viewJob?.contactPerson} </p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Company Name:</p>
                  <p> {viewJob?.companyName} </p>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Location: </p>
                  <p>
                    {viewJob?.location?.location
                      ? (viewJob?.location?.location)
                      : ("N/A")}   </p>
                </Stack>
              </Grid>

              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Status:</p>
           
                    <Chip
                      size="small"
                      sx={{
                        backgroundColor:
                          viewJob?.status === 1 ? "#97c53c" : "#e48813",
                        color: viewJob?.status === 1 ? "white" : "#fff",
                      }}
                      label={viewJob?.status === 1 ? "Active" : "InActive"}
                    />
                 
                </Stack>
              </Grid>

            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Job Duration : </p>
                  <p>{viewJob?.jobDurations}</p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Job Vaccancy: </p>
                  <p>{viewJob?.numberOfEmployees} </p>
                </Stack>
              </Grid>

            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Gender: </p>
               
                    <Chip
                      size="small"
                      sx={{
                        backgroundColor:
                          viewJob?.gender === 0 ? "#16b1ff1f" : "#ff4c511f",
                        color: viewJob?.gender === 0 ? "#16b1ff" : "red",
                      }}
                      label={
                        viewJob?.gender === 0 ? "Male" : "Female"
                      }
                    />
                
                </Stack>
              </Grid>

              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Dihari: </p>
                  <p>{viewJob?.shiftTimings}</p>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Created at:</p>
                  <p>
                    {viewJob?.createdAt
                      ? moment(viewJob?.createdAt).format("D/MM/YYYY")
                      : ""}{" "}
                  </p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Updated at:</p>
                  <p>
                    {viewJob?.updatedAt
                      ? moment(viewJob?.updatedAt).format("D/MM/YYYY")
                      : ""}{" "}
                  </p>
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={10}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Email Id:</p>
                  <p> {viewJob?.contactEmail} </p>
                </Stack>
              </Grid>

            </Grid>
            <Grid container spacing={2}>

              <Grid item xs={6} md={10}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">JobDescription:</p>
                  <p> {viewJob?.description} </p>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={20}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> InterviewAddress: </p>
                  <p>{viewJob?.interviewAddress} </p>
                </Stack>
              </Grid>

            </Grid>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
