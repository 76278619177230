import React, { useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  tableCellClasses,
  TableContainer,
  Box,
  Paper,
  Tooltip,
  MenuItem,
  Menu,
  IconButton,
} from "@mui/material";
import moment from "moment";
import { styled } from "@mui/material/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import { pendingContext } from "../../contexts/PendingContext";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#233044",
    color: "#eeeeee",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default function PendingTable({ pageNumber }) {
  const { pendingData, pendingView, pendingEdit } = useContext(pendingContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRecordId, setSelectedRecordId] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <div className="TablePadding">
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left" style={{ maxWidth: "2vw" }}>
                #
              </StyledTableCell>
              <StyledTableCell style={{ maxWidth: "9vw" }} align="left">
                Job Title
              </StyledTableCell>
              <StyledTableCell style={{ maxWidth: "13vw" }} align="center">
                Company Name
              </StyledTableCell>
              <StyledTableCell style={{ maxWidth: "13vw" }} align="center">
                Company Number
              </StyledTableCell>
              <StyledTableCell style={{ maxWidth: "7vw" }} align="center">
                Location
              </StyledTableCell>
              <StyledTableCell style={{ maxWidth: "7vw" }} align="center">
                Industry
              </StyledTableCell>
              <StyledTableCell style={{ maxWidth: "7vw" }} align="center">
                Job Post
              </StyledTableCell>
              <StyledTableCell style={{ maxWidth: "8vw" }} align="center">
                Created At
              </StyledTableCell>
              <StyledTableCell style={{ maxWidth: "6vw" }} align="center">
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pendingData?.map((row, index) => (
              <StyledTableRow
                key={index}
                className="tableRow cell tableDataRow hove"
              >
                <StyledTableCell align="center" style={{ maxWidth: "4vw" }}>
                  {index + 1 + (pageNumber - 1) * 10}
                </StyledTableCell>
                <StyledTableCell align="left" style={{ maxWidth: "9vw" }}>
                  <div> {row?.jobProfile?.name}</div>
                </StyledTableCell>
                <StyledTableCell align="left" style={{ maxWidth: "9vw" }}>
                  <div> {row?.companyName}</div>
                </StyledTableCell>
                <StyledTableCell align="center" style={{ maxWidth: "11vw" }}>
                  <div> {row?.contactNumber}</div>
                </StyledTableCell>
                <StyledTableCell align="left" style={{ maxWidth: "7vw" }}>
                  <div> {row?.location?.city}</div>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <div> {row?.industry?.name}</div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  {/* <div> {row?.jobPostedStatus}</div> */}
                  {row.numJobs > 0 ? "Yes" : "No"}
                </StyledTableCell>
                <StyledTableCell align="center" style={{ maxWidth: "8vw" }}>
                  {row?.createdAt
                    ? moment(row?.createdAt).format("D/MM/YYYY")
                    : ""}
                </StyledTableCell>

                <StyledTableCell align="center" sx={{ width: "6vw" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Tooltip title="Action">
                      <IconButton
                        onClick={(event) => {
                          handleMenu(event);
                          setSelectedRecordId(row?._id);
                        }}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 1px 1px rgb(211, 211, 211))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          left: 40,
                          width: 10,
                          height: 10,

                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "left", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem
                      sx={{ color: "rgb(35, 48, 68)" }}
                      onClick={() => {
                        pendingEdit(selectedRecordId);
                        handleClose();
                      }}
                    >
                      <EditIcon
                        sx={{ color: "	#228B22", marginRight: "10px" }}
                      />
                      Edit
                    </MenuItem>
                    <MenuItem
                      sx={{ color: "rgb(35, 48, 68)" }}
                      onClick={() => {
                        handleClose();
                        pendingView(selectedRecordId);
                      }}
                    >
                      <SendIcon
                        sx={{ color: "#5072A7", marginRight: "10px" }}
                      />
                      View
                    </MenuItem>
                  </Menu>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
