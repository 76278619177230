import React, { useEffect, useContext, useState } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import OrderTable from "./Table";
import { orderContext } from "../../contexts/OrderContext";
import { appContext } from "../../contexts/AppContext";
import LoadingButton from "@mui/lab/LoadingButton";
import {Typography,Stack,Autocomplete,TextField,Pagination,Checkbox, Button, Paper, Box,CircularProgress } from "@mui/material";
import OrderView from "./OrderView";
import { styled } from "@mui/system";
import OrderEdit from "./OrderEdit";

const CustomCheckbox = styled(Checkbox)({
  color: "rgb(35, 48, 68)",
  "&.Mui-checked": {
    color: "#9155fd",
  },
});

export default function Orders() {
  const { handleSearchOrder, orderCount, loading} =
    useContext(orderContext)
    const {  plan, editPlan } =
    useContext(appContext);
  const [values, setValues] = useState({
    fromDate: null,
    toDate: null,
    contactNumber: "",
    planId: "",
    page: 1,
    isActive: true,
  });

  useEffect(() => {
    editPlan();
    handleSearchOrder({ ...values });
  }, []);

  const handlePageChange = (event, value) => {
    setValues({ ...values, page: value });
    handleSearchOrder({ ...values, page: value });
  };

  const handleReset = () => {
    setValues({
      fromDate: null,
      toDate: null,
      contactNumber: "",
      planId: "",
      page: 1,
      isActive: true,
    });
    handleSearchOrder({
      fromDate: null,
      toDate: null,
      contactNumber: "",
      planId: "",
      page: 1,
      isActive: true,
    });
  };

  const handleChange = (prop) => (event) => {
    if (prop === "fromDate" || prop === "toDate") {
      setValues({ ...values, [prop]: event });
    } else {
      setValues({ ...values, [prop]: event.target.value });
    }
  };

  const handleChangeAutoComplete = (prop, value) => {
    setValues({ ...values, [prop]: value });
  };

  const handleCheck = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.checked });
  };

  const handleEnterKeyPress = (event, value) => {
    if (event.key === "Enter") {
      handleSearchOrder({ ...values, page: value });
    }
  };
  return (
    <div>
      <Paper
        sx={{
          textAlign: "center",
          padding: "5px",
        }}
      >
        <Box
          sx={{ justifyContent: "center", borderRadius: "5px" }}
          className="heading"
        >
          <Typography variant="h5">
            Order(
            {orderCount ? parseInt(orderCount).toLocaleString() : "NA"})
          </Typography>
        </Box>
        <div className="Textfield">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Autocomplete
              sx={{ width: "16vw" }}
              size="small"
              options={plan}
              getOptionLabel={(option) =>
                option ? option?.planType + "-" + option?.name : ""
              }
              value={plan.find((plan) => plan._id === values?.planId) ?? ""}
              onChange={(event, value) => {
                handleChangeAutoComplete("planId", value?._id);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Plan Name" />
              )}
              onKeyPress={handleEnterKeyPress}
            />

            <DesktopDatePicker
              sx={{
                width: "17vw",
                textAlign: "center",
                justifyContent: "center",
              }}
              value={values.fromDate}
              onChange={handleChange("fromDate")}
              label="From*"
              format="DD/MM/YYYY"
              maxDate={values.toDate}
              slotProps={{ textField: { size: "small" } }}
              onKeyPress={handleEnterKeyPress}
            />

            <DesktopDatePicker
              sx={{
                width: "17vw",
                textAlign: "center",
                justifyContent: "center",
              }}
              value={values.toDate}
              onChange={handleChange("toDate")}
              label="To*"
              format="DD/MM/YYYY"
              minDate={values.fromDate}
              slotProps={{ textField: { size: "small" } }}
              onKeyPress={handleEnterKeyPress}
            />

            <TextField
              inputProps={{
                maxLength: 10,
              }}
              disabled={loading}
              size="small"
              sx={{
                width: "17vw",
                textAlign: "center",
                justifyContent: "center",
              }}
              label="Contact Number"
              value={values.contactNumber}
              onChange={handleChange("contactNumber")}
              onKeyPress={handleEnterKeyPress}
            />
          </Stack>
        </div>

        <Stack
          className="SearchButton"
          spacing={2}
          direction="row"
          justifyContent={"flex-middle"}
          marginBottom={1}
          marginTop={1}
        >
          <Stack direction="column">
            <Box
              sx={{
                border: "1px solid #c18eda",
                // marginTop: "20px",
                paddingRight: "20px",
                paddingLeft: "10px",
                borderRadius: "5px",
                // background: "#dc3545",
                color: "rgb(35, 48, 68)",
              }}
            >
              <CustomCheckbox
                color="default"
                size="small"
                checked={values.isActive}
                onChange={handleCheck("isActive")}
              />
              IsPaid
            </Box>
          </Stack>
          <LoadingButton
            loading={loading}
            loadingPosition="center"
            onClick={() => {
              setValues({ ...values, page : 1})
              handleSearchOrder({ ...values, page: 1 });
            }}
            variant="contained"
            className="search-button "
          >
            Search
          </LoadingButton>
          <Button
            variant="contained"
            className="reset-button"
            onClick={handleReset}
          >
            Reset
          </Button>
        </Stack>
        {loading ? (
          <CircularProgress
            className="Loading"
            loading={"true"}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <OrderTable pageNumber={values.page} />
        )}

        <Pagination
          sx={{
            marginLeft: "10px",
            marginRight: "10px",
            marginTop: "10px",
            paddingBottom: "10px",
            "Button.MuiPaginationItem-rounded.Mui-selected": {
              bgcolor: "rgb(35, 48, 68)",
              color: "rgb(238, 238, 238)",
            },
          }}
          count={parseInt(orderCount / 10) + (orderCount % 10 > 0 ? 1 : 0)}
          page={values.page}
          onChange={handlePageChange}
          shape="rounded"
          variant="outlined"
        />
      </Paper>
      <OrderView />
      <OrderEdit/>
    </div>
  );
}
