import React, { useContext } from "react";
import { orderContext } from "../../contexts/OrderContext";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, DialogContent,DialogTitle,Dialog,Stack, Box, Slide } from "@mui/material";
import moment from "moment";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function OrderView() {
  const { viewOrders, closeOrderViewModel, showOrderView } =
    useContext(orderContext);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={showOrderView}
      onClose={closeOrderViewModel}
      aria-labelledby="customized-dialog-title"
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          fontWeight: "bold",
          background: "rgb(35, 48,68)",
          color: "White",
          textAlign: "center",
        }}
        id="customized-dialog-title"
      >
        ORDER DETAILS - {viewOrders?._id}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeOrderViewModel}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent

        dividers
      >
        <Box sx={{ flexGrow: 1 }}>
          <Stack spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Contact Name:</p>
                  <p>
                    {/* {viewOrders?.employerId?.companyDetails?.contactNumber} */}
                    {viewOrders?.employerId?.companyDetails?.companyName
                      ? (viewOrders?.employerId?.companyDetails?.companyName)
                      : ("null")} </p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Contact Number:</p>
                  <p>
                    {/* {viewOrders?.employerId?.companyDetails?.contactNumber} */}
                    {viewOrders?.employerId?.companyDetails?.contactNumber
                      ? (viewOrders?.employerId?.companyDetails?.contactNumber)
                      : ("null")} </p>
                </Stack>
              </Grid>
            </Grid>
          

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Plan: </p>
                  <p>

                    {viewOrders?.planId?.name
                      ? viewOrders?.planId?.name
                      : "N/A"}
                  </p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Amount: </p>
                  <p>
                    {/* {viewOrders?.amount} */}
                    {viewOrders?.amount
                      ? viewOrders?.amount
                      : 0}
                  </p>
                  <p></p>
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> GST Tax:</p>
                  <p>
                    {/* {viewOrders?.gstTax} */}
                    {viewOrders?.gstTax ? viewOrders?.gstTax : "null"}
                  </p>
                </Stack>
              </Grid>

              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> orderKey:</p>
                  <p>
                    {/* {viewOrders?.orderKey} */}
                    {viewOrders?.orderKey ? viewOrders?.orderKey : "N/A"}
                  </p>
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Created at:</p>
                  <p>
                    {viewOrders?.createdAt
                      ? moment(viewOrders?.createdAt).format("D/MM/YYYY")
                      : ""}{" "}
                  </p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Updated at:</p>
                  <p>
                    {viewOrders?.updatedAt
                      ? moment(viewOrders?.updatedAt).format("D/MM/YYYY")
                      : ""}{" "}
                  </p>
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">RazorPayOrderId:</p>
                  <p>
                    {/* {viewOrders?.razorPayOrderId} */}
                    {viewOrders?.razorPayOrderId
                      ? viewOrders?.razorPayOrderId
                      : "null"}
                  </p>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
