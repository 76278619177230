import React, { useContext } from "react";
import moment from "moment";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  Paper,
  TableCell,
  tableCellClasses,
  Box,
  Tooltip,
  Chip,
  TableContainer,
  MenuItem,
  Menu,
  IconButton,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import PauseIcon from "@mui/icons-material/Pause";
import { employeeOrderContext } from "../../contexts/EmployeeOrderContext";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#233044",
    color: "#eeeeee",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default function EmployeeOrderTable({ pageNumber }) {
  const {
    EmployeeOrderData,
    handleSearchEmployeeOrder,
    employeeorderCount,
    employeeOrderView,
    employeeOrderviewMore,
    employeeOrderData,
    handleEmployeeOrderStatus,
  } = useContext(employeeOrderContext);
  const [selectedRecord, setSelectedRecord] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRecordId, setSelectedRecordId] = React.useState(null);
  const open = Boolean(anchorEl);
  let navigate = useNavigate();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <div className="TablePadding">
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell
                align="center"
                style={{ fontSize: "17px", width: "5vw" }}
              >
                #
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{ fontSize: "17px", width: "15vw" }}
              >
                Plan
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{ fontSize: "17px", width: "15vw" }}
              >
                Mobile
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ fontSize: "17px", width: "12vw" }}
              >
                Paid
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ fontSize: "17px", width: "12vw" }}
              >
                Created at
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ fontSize: "17px", width: "12vw" }}
              >
                Status
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{ fontSize: "17px", width: "2vw" }}
              >
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {employeeOrderData?.map((row, index) => (
              <StyledTableRow
                key={index}
                className="tableRow cell tableDataRow hove"
              >
                <StyledTableCell align="center" style={{ maxWidth: "5vw" }}>
                  <div> {index + 1 + (pageNumber - 1) * 10}</div>
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  style={{ maxWidth: "10vw" }}
                > {row?.employeePlanId?.planName}</StyledTableCell>
                <StyledTableCell align="left" style={{ maxWidth: "10vw" }}>
                  <div> {row?.userId?.mobile}</div>
                </StyledTableCell>

                <StyledTableCell align="center" style={{ maxWidth: "10vw" }}>
                  <div>{row?.paid === true ? "Yes" : "No"}</div>
                </StyledTableCell>

                <StyledTableCell align="center" style={{ minWidth: "13vw" }}>
                  {row?.createdAt
                    ? moment(row?.createdAt).format("D/MM/YYYY")
                    : ""}
                </StyledTableCell>

                <StyledTableCell align="center" style={{ maxWidth: "4vw" }}>
                  
                    <Chip
                      size="small"
                      sx={{
                        backgroundColor:
                          row?.status === true ? "#97c53c" : "#e48813",
                        color: row?.status === true ? "white" : "#fff",
                      }}
                      label={row?.status === true ? "Active" : "InActive"}
                    />
                  
                </StyledTableCell>

                <StyledTableCell align="left" style={{ minmaxWidth: "3vw" }}>
                  <div>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Tooltip title="Action">
                        <IconButton
                          onClick={(event) => {
                            handleMenu(event);
                            setSelectedRecordId(row?._id)
                            setSelectedRecord(row);
                          }}
                          size="small"
                          sx={{ ml: 2 }}
                          aria-controls={open ? "account-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      keepMounted
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 1px 1px rgb(211, 211, 211))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            maxWidth: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            left: 22,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: "left", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                     

                      <MenuItem
                        sx={{ color: "rgb(35, 48, 68)" }}
                        onClick={() => {
                          handleClose();
                          employeeOrderView(selectedRecordId);
                        }}
                      >
                        <SendIcon
                          sx={{ color: "#5072A7", marginRight: "10px" }}
                        />
                        View
                      </MenuItem>
                      {selectedRecord?.status === false ? (
                        <MenuItem
                          sx={{ color: "rgb(35, 48, 68)" }}
                          onClick={() => {
                                    handleEmployeeOrderStatus(
                              { status: true },
                              selectedRecord._id
                            );
                            handleClose();
                          }}
                        >
                          <PauseIcon
                            sx={{ color: "green", marginRight: "10px" }}
                          />
                          <Chip
                            size="small"
                            sx={{
                              backgroundColor: "#97c53c",
                              color: "white",
                            }}
                            label="Activate"
                          />
                        </MenuItem>
                      ) : (
                        <MenuItem
                          sx={{ color: "rgb(35, 48, 68)" }}
                          onClick={() => {
                            handleEmployeeOrderStatus(
                              { status: false },
                              selectedRecord._id
                            );
                            handleClose();
                          }}
                        >
                          <PauseIcon
                            sx={{ color: "#e48813", marginRight: "10px" }}
                          />
                          <Chip
                            size="small"
                            sx={{
                              backgroundColor: "#e48813",
                              color: "white",
                            }}
                            label="Inactivate"
                          />
                        </MenuItem>
                      )}
                    </Menu>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
