import React, { createContext, useState } from "react";
import axios from "axios";
import { backendUrl } from "../contants/contants";
import { toast } from "react-toastify";
export const feedbackContext = createContext({});
const FeedbackProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [feedbackData, setFeedbackData] = useState([]);
  const [viewFeedback, setViewFeedback] = useState([]);
  const [feedbackCount, setFeedbackCount] = useState(0);
  const [showFeedbackView, setShowFeedbackView] = useState(false);
  const showFeedbackViewModel = () => {
    setShowFeedbackView(true);
  };
  const closeFeedbackViewModel = () => {
    setShowFeedbackView(false);
  };
  const handleSearchFeedback = (data) => {
    setLoading(true);
    axios
      .post(backendUrl + "/api/crm/jobfeedback/list", data)
      .then((response) => {
        setFeedbackData(response.data.data.jobFeedbackList);
        setFeedbackCount(response.data.data.count);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const feedbackView = (id) => {
    axios
      .get(backendUrl + "/api/crm/jobfeedback?id=" + id)
      .then((response) => {
        setViewFeedback(response.data.data.jobFeedback);
        setShowFeedbackView(true);
      })
      .catch((e) => {});
  };

  return (
    <feedbackContext.Provider
      value={{
        loading,
        handleSearchFeedback,
        feedbackData,
        feedbackCount,
        showFeedbackView,
        showFeedbackViewModel,
        closeFeedbackViewModel,
        viewFeedback,
        feedbackView,
      }}
    >
      {children}
    </feedbackContext.Provider>
  );
};
export default FeedbackProvider;
