import React, { useEffect, useContext, useState } from "react";
import { locationContext } from "../../contexts/LocationContext";
import { Paper, Pagination, Typography, Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import LocationTable from "./Table";
import LocationEdit from "./LocationEdit";
export default function SmsLogs() {
  const [page, setPage] = useState(1);
  const { loading, handleSearchLocations,
    
    locationsCount } =
    useContext(locationContext);

  useEffect(() => {
    handleSearchLocations({ page });
  }, [page]);

  return (
    <div>
      <Paper
        sx={{
          textAlign: "center",
          padding: "5px",
        }}
      >
        <Box sx={{ justifyContent: "center", borderRadius: "5px" }} className="heading">
          <Typography variant="h5">
            Location({locationsCount ? parseInt(locationsCount).toLocaleString() : "NA"})
          </Typography>
        </Box>

        {loading ? (
          <CircularProgress
            className="Loading"
            loading={"true"}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <LocationTable pageNumber={page} />
        )}

        <Pagination
          sx={{
            marginLeft: "10px",
            marginRight: "10px",
            marginTop: "10px",
            paddingBottom: "10px",
            "Button.MuiPaginationItem-rounded.Mui-selected": {
              bgcolor: "rgb(35, 48, 68)",
              color: "rgb(238, 238, 238)",
            },
          }}
          count={parseInt(locationsCount / 10) + (locationsCount % 10 > 0 ? 1 : 0)}
          onChange={(event, value) => setPage(value)}
          shape="rounded"
          variant="outlined"
        />
      </Paper>
      <LocationEdit/>

    </div>
  );
}
