import React, { useContext } from "react";
import { searchResumeLogContext } from "../../contexts/SearchResumeLogContext";
import moment from "moment";
import { styled } from "@mui/material/styles";
 import { useNavigate } from "react-router-dom";
 import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  tableCellClasses,
  TableContainer,Paper
} from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#233044",
    color: "#eeeeee",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default function SearchResumeTable({ pageNumber }) {
  const { searchResumeData } = useContext(searchResumeLogContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRecordId, setSelectedRecordId] = React.useState(null);
  const open = Boolean(anchorEl);
  let navigate = useNavigate();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <div className="TablePadding">
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell
                align="left"
                style={{ maxWidth: "2vw" }}
              >
                #
              </StyledTableCell>
              <StyledTableCell
                style={{ maxWidth: "9vw" }}
                align="left"
              >
                Company Name
              </StyledTableCell>
              <StyledTableCell
                style={{ maxWidth: "13vw" }}
                align="center"
              >
                Company Number
              </StyledTableCell>
              <StyledTableCell
                style={{ maxWidth: "13vw" }}
                align="left"
              >
                Skill
              </StyledTableCell>
              <StyledTableCell
                style={{ maxWidth: "13vw" }}
                align="left"
              >
                Location
              </StyledTableCell>


              <StyledTableCell
                style={{ maxWidth: "7vw" }}
                align="center"
              >
                Created At
              </StyledTableCell>


              {/* <StyledTableCell
                style={{ maxWidth: "6vw" }}
                align="center"
              >
                Action
              </StyledTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {searchResumeData?.map((row, index) => (
              <StyledTableRow
                key={index}
                className="tableRow cell tableDataRow hove"
              >
                <StyledTableCell align="center" style={{ maxWidth: "4vw" }}>
                  {index + 1 + (pageNumber - 1) * 10}
                </StyledTableCell>
                <StyledTableCell align="left" style={{ maxWidth: "9vw" }}>
                  <div> {row?.employer?.companyDetails?.companyName}</div>
                </StyledTableCell>
                <StyledTableCell align="center" style={{ maxWidth: "9vw" }}>
                  <div> {row?.employer?.companyDetails?.contactNumber}</div>
                </StyledTableCell>
                <StyledTableCell align="left" style={{ maxWidth: "11vw" }}>
                  <div> {row?.skill?.name}</div>
                </StyledTableCell>
                <StyledTableCell align="left" style={{ maxWidth: "11vw" }}>
                  {/* <div> {row?.location?.city}</div> */}
                   
                    {row?.location 
                      ?row?.location?.city
                      : row?.location?.location
                     }
                </StyledTableCell>

                <StyledTableCell align="center">
                  {row?.createdAt
                    ? moment(row?.createdAt).format("D/MM/YYYY,h:mm:ss a")
                    : ""}
                </StyledTableCell>

                
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}