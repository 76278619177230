import React, { createContext, useState } from "react";
import axios from "axios";
import { backendUrl } from "../contants/contants";
export const employerContext = createContext({});
const EmployerProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [employerData, setEmployerData] = useState([]);
  const [showSearchEmployer, setShowSearchEmployer] = useState(false);
  const [employerCount, setEmployerCount] = useState(0);
  const [viewEmployer, setViewEmployer] = useState([]);
  const [employerList, setEmployerList] = useState([]);

  const [showEmployerView, setShowEmployerView] = useState(false);

  const closeEmployerViewModel = () => {
    setShowEmployerView(false);
  };
  const showEmployerViewModel = () => {
    setShowEmployerView(true);
  };

  const showSearchEmployerModel = () => {
    setShowSearchEmployer(true);
  };
  const closeSearchEmployerModel = () => {
    setShowSearchEmployer(false);
  };

  const handleSearchEmployer = (data) => {
    setLoading(true);
    axios
      .post(backendUrl + "/api/crm/employer/list", data)
      .then((response) => {
        setShowSearchEmployer(false);
        setEmployerData(response.data.data.employers);
        setEmployerCount(response.data.data.count);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const employerView = (id) => {
    axios
      .get(backendUrl + "/api/crm/employer?id=" + id)
      .then((response) => {
        setShowEmployerView(true);
        setViewEmployer(response.data.data.employer);
        setEmployerList(response.data.data.employerJobList);
      })
      .catch((e) => {});
  };
  return (
    <employerContext.Provider
      value={{
        loading,
        showSearchEmployerModel,
        closeSearchEmployerModel,
        handleSearchEmployer,
        employerData,
        showSearchEmployer,
        employerCount,
        viewEmployer,
        employerView,
        employerList,
        closeEmployerViewModel,
        showEmployerViewModel,
        showEmployerView,
      }}
    >
      {children}
    </employerContext.Provider>
  );
};

export default EmployerProvider;
