import React, { useEffect, useContext, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Box,
  Paper,
  Button,
  TextField,
  Switch,
  Stack,Pagination,
  Autocomplete,FormControl,
} from "@mui/material";
import SkillTable from "./Table";
import { appContext } from "../../contexts/AppContext";
import CircularProgress from "@mui/material/CircularProgress";
import SkillView from "./SkillView";
import LoadingButton from "@mui/lab/LoadingButton";
import { skillContext } from "../../contexts/SkillContext";
import SkillEdit from "./SkillEdit";
export default function Skills() {
  const {
    profile,
    employeeProfile,
    employeeLocation,
    editFunctional,
    editIndustry,
    editQualification,
   
    loading,
  } = useContext(appContext);
  const {
    skillsCount,
    handleSearchSkills,
  
  } = useContext(skillContext);

  useEffect(() => {
    employeeProfile();
    employeeLocation();
    editFunctional();
    editIndustry();
    editQualification();
    handleSearchSkills({ ...values });
  }, []);

  const [values, setValues] = useState({
    parent: "",
    isParent: false,
    showSubSkill: "",
    page: 1,
  });

  const handleChange = (prop) => (event) => {
    if (prop === "fromDate" || prop === "toDate") {
      setValues({ ...values, [prop]: event });
    } else {
      setValues({ ...values, [prop]: event.target.value });
    }
  };
  const handlePageChange = (event, value) => {
    setValues({ ...values, page: value });
    handleSearchSkills({ ...values, page: value });
  };

  const handleReset = () => {
    setValues({
      parent: "",
      isParent: false,
      showSubSkill: "",
      page: 1,
    });
    handleSearchSkills({
      parent: "",
      isParent: false,
      showSubSkill: "",
      page: 1,
    });
  };

  const handleCheck = (prop) => (event) => {
    if (prop === "isParent" && event.target.checked) {
      setValues({ ...values, parent: "", [prop]: event.target.checked });
    } else {
      setValues({ ...values, [prop]: event.target.checked });
    }
  };

  const handleChangeAutoComplete = (prop, value) => {
    setValues({ ...values, [prop]: value });
  };

  const handleEnterKeyPress = (event, value) => {
    if (event.key === "Enter") {
      handleSearchSkills({ ...values, page: value });
    }
  };

  return (
    <>
      <Paper
        sx={{
          textAlign: "center",
          padding: "5px",
        }}
      >
        <Box
          sx={{ justifyContent: "center", borderRadius: "5px" }}
          className="heading"
        >
          <Typography variant="h5">
            Skill ({skillsCount ? parseInt(skillsCount).toLocaleString() : "NA"}
            )
          </Typography>
        </Box>

        <Stack
          direction="row"
          spacing={15}
          justifyContent="space-evenly"
          sx={{ marginTop: "10px" }}
        >
          <Stack direction="column">
            <label>Is Parent</label>
            <Switch
              checked={values.isParent}
              onChange={handleCheck("isParent")}
            />
          </Stack>
          {values.isParent ? (
            <>
              <Stack direction="column">
                <label>Sub Skill: </label>
                <TextField
                  sx={{ width: "35vw" }}
                  size="small"
                  placeholder="Sub Skill"
                  value={values?.showSubSkill}
                  onChange={handleChange("showSubSkill")}
                  onKeyPress={handleEnterKeyPress}
                />
              </Stack>
            </>
          ) : (
            <>
              <Stack direction="column">
                <label>Parent: </label>
                <FormControl disabled={loading} size="small">
                  <Autocomplete
                    sx={{ width: "35vw" }}
                    size="small"
                    options={profile}
                    getOptionLabel={(option) => option.name ?? ""}
                    value={
                      profile.find(
                        (profile) => profile._id === values?.parent
                      ) ?? ""
                    }
                    // getOptionSelected={(option, value) => {
                    //   if (value === "") {
                    //     return true;
                    //   } else if (value === option) {
                    //     return true;
                    //   }
                    // }}
                    onChange={(event, value) => {
                      handleChangeAutoComplete("parent", value?._id);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="parent" />
                    )}
                    onKeyPress={handleEnterKeyPress}
                  />
                </FormControl>
              </Stack>
            </>
          )}
        </Stack>
        <Stack
          justifyContent="center"
          alignItems="center"
          direction="row"
          spacing={2}
          marginTop={2}
        >
          <LoadingButton
            loading={loading}
            loadingPosition="center"
            onClick={() => {
              setValues({ ...values, page : 1})
              handleSearchSkills(values);
            }}
            variant="contained"
            className="search-button"
          >
            Search
          </LoadingButton>
          <Button
            sx={{ marginLeft: "10px" }}
            variant="contained"
            className="reset-button"
            onClick={handleReset}
          >
            Reset
          </Button>
        </Stack>

        {loading ? (
          <CircularProgress
            sx={{
              marginTop: "15%",
              marginBottom: "15%",
            }}
            loading={"true"}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <SkillTable pageNumber={values.page} />
        )}

        <Pagination
          sx={{
            marginLeft: "10px",
            marginRight: "10px",
            marginTop: "10px",
            paddingBottom: "10px",
            "Button.MuiPaginationItem-rounded.Mui-selected": {
              bgcolor: "rgb(35, 48, 68)",
              color: "rgb(238, 238, 238)",
            },
          }}
          count={parseInt(skillsCount / 10) + (skillsCount % 10 > 0 ? 1 : 0)}
          page={values.page}
          onChange={handlePageChange}
          shape="rounded"
          variant="outlined"
        />
      </Paper>
      <SkillView />
      <SkillEdit/>
    </>
  );
}
