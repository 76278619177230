import React, { useContext } from "react";
import { smsLogsContext } from "../../contexts/SmsLogsContext";
import CloseIcon from "@mui/icons-material/Close";
import {Dialog,DialogTitle, DialogContent,IconButton,Grid, Stack, Box, Chip, Slide, Paper } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function PendingView() {
  const { viewSms, closeSmsViewModel, showSmsView } =
    useContext( smsLogsContext );

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={showSmsView}
      onClose={closeSmsViewModel}
      aria-labelledby="customized-dialog-title"
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          fontWeight: "bold",
          background: "rgb(35, 48, 68)",
          color: "White",
          textAlign: "center",
        }}
        id="customized-dialog-title"
      >
        SmsLogs DETAILS
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeSmsViewModel}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers sx={{ height: "50vh" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Stack spacing={1}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Company Name:</p>
                  <p> {viewSms?.jobId?.companyName} </p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Contact Number:</p>
                  <p>{viewSms?.jobId?.contactNumber} </p>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Profile: </p>
                  <p>{viewSms?.userId?.profile?.name} </p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Location: </p>
                  <p>{viewSms?.jobId?.location?.city} </p>
                </Stack>
              </Grid>
            </Grid>
            
            <Grid container spacing={2}>
              <Grid item xs={6} md={15}>
                <Stack direction="row" spacing={0.6}>
                  <label className="Bold">SMS:</label>
                  <textarea
                    rows={6}
                    disabled
                    className="Input"
                    value={viewSms?.sms}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
