import React, { useContext, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import {
  Stack,
  Box,
  Typography,
  TextField,
  Autocomplete,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,Checkbox
} from "@mui/material";
import { styled } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { jobPostedContext } from "../../contexts/JobPostedContext";
import { appContext } from "../../contexts/AppContext";
import { createFilterOptions } from "@mui/material/Autocomplete";


const CustomCheckbox = styled(Checkbox)({
  color: "rgb(35, 48, 68)",
  "&.Mui-checked": {
    color: "#9155fd",
  },
});
const filter = createFilterOptions();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function NormalJobEdit({ handleSearch }) {
  const {
    profile,
    employeeProfile,
    employeeSubProfile,
    loading,
    employeeLocation,
    locations,
    functionalArea,
    editFunctional,
    industry,
    editIndustry,
    qualification,
    editQualification,
    handleSkillSuggestion,
    skillSuggestion,
  } = useContext(appContext);

  const { viewJob, handleEditJob, closeNormalJobEditModel, showNormalJobEdit } =
    useContext(jobPostedContext);

  const [values, setValues] = useState({});

  useEffect(() => {
    handleSkillSuggestion();
    employeeProfile();
    employeeLocation();
    editFunctional();
    editIndustry();
    editQualification();
    setValues({
      parentSkill: viewJob?.parentSkill ? viewJob?.parentSkill : null,
      location: viewJob?.location?._id ? viewJob?.location?._id : null,
      functionalArea: viewJob?.functionalArea ? viewJob?.functionalArea : null,
      industry: viewJob.industry ? viewJob.industry : null,
      qualification: viewJob?.qualification ? viewJob?.qualification : null,
      name: viewJob?.name ? viewJob?.name : "",
      alias: viewJob?.alias ? viewJob?.alias : "",
      companyName: viewJob?.companyName ? viewJob?.companyName : "",
      contactPerson: viewJob?.contactPerson ? viewJob?.contactPerson : "",
      contactNumber: viewJob?.contactNumber ? viewJob?.contactNumber : "",
      contactEmail: viewJob?.contactEmail ? viewJob?.contactEmail : "",
      interviewAddress: viewJob?.interviewAddress
        ? viewJob?.interviewAddress
        : "",
      minSalary: viewJob?.minSalary ? viewJob?.minSalary : "",
      maxSalary: viewJob?.maxSalary ? viewJob?.maxSalary : "",
      experience: viewJob?.experience ? viewJob?.experience : "",
      gender: viewJob?.gender ? viewJob?.gender : 0,
      status: viewJob?.status ? viewJob?.status : 0,
      skills: viewJob?.skills ? viewJob?.skills : [],
      jobVacancy: viewJob?.jobVacancy ? viewJob?.jobVacancy : "",
      benefits: viewJob?.benefits ? viewJob?.benefits : [],
      description: viewJob?.description ? viewJob?.description : "",
      fullTime: viewJob?.fullTime ? viewJob?.fullTime : false,
      whatsappStatus: viewJob?.whatsappStatus ? viewJob?.whatsappStatus : "",
      callTimings: viewJob?.callTimings ? viewJob?.callTimings : "",
      order: viewJob?.order ? viewJob?.order : null,
      paidJob: viewJob?.paidJob ? viewJob?.paidJob : 0,
    });
  }, [viewJob?._id]);

  const handleProfileChange = (value) => {
    employeeSubProfile(value);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleCheck = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.checked.toString() });
  };

  const handleChecked = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.checked });
  };

  const handleChangeAutoComplete = (prop, value) => {
    setValues({ ...values, [prop]: value });
  };

  const handleSubmit = () => {
    const updatedValues = {
      ...values,
      skills: values.skills.map((skill) => skill._id),
    };


    handleEditJob(updatedValues, viewJob?._id, handleSearch);
  };

  return (
    <Dialog
      maxWidth={"lg"}
      open={showNormalJobEdit}
      onClose={closeNormalJobEditModel}
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          fontWeight: "bold",
          background: "rgb(35, 48, 68)",
          color: "White",
          textAlign: "center",
        }}
        id="customized-dialog-title"
      >
        UPDATE JOB POSTED
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeNormalJobEditModel}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers sx={{ height: "100vh" }}>
        {loading ? (
          <CircularProgress
            className="Loading"
            loading={"true"}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
            sx={{ marginLeft: "40%" }}
          />
        ) : (
          <Box>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-evenly"
              marginTop={0}
              alignContent="center"
            >
              <Stack direction="column">
                <label>Job Profile: </label>
                <Autocomplete
                  sx={{ width: "23vw" }}
                  size="small"
                  onChange={(event, newValue) => {
                    if (typeof newValue === "string") {
                      handleChangeAutoComplete("name", newValue);
                    } else if (newValue && newValue.inputValue) {
                      handleChangeAutoComplete("name", newValue.inputValue);
                    } else {
                      handleChangeAutoComplete("name", newValue?.name);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    console.log("options", options);
                    const { inputValue } = params;
                    const isExisting = options.some(
                      (option) => inputValue === option.name
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        inputValue,
                        name: `Create "${inputValue}"`,
                      });
                    }
                    return filtered;
                  }}
                  onInputChange={(event, newInputValue) => {
                    handleSkillSuggestion({
                      skillSearch:
                        newInputValue !== values.name ? newInputValue : "",
                      parentProfileId: "",
                    });
                  }}
                  getOptionLabel={(option) => option.name || values?.name}
                  options={skillSuggestion}
                  value={
                    skillSuggestion.find(
                      (skills) => skills._id === values?.name
                    ) || values?.name
                  }
                  renderInput={(params, index) => (
                    <TextField {...params} placeholder="Job Profile" />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option?._id}>
                      {option.name}
                    </li>
                  )}
                />
              </Stack>
              <Stack direction="column">
                <label>Profile: </label>
                <Autocomplete
                  sx={{ width: "23vw" }}
                  size="small"
                  options={profile}
                  getOptionLabel={(option) => option.name || ""}
                  value={
                    profile.find(
                      (profile) => profile._id === values?.parentSkill
                    ) || ""
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("parentSkill", value?._id);
                    handleProfileChange(value._id);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Profile" />
                  )}
                />
              </Stack>
              <Stack direction="column">
                <label>Industry: </label>
                <Autocomplete
                  sx={{ width: "24.5vw" }}
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={industry}
                  getOptionLabel={(option) => option.name || ""}
                  value={
                    industry.find(
                      (industry) => industry._id === values?.industry
                    ) || ""
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("industry", value?._id);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Industry" />
                  )}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-evenly"
              marginTop={1.5}
              alignContent="center"
            >
              <Stack direction="column">
                <label>Functional Area : </label>
                <Autocomplete
                  sx={{ width: "28vw" }}
                  size="small"
                  options={functionalArea}
                  getOptionLabel={(option) => option.name || ""}
                  value={
                    functionalArea.find(
                      (functionalArea) =>
                        functionalArea._id === values?.functionalArea
                    ) || ""
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("functionalArea", value?._id);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Functional Area" />
                  )}
                />
              </Stack>
              <Stack direction="column">
                <label>Facilities: </label>
                <Autocomplete
                  sx={{ width: "43.5vw" }}
                  size="small"
                  multiple
                  filterSelectedOptions
                  options={benefits}
                  getOptionLabel={(option) => option.label || ""}
                  value={
                    benefits.filter((benefits) =>
                      values?.benefits?.includes(benefits.value)
                    ) || ""
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete(
                      "benefits",
                      value.map((benefits) => benefits.value)
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Facilities" />
                  )}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              alignContent="center"
              justifyContent="space-evenly"
              marginTop={1.5}
            >
              <Stack direction="column">
                <label>Skills: </label>
                <Autocomplete
                  sx={{ width: "72vw" }}
                  size="small"
                  multiple
                  filterOptions={(options, { inputValue }) =>
                  options.filter((option) =>
                    !values.skills.map((skill) => skill.name).includes(option.name)
                  )
                }
                  onInputChange={(event, newInputValue) => {
                    handleSkillSuggestion({
                      skillSearch: newInputValue,
                      parentProfileId: values.parentSkill,
                    });
                  }}
                  onOpen={() => {
                    handleSkillSuggestion({
                      skillSearch: "",
                      parentProfileId: values.parentSkill,
                    });
                  }}
                  options={skillSuggestion}
                  getOptionLabel={(option) => option.name}
                  value={values?.skills}
                  onChange={(event, value) => {
                    handleChangeAutoComplete("skills", value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Skills" />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option?._id}>
                      {option?.name}
                    </li>
                  )}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-evenly"
              marginTop={1.5}
            >
              <Stack direction="column">
                <label>Qualification: </label>
                <Autocomplete
                  sx={{ width: "21vw" }}
                  size="small"
                  options={qualification}
                  getOptionLabel={(option) => option.name || ""}
                  value={
                    qualification.find(
                      (qualification) =>
                        qualification._id === values?.qualification
                    ) || ""
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("qualification", value?._id);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Qualification" />
                  )}
                />
              </Stack>
              <Stack direction="column">
                <label>Order: </label>
                <TextField
                  sx={{ width: "19vw" }}
                  size="small"
                  placeholder="Order"
                  value={values?.order}
                  onChange={handleChange("order")}
                />
              </Stack>
              <Stack direction="column">
                <label> PaidJob :</label>
                <Autocomplete
                  sx={{ width: "14vw" }}
                  size="small"
                  options={PaidJob}
                  getOptionLabel={(option) => option.label}
                  value={PaidJob.find(
                    (paidJob) => paidJob.value === values?.paidJob
                  )}
                  onChange={(event, value) => {
                    handleChangeAutoComplete("paidJob", value?.value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="PaidJob" />
                  )}
                />
              </Stack>
              <Stack direction="column">
                <label>Job Vacancy: </label>
                <TextField
                  type="number"
                  inputProps={{ min: 0 }}
                  sx={{ width: "15vw" }}
                  placeholder="Job Vacancy"
                  size="small"
                  value={values.jobVacancy}
                  onChange={handleChange("jobVacancy")}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-evenly"
              marginTop={1.5}
            >
              <Stack direction="column">
                <label>Status: </label>
                <Autocomplete
                  sx={{ width: "17vw" }}
                  size="small"
                  options={Status}
                  getOptionLabel={(option) => option.label}
                  value={Status.find(
                    (status) => status.value === values?.status
                  )}
                  onChange={(event, value) => {
                    handleChangeAutoComplete("status", value?.value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Status" />
                  )}
                />
              </Stack>
              <Stack direction="column">
                <label>Experience: </label>
                <Autocomplete
                  sx={{ width: "13vw" }}
                  size="small"
                  options={Experience}
                  getOptionLabel={(option) => option.label || ""}
                  value={
                    Experience.find(
                      (experience) => experience.value === values?.experience
                    ) || ""
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("experience", value?.value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Experience" />
                  )}
                />
              </Stack>
              <Stack direction="column">
                <label>Gender: </label>

                <Autocomplete
                  sx={{ width: "13vw" }}
                  size="small"
                  options={Gender}
                  getOptionLabel={(option) => option.label || ""}
                  value={
                    Gender.find((gender) => gender.value === values?.gender) ||
                    0
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("gender", value?.value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Gender" />
                  )}
                />
              </Stack>
              <Stack direction="column">
                <label>Min Salary: </label>

                <Autocomplete
                  sx={{ width: "12vw" }}
                  size="small"
                  options={MinSalary}
                  getOptionLabel={(option) => option.label || values?.minSalary}
                  value={
                    MinSalary.find(
                      (minSalary) => minSalary.value === values?.minSalary
                    ) || values?.minSalary
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("minSalary", value?.value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="MinSalary" />
                  )}
                />
              </Stack>
              <Stack direction="column">
                <label>Max Salary: </label>
                <Autocomplete
                  sx={{ width: "12vw" }}
                  size="small"
                  options={MaxSalary.filter(
                    (salary) => salary.value > values.minSalary
                  )}
                  getOptionLabel={(option) => option.label || values?.maxSalary}
                  value={
                    MaxSalary.find(
                      (maxSalary) => maxSalary.value === values?.maxSalary
                    ) || values?.maxSalary
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("maxSalary", value?.value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="MaxSalary" />
                  )}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-around"
              marginTop={1.5}
            >
              <Stack direction="column">
                <label>Location: </label>
                <Autocomplete
                  sx={{ width: "24vw" }}
                  size="small"
                  options={locations}
                  getOptionLabel={(option) => option.city || ""}
                  value={
                    locations.find(
                      (locations) => locations._id === values?.location
                    ) || values?.location
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("location", value?._id);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Locations" />
                  )}
                />
              </Stack>
              <Stack direction="column">
                <Box
                  sx={{
                    border: "2px solid #c18eda",
                    marginTop: "19px",
                    borderRadius: "5px",
                    color: "#dc3545",
                    width: "22vw",
                  }}
                >
                  <CustomCheckbox
                    color="default"
                    size="small"
                    checked={
                      values.callTimings === "on" ||
                      values.callTimings === "true"
                        ? true
                        : false
                    }
                    onChange={handleCheck("callTimings")}
                  />
                  Call Timing 10:00 am - 7:00 pm
                </Box>
              </Stack>
              <Stack direction="column">
                <Box
                  sx={{
                    border: "2px solid #c18eda",
                    marginTop: "19px",
                    borderRadius: "5px",
                    borderColor: "#c18eda",

                    width: "12vw",
                  }}
                >
                  <CustomCheckbox
                    color="default"
                    size="small"
                    checked={
                      values.whatsappStatus === "on" ||
                      values.whatsappStatus === "true"
                        ? true
                        : false
                    }
                    onChange={handleCheck("whatsappStatus")}
                  />
                  Whatsapp
                </Box>
              </Stack>
              <Stack direction="column">
                <Box
                  sx={{
                    border: "2px solid #c18eda",
                    marginTop: "19px",
                    borderRadius: "5px",

                    color: "#28a745",
                    width: "10vw",
                  }}
                >
                  <CustomCheckbox
                    color="default"
                    size="small"
                    checked={values.fullTime}
                    onChange={handleChecked("fullTime")}
                  />
                  Full Time
                </Box>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-evenly"
              marginTop={1.5}
            >
              <Stack direction="column" sx={{ width: "72vw" }}>
                <label> Description</label>
                <textarea
                  placeholder="Description"
                  name="description"
                  rows={1}
                  className="Input"
                  value={values.description}
                  onChange={handleChange("description")}
                />
              </Stack>
            </Stack>
            <Typography
              sx={{
                fontSize: "25px",
                paddingLeft: "3px",
                marginTop: "15px",
              }}
            >
              Company Details
            </Typography>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-evenly"
              alignContent="center"
              marginTop={1.5}
            >
              <Stack direction="column">
                <label>Company Name: </label>
                <TextField
                  sx={{ width: "21vw" }}
                  size="small"
                  placeholder="Company Name"
                  value={values.companyName}
                  onChange={handleChange("companyName")}
                ></TextField>
              </Stack>
              <Stack direction="column">
                <label>Contact Person: </label>
                <TextField
                  sx={{ width: "20vw" }}
                  size="small"
                  placeholder="Contact Person"
                  value={values?.contactPerson}
                  onChange={handleChange("contactPerson")}
                />
              </Stack>

              <Stack direction="column">
                <label>Company Email: </label>
                <TextField
                  sx={{ width: "19vw" }}
                  size="small"
                  placeholder="Company Email"
                  value={values?.contactEmail}
                  onChange={handleChange("contactEmail")}
                />
              </Stack>
              <Stack direction="column">
                <label>Contact Number: </label>
                <TextField
                  sx={{ width: "10vw" }}
                  size="small"
                  placeholder="Contact Number"
                  value={values?.contactNumber}
                  onChange={handleChange("contactNumber")}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              spacing={3}
              justifyContent="space-evenly"
              marginTop={1.5}
            ></Stack>
            <Stack
              direction="row"
              justifyContent="space-evenly"
              marginTop={1.5}
            >
              <Stack direction="column" sx={{ width: "72vw" }}>
                <label> Interview Address</label>
                <textarea
                  placeholder=" Interview Address"
                  name="description"
                  rows={3}
                  className="Input"
                  value={values?.interviewAddress}
                  onChange={handleChange("Interview Address")}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing={3}
              sx={{
                marginTop: "10px",
                justifyContent: "center",
              }}
            >
              <Button
                className="search-button"
                variant="contained"
                onClick={handleSubmit}
              >
                update
              </Button>
              <Button
                className="reset-button"
                variant="contained"
                onClick={closeNormalJobEditModel}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}

const Gender = [
  { label: "Male", value: 1 },
  { label: "Female", value: 2 },
  { label: "Both", value: 3 },
];

const benefits = [
  { label: "Esi/PF", value: "Esi/PF" },
  { label: "Lodge", value: "Lodge" },
  { label: "Food", value: "Food" },
  { label: "Bonus", value: "Bonus" },
];

const Status = [
  { label: "Active", value: 1 },
  { label: "Inactive", value: 0 },
];
const PaidJob = [
  { label: 0, value: 0 },
  { label: 1, value: 1 },
];

const Experience = [
  { label: "Fresher", value: "0+" },
  { label: "1+ Years", value: "1+" },
  { label: "2+ Years", value: "2+" },
  { label: "3+ Years", value: "3+" },
  { label: "4+ Years", value: "4+" },
  { label: "5+ Years", value: "5+" },
  { label: "6+ Years", value: "6+" },
  { label: "7+ Years", value: "7+" },
  { label: "8+ Years", value: "8+" },
  { label: "9+ Years", value: "9+" },
  { label: "10+ Years", value: "10+" },
];

const MaxSalary = [
  { label: "12000", value: 12000 },
  { label: "14000", value: 14000 },
  { label: "16000", value: 16000 },
  { label: "18000", value: 18000 },
  { label: "20000", value: 20000 },
  { label: "22000", value: 22000 },
  { label: "24000", value: 24000 },
  { label: "26000", value: 26000 },
  { label: "28000", value: 28000 },
  { label: "30000", value: 30000 },
  { label: "35000", value: 35000 },
  { label: "40000", value: 40000 },
  { label: "45000", value: 45000 },
  { label: "50000", value: 50000 },
  { label: "60000", value: 60000 },
  { label: "70000", value: 70000 },
  { label: "80000", value: 80000 },
  { label: "90000", value: 90000 },
  { label: "100000", value: 100000 },
  { label: "110000", value: 110000 },
];

const MinSalary = [
  { label: "10000", value: 10000 },
  { label: "12000", value: 12000 },
  { label: "14000", value: 14000 },
  { label: "16000", value: 16000 },
  { label: "18000", value: 18000 },
  { label: "20000", value: 20000 },
  { label: "22000", value: 22000 },
  { label: "24000", value: 24000 },
  { label: "26000", value: 26000 },
  { label: "28000", value: 28000 },
  { label: "30000", value: 30000 },
  { label: "35000", value: 35000 },
  { label: "40000", value: 40000 },
  { label: "45000", value: 45000 },
  { label: "50000", value: 50000 },
  { label: "60000", value: 60000 },
  { label: "70000", value: 70000 },
  { label: "80000", value: 80000 },
];
