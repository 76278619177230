import React, { useContext } from "react";
import { orderContext } from "../../contexts/OrderContext";
import moment from "moment";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  tableCellClasses,
  Box,
  Tooltip,
  Chip,
  MenuItem,
  Menu,
  IconButton,
} from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#233044",
    color: "#eeeeee",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default function OrderTable({ pageNumber }) {
  const { OrderData, ordersView,orderEdit } = useContext(orderContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRecordId, setSelectedRecordId] = React.useState(null);
  const open = Boolean(anchorEl);
  let navigate = useNavigate();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <div className="TablePadding">

      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell
              align="center"
              style={{ fontSize: "17px", maxWidth: "3vw" }}
            >
              #
            </StyledTableCell>
            <StyledTableCell style={{ maxWidth: "10vw" }} align="left">
              Company Name
            </StyledTableCell>
            <StyledTableCell style={{ maxWidth: "12vw" }} align="center">
              Contact Number
            </StyledTableCell>
            <StyledTableCell style={{ maxWidth: "5vw" }} align="center">
              Plan
            </StyledTableCell>
            <StyledTableCell style={{ maxWidth: "5vw" }} align="left">
              Plan Type
            </StyledTableCell>
            <StyledTableCell style={{ maxWidth: "5vw" }} align="center">
              Amount
            </StyledTableCell>
            <StyledTableCell style={{ maxWidth: "13vw" }} align="center">
             Status
            </StyledTableCell>
            <StyledTableCell style={{ maxWidth: "5vw" }} align="center">
              Created At
            </StyledTableCell>
            <StyledTableCell align="center" style={{ maxWidth: "3vw" }}>
              Action
            </StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {OrderData?.map((row, index) => (

            <StyledTableRow key={index} className="tableRow cell tableDataRow hove">
              <StyledTableCell align="center" style={{ maxWidth: "3vw" }}>
                {index + 1 + (pageNumber - 1) * 10}
              </StyledTableCell>
              <StyledTableCell align="left"style={{ maxWidth: "10vw" }}>
                <div> {row?.employerId?.companyDetails?.companyName}</div>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ maxWidth: "12vw" }}>
                <div> {row?.employerId?.companyDetails?.contactNumber}</div>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ maxWidth: "5vw" }}> 
              {row?.planId?.name}
               </StyledTableCell>
               <StyledTableCell align="left" style={{ maxWidth: "5vw" }}> 
              {row?.planId?.planType}
               </StyledTableCell>
              <StyledTableCell align="center"style={{ maxWidth: "5vw" }}>
                <div> {row?.amount}</div>
              </StyledTableCell>
              <StyledTableCell align="center"style={{ maxWidth: "17vw" }}>
              <Chip
                    size="small"
                    sx={{
                      backgroundColor:
                        row?.status === 1 ? "#97c53c" : "#e48813",
                      color: row?.status === 1 ? "white" : "#fff",
                    }}
                    label={row?.status === 1 ? "Active" : "InActive"}
                  />
              </StyledTableCell>
              <StyledTableCell align="center" style={{ maxWidth: "6vw" }}>
                <div>
                 
                  {row?.createdAt
                    ? moment(row?.createdAt).format("D/MM/YYYY")
                    : ""}
                </div>
              </StyledTableCell>
              <StyledTableCell align="left" style={{ minmaxWidth: "3vw" }}>
                <div>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Tooltip title="Action">
                      <IconButton
                        onClick={(event) => {
                          handleMenu(event);
                          setSelectedRecordId(row?._id);
                        }}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}

                    keepMounted
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 1px 1px rgb(211, 211, 211))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          maxWidth: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          left: 22,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "left", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem
                      sx={{ color: "rgb(35, 48, 68)" }}
                      onClick={() => {
                      

                        handleClose();
                        orderEdit(selectedRecordId);
                      }}
                    >
                      <EditIcon
                        sx={{ color: "#228B22", marginRight: "10px" }}
                      />
                      Edit
                    </MenuItem>

                    <MenuItem
                      sx={{ color: "rgb(35, 48, 68)" }}
                      onClick={() => {
                        handleClose();
                        ordersView(selectedRecordId);
                      }}
                    >
                      <SendIcon
                        sx={{ color: "#5072A7", marginRight: "10px" }}
                      />
                      View
                    </MenuItem>
                  </Menu>
                </div>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}