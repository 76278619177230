import React, { useEffect, useContext, useState } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import  {searchResumeLogContext}  from "../../contexts/SearchResumeLogContext";
import LoadingButton from "@mui/lab/LoadingButton";
import {Stack, Typography,TextField,Button,Pagination, Paper, Box,CircularProgress } from "@mui/material";
import SearchResumeTable from "./Table";

export default function SearchResumeLogs() {
  const {
    loading,
    handleSearchResumeLogs,
    searchResumeCount,
  } = useContext(searchResumeLogContext);

  const [values, setValues] = useState({
    fromDate: null,
    toDate: null,
    contactNumber: "",
    page: 1
  });
  useEffect(() => {
    handleSearchResumeLogs({ ...values });
  }, []);

  const handlePageChange = (event, value) => {
    setValues({ ...values, page: value });
    handleSearchResumeLogs({ ...values, page: value });
  };

  const handleReset = () => {
    setValues({
      fromDate: null,
      toDate: null,
      contactNumber: "",
      // planId: "",
      page: 1
    });
    handleSearchResumeLogs({
      fromDate: null,
      toDate: null,
      contactNumber: "",
      // planId: "",
      page: 1
    });
  };

  const handleChange = (prop) => (event) => {
    if (prop === "fromDate" || prop === "toDate") {
      setValues({ ...values, [prop]: event });
    } else {
      setValues({ ...values, [prop]: event.target.value });
    }
  };
  const handleEnterKeyPress = (event , value) => {
    if (event.key === "Enter") {
      handleSearchResumeLogs({ ...values, page: value });
    }
  };
  return (
    <div>
      <Paper
        sx={{
          textAlign: "center",
          padding: "5px",
        }}
      >
        <Box
          sx={{ justifyContent: "center", borderRadius: "5px" }}
          className="heading"
        >
          <Typography variant="h5">
            Search Resume Logs(
            {searchResumeCount
              ? parseInt(searchResumeCount).toLocaleString()
              : "NA"}
            )
          </Typography>
        </Box>



        <Stack
          className="SearchButton"
          spacing={2}
          direction="row"
          justifyContent={"flex-middle"}
          marginBottom={1}
          marginTop={1}
        >

          <DesktopDatePicker
            sx={{
              width: "17vw",
              textAlign: "center",
              justifyContent: "center",
            }}
            value={values.fromDate}
            onChange={handleChange("fromDate")}
            label="From*"
            format="DD/MM/YYYY"
            maxDate={values.toDate}
            slotProps={{ textField: { size: "small" } }}
            onKeyPress={handleEnterKeyPress}
          />

          <DesktopDatePicker
            sx={{
              width: "17vw",
              textAlign: "center",
              justifyContent: "center",
            }}
            value={values.toDate}
            onChange={handleChange("toDate")}
            label="To*"
            format="DD/MM/YYYY"
            minDate={values.fromDate}
            slotProps={{ textField: { size: "small" } }}
            onKeyPress={handleEnterKeyPress}
          />

<TextField
          inputProps={{
            maxLength: 10,
          }}
          disabled={loading}
          size="small"
          sx={{
            width: "17vw",
            textAlign: "center",
            justifyContent: "center",
            marginRight: "20px",
          }}
          label="Contact Number"
          value={values.contactNumber}
          onChange={handleChange("contactNumber")}
          onKeyPress={handleEnterKeyPress}
        />
          <LoadingButton
            loading={loading}
            loadingPosition="center"
            onClick={() => {
              setValues({ ...values, page : 1})
              handleSearchResumeLogs({ ...values, page: 1 })
            }}
            variant="contained"
            className="search-button "
          >
            Search
          </LoadingButton>
          <Button
            variant="contained"
            className="reset-button"

            onClick={handleReset}
          >
            Reset
          </Button>
        </Stack>
        {loading ? (
          <CircularProgress
            className="Loading"
            loading={"true"}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <SearchResumeTable pageNumber={values.page} />
        )}

        <Pagination
          sx={{
            marginLeft: "10px",
            marginRight: "10px",
            marginTop: "10px",
            paddingBottom: "10px",
            "Button.MuiPaginationItem-rounded.Mui-selected": {
              bgcolor: "rgb(35, 48, 68)",
              color: "rgb(238, 238, 238)",
            },
          }}
          page={values.page}
          count={parseInt(searchResumeCount / 10)}
          // onChange={(event, value) => setPage(value)}
          onChange={handlePageChange}
          shape="rounded"
          variant="outlined"
        />
      </Paper>
      {/* <EmployeeOrderView /> */}
    </div>
  );
}
