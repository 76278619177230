import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate, Navigate } from "react-router-dom";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import Stack from "@mui/material/Stack";
import PersonIcon from "@mui/icons-material/Person";
import SmsRoundedIcon from "@mui/icons-material/SmsRounded";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import DomainIcon from "@mui/icons-material/Domain";
import { MenuItem, Menu, Tooltip } from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CircleAppLogo from "../images/CircleAppLogo.png";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import WorkIcon from "@mui/icons-material/Work";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import AppsIcon from "@mui/icons-material/Apps";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import WavesIcon from "@mui/icons-material/Waves";
import { jwtDecode } from "jwt-decode";

export default function PrivateRoute({ component: Component, ...rest }) {
  let navigate = useNavigate();
  const [showMenu, setShowMenu] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    const isTokenValid = (token) => {
      try {
        const decodedToken = jwtDecode(token);

        // Check if the token has expired
        if (decodedToken.exp * 1000 < Date.now()) {
          console.log("Token has expired");
          localStorage.removeItem('auth_token')
          localStorage.removeItem('userInfo')
          
          navigate("/login");
          return false;
        }
        if (decodedToken.iss !== "theincircle") {
          console.log("Token has invalid theincircle");
          localStorage.removeItem('auth_token')
          localStorage.removeItem('userInfo')
          navigate("/login");
          return false;
        }
        // Token is valid
        console.log("Token is valid");
        return true;
      } catch (error) {
        console.error("Error decoding or verifying the token:", error);
        localStorage.removeItem('auth_token')
        localStorage.removeItem('userInfo')
        navigate("/login");
        return false;
      }
    };
    isTokenValid(localStorage.getItem("auth_token"));
  }, []);

  if (localStorage.getItem("auth_token")) {
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar
            sx={{ backgroundColor: "white", color: "Black" }}
            variant="dense"
          >
            <IconButton
              onClick={() => setShowMenu(!showMenu)}
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>

            <img
              src={`${CircleAppLogo}?fit=crop&auto=format`}
              srcSet={`${CircleAppLogo}?fit=crop&auto=format&dpr=2 2x`}
              alt={"logo"}
              loading="lazy"
              className="logoimage"
            />

            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              The In Circle
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Tooltip>
                <IconButton
                  onClick={handleMenu}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <AccountCircleOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 1px 1px rgb(211, 211, 211))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  localStorage.removeItem("auth_token");
                  navigate("/login");
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        {showMenu && (
          <Box>
            <Drawer
              variant="permanent"
              sx={{
                width: "18vw",
                [`& .MuiDrawer-paper`]: {
                  boxSizing: "border-box",
                  position: "centre",
                  backgroundColor: "rgb(35, 48, 68)",
                  color: "rgb(238, 238, 238)",
                },
              }}
            >
              <Toolbar />
              <List>
                <Stack className="SideBar">
                  <ListItem
                    sx={{ width: "18vw", paddingTop: 0, paddingBottom: 0 }}
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                  >
                    <AddHomeWorkIcon sx={{ color: "#c4507c" }} />
                    <ListItemButton>
                      <ListItemText>Home</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    sx={{ width: "18vw", paddingTop: 0, paddingBottom: 0 }}
                    onClick={() => {
                      navigate("/employee");
                    }}
                  >
                    <PersonIcon sx={{ color: "#8F00FF" }} />
                    <ListItemButton>
                      <ListItemText>Employee</ListItemText>
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    sx={{ width: "18vw", paddingTop: 0, paddingBottom: 0 }}
                    onClick={() => {
                      navigate("/employer");
                    }}
                  >
                    <PeopleAltIcon sx={{ color: "#82b74b" }} />
                    <ListItemButton>
                      <ListItemText>Employer</ListItemText>
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    sx={{ width: "18vw", paddingTop: 0, paddingBottom: 0 }}
                    onClick={() => {
                      navigate("/skill");
                    }}
                  >
                    <WorkHistoryIcon sx={{ color: "#FF7676" }} />
                    <ListItemButton>
                      <ListItemText>Skills</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    sx={{ width: "18vw", paddingTop: 0, paddingBottom: 0 }}
                    onClick={() => {
                      navigate("/smslogs");
                    }}
                  >
                    <SmsRoundedIcon sx={{ color: "#78cff2" }} />
                    <ListItemButton>
                      <ListItemText>SMS Logs</ListItemText>
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    sx={{ width: "18vw", paddingTop: 0, paddingBottom: 0 }}
                    onClick={() => {
                      navigate("/jobPosted");
                    }}
                  >
                    <WorkIcon sx={{ color: "#cc99a2" }} />
                    <ListItemButton>
                      <ListItemText>Job Posted</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    sx={{ width: "18vw", paddingTop: 0, paddingBottom: 0 }}
                    onClick={() => {
                      navigate("/orders");
                    }}
                  >
                    <ShoppingCartIcon sx={{ color: "#008170" }} />
                    <ListItemButton>
                      <ListItemText>Orders</ListItemText>
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    sx={{ width: "18vw", paddingTop: 0, paddingBottom: 0 }}
                    onClick={() => {
                      navigate("/employeeOrder");
                    }}
                  >
                    <AddShoppingCartIcon sx={{ color: "#D2DE32" }} />
                    <ListItemButton>
                      <ListItemText>Employee Orders</ListItemText>
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    sx={{ width: "18vw", paddingTop: 0, paddingBottom: 0 }}
                    onClick={() => {
                      navigate("/industries");
                    }}
                  >
                    <DomainIcon sx={{ color: "#33FF93" }} />
                    <ListItemButton>
                      <ListItemText>Industries</ListItemText>
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    sx={{ width: "18vw", paddingTop: 0, paddingBottom: 0 }}
                    onClick={() => {
                      navigate("/pendingjobpost");
                    }}
                  >
                    <BusinessCenterIcon sx={{ color: "#98E4FF" }} />
                    <ListItemButton>
                      <ListItemText>Pending Jobs</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    sx={{ width: "18vw", paddingTop: 0, paddingBottom: 0 }}
                    onClick={() => {
                      navigate("/videoUpload");
                    }}
                  >
                    <VideoLibraryIcon sx={{ color: "#7fff00" }} />
                    <ListItemButton>
                      <ListItemText>Video Upload</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    sx={{ width: "18vw", paddingTop: 0, paddingBottom: 0 }}
                    onClick={() => {
                      navigate("/Location");
                    }}
                  >
                    <LocationOnIcon sx={{ color: "#ae0e52" }} />
                    <ListItemButton>
                      <ListItemText>Location</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    sx={{ width: "18vw", paddingTop: 0, paddingBottom: 0 }}
                    onClick={() => {
                      navigate("/plans");
                    }}
                  >
                    <WavesIcon sx={{ color: "#d0ae8b" }} />
                    <ListItemButton>
                      <ListItemText>Plans</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    sx={{ width: "18vw", paddingTop: 0, paddingBottom: 0 }}
                    onClick={() => {
                      navigate("/jobApplication");
                    }}
                  >
                    <AppsIcon sx={{ color: "#96EFFF" }} />
                    <ListItemButton>
                      <ListItemText>Job Applications</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    sx={{ width: "18vw", paddingTop: 0, paddingBottom: 0 }}
                    onClick={() => {
                      navigate("/feedback");
                    }}
                  >
                    <ThumbUpOffAltIcon sx={{ color: "#FF8F8F" }} />
                    <ListItemButton>
                      <ListItemText>Feedback</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    sx={{ width: "18vw", paddingTop: 0, paddingBottom: 0 }}
                    onClick={() => {
                      navigate("/searchResumeLogs");
                    }}
                  >
                    <ManageSearchIcon sx={{ color: "#35A29F" }} />
                    <ListItemButton>
                      <ListItemText>Search Resume Logs</ListItemText>
                    </ListItemButton>
                  </ListItem>
                </Stack>
              </List>
            </Drawer>
          </Box>
        )}
        <Box
          sx={{
            p: 1,
            width: showMenu ? "81vw" : "100vw",
            marginTop: 5,
          }}
        >
          <Component />
        </Box>
      </Box>
    );
  } else {
    return <Navigate to="/login" />;
  }
}
