import React from "react";
import {CardActionArea, Box, Paper , Stack,Typography } from "@mui/material";

export default function VideoUpload() {
  return (
    
      <div>
        <Paper
          sx={{
            textAlign: "center",
            padding: "5px",
          }}
        >
          <CardActionArea>
            <Box
              sx={{ justifyContent: "center", borderRadius: "5px" }}
              className="heading"
            >
              <Typography variant="h5">Video Upload</Typography>
            </Box>
          </CardActionArea>

          <div className="Label">
            <label>Thumbnail:</label>
            <input
              placeholder="Thumbnail"
              name="thumbnail"
              type="text"
              className="Input"
            />
          </div>
          <div className="Label">
            <label>Video URL</label>
            <input
              placeholder="Video URL"
              name="videoUrl"
              type="text"
              className="Input"
            />
          </div>

          <div className="Row">
            <div className="Label1">
              <label>Video Duration</label>
              <input
                placeholder="Video Duration"
                name="videoDuration"
                type="text"
                className="Input"
              />
            </div>
            <div className="Label1">
              <label>Video Category</label>
              <input
                placeholder="Video Category"
                name="videoCategory"
                type="text"
                className="Input"
              />
            </div>
          </div>

          <div className="Label">
            <label>Video Description</label>
            <textarea
              placeholder="Video Description"
              name="description"
              rows={3}
              className="Input"
            />
          </div>
          <Stack>
            <button className="video-Button">Upload</button>
          </Stack>
        </Paper>
      </div>
    
  );
}
