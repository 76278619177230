import React, { useContext, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import {
  Stack,
  Box,
  TextField,
  Autocomplete,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { employeeContext } from "../../contexts/EmployeeContext";
import { appContext } from "../../contexts/AppContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function NormalJobEdit({ handleSearch }) {
  const {
    profile,
    employeeProfile,
    employeeSubProfile,
    loading,
    employeeLocation,
    locations,
    industry,
    editIndustry,
    handleSkillSuggestion,
    skillSuggestion,
  } = useContext(appContext);

  const {
    viewEmployee,
    handleEditEmployee,
    closeEmployeeEditModel,
    showEmployeeEdit,
  } = useContext(employeeContext);

  const [values, setValues] = useState({});

  useEffect(() => {
    handleSkillSuggestion({
      skillSearch: viewEmployee?.subProfile?.name,
      parentProfileId: "",
    });
    employeeProfile();
    employeeLocation();
    editIndustry();
    setValues({
      name: viewEmployee?.basicInfo?.name ?? "",
      mobile: viewEmployee?.mobile,
      gender: viewEmployee?.basicInfo?.gender ?? 1,
      profile: viewEmployee?.profile?._id ?? null,
      subProfile: viewEmployee?.subProfile?._id ?? null,
      skills: viewEmployee?.skills ?? [],
      workLocality: viewEmployee?.workLocality?._id ?? null,
      currentSalary: viewEmployee?.currentSalary ?? 0,
      industry: viewEmployee?.industry?.map((industry) => industry?._id) ?? [],
      experience: viewEmployee?.experience,
    });
  }, [viewEmployee?._id]);

  const handleProfileChange = (value) => {
    employeeSubProfile(value);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeAutoComplete = (prop, value) => {
    setValues({ ...values, [prop]: value });
  };

  const handleSubmit = () => {
    const updatedValues = {
      ...values,
      skills: values.skills.map((skill) => skill._id),
      basicInfo : {
        name : values.name,
        gender: values.gender
      }
    };
    delete updatedValues.name;
    delete updatedValues.gender;
    handleEditEmployee(updatedValues, viewEmployee?._id, handleSearch);
  };

  return (
    <Dialog
      maxWidth={"lg"}
      open={showEmployeeEdit}
      onClose={closeEmployeeEditModel}
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          fontWeight: "bold",
          background: "rgb(35, 48, 68)",
          color: "White",
          textAlign: "center",
        }}
        id="customized-dialog-title"
      >
        UPDATE EMPLOYEE
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeEmployeeEditModel}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers sx={{ height: "100vh" }}>
        {loading ? (
          <CircularProgress
            className="Loading"
            loading={"true"}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
            sx={{ marginLeft: "40%" }}
          />
        ) : (
          <Box>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-evenly"
              marginTop={0}
              alignContent="center"
            >
              <Stack direction="column">
                <label>Mobile: </label>
                <TextField
                  sx={{ width: "23vw" }}
                  size="small"
                  placeholder="mobile"
                  value={values?.mobile}
                  disabled
                />
              </Stack>
              <Stack direction="column">
                <label>Profile: </label>
                <Autocomplete
                  sx={{ width: "23vw" }}
                  size="small"
                  options={profile}
                  getOptionLabel={(option) => option.name || ""}
                  value={
                    profile.find(
                      (profile) => profile._id === values?.profile
                    ) || ""
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("profile", value?._id);
                    handleProfileChange(value._id);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Profile" />
                  )}
                />
              </Stack>
              <Stack direction="column">
                <label>Industry: </label>
                <Autocomplete
                  sx={{ width: "24.5vw" }}
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={industry}
                  getOptionLabel={(option) => option.name || ""}
                  value={
                    values?.industry
                      ? industry.find(
                          (industry) =>
                            industry._id === values?.industry[0]
                        ) || ""
                      : ""
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("industry", [value?._id]);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Industry" />
                  )}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              alignContent="center"
              justifyContent="space-evenly"
              marginTop={1.5}
            >
              <Stack direction="column">
                <label>Skills: </label>
                <Autocomplete
                  sx={{ width: "72vw" }}
                  size="small"
                  multiple
                  filterOptions={(options, { inputValue }) =>
                    options.filter(
                      (option) =>
                        !values.skills
                          .map((skill) => skill.name)
                          .includes(option.name)
                    )
                  }
                  onInputChange={(event, newInputValue) => {
                    handleSkillSuggestion({
                      skillSearch: newInputValue,
                      parentProfileId: values.profile,
                    });
                  }}
                  onOpen={() => {
                    handleSkillSuggestion({
                      skillSearch: "",
                      parentProfileId: values.profile,
                    });
                  }}
                  options={skillSuggestion}
                  getOptionLabel={(option) => option.name}
                  value={values?.skills}
                  onChange={(event, value) => {
                    handleChangeAutoComplete("skills", value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Skills" />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option?._id}>
                      {option?.name}
                    </li>
                  )}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-evenly"
              marginTop={1.5}
            >
              <Stack direction="column">
                <label>Name: </label>
                <TextField
                  sx={{ width: "40vw" }}
                  size="small"
                  placeholder="Name"
                  value={values?.name}
                  onChange={handleChange("name")}
                />
              </Stack>
              <Stack direction="column">
                <label>Sub Profile: </label>
                <Autocomplete
                  sx={{ width: "31vw" }}
                  size="small"
                  options={skillSuggestion}
                  getOptionLabel={(option) => option.name || ""}
                  value={
                    skillSuggestion.find(
                      (subProfile) => subProfile._id === values?.subProfile
                    ) || ""
                  }
                  filterOptions={(options, { inputValue }) =>
                    options.filter(
                      (option) =>
                        !values.skills
                          .map((skill) => skill.name)
                          .includes(option.name)
                    )
                  }
                  onInputChange={(event, newInputValue) => {
                    handleSkillSuggestion({
                      skillSearch: newInputValue,
                      parentProfileId: values.profile,
                    });
                  }}
                  onChange={(event, value) => {
                    handleChangeAutoComplete("subProfile", value?._id);
                  }}
                  onOpen={() => {
                    handleSkillSuggestion({
                      skillSearch: values?.subProfile?.name,
                      parentProfileId: values.profile,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Profile" />
                  )}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-evenly"
              marginTop={1.5}
            >
              <Stack direction="column">
                <label>Location: </label>
                <Autocomplete
                  sx={{ width: "24vw" }}
                  size="small"
                  options={locations}
                  getOptionLabel={(option) => option.city || ""}
                  value={
                    locations.find(
                      (locations) => locations._id === values?.workLocality
                    ) || values?.location
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("workLocality", value?._id);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Locations" />
                  )}
                />
              </Stack>
              <Stack direction="column">
                <label>Experience: </label>
                <Autocomplete
                  sx={{ width: "15vw" }}
                  size="small"
                  options={Experience}
                  getOptionLabel={(option) => option.label || ""}
                  value={
                    Experience.find(
                      (experience) => experience.value === values?.experience
                    ) || ""
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("experience", value?.value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Experience" />
                  )}
                />
              </Stack>
              <Stack direction="column">
                <label>Gender: </label>
                <Autocomplete
                  sx={{ width: "14vw" }}
                  size="small"
                  options={Gender}
                  getOptionLabel={(option) => option.label || ""}
                  value={
                    Gender.find((gender) => gender.value === values?.gender) ||
                    0
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("gender", value?.value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Gender" />
                  )}
                />
              </Stack>
              <Stack direction="column">
                <label>Current Salary: </label>
                <TextField
                  type="number"
                  inputProps={{ min: 0 }}
                  sx={{ width: "14vw" }}
                  size="small"
                  placeholder="Current Salary"
                  value={values?.currentSalary}
                  onChange={handleChange("currentSalary")}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing={3}
              sx={{
                marginTop: "30px",
                justifyContent: "center",
              }}
            >
              <Button
                className="search-button"
                variant="contained"
                onClick={handleSubmit}
              >
                Update
              </Button>
              <Button
                className="reset-button"
                variant="contained"
                onClick={closeEmployeeEditModel}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}

const Gender = [
  { label: "Male", value: 1 },
  { label: "Female", value: 2 },
  { label: "Both", value: 3 },
];

const Experience = [
  { label: "Fresher", value: 0 },
  { label: "1+ Years", value: 1 },
  { label: "2+ Years", value: 2 },
  { label: "3+ Years", value: 3 },
  { label: "4+ Years", value: 4 },
  { label: "5+ Years", value: 5 },
  { label: "6+ Years", value: 6 },
  { label: "7+ Years", value: 7 },
  { label: "8+ Years", value: 8 },
  { label: "9+ Years", value: 9 },
  { label: "10+ Years", value: 10 },
  { label: "11+ Years", value: 11 },
  { label: "12+ Years", value: 12 },
  { label: "13+ Years", value: 13 },
  { label: "14+ Years", value: 14 },
  { label: "15+ Years", value: 15 },
];
