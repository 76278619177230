import React, { useContext } from "react";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TableContainer,
  Grid,
  Stack,
  Slide,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Paper,
  TableCell,
  Box,
} from "@mui/material";

import { employerContext } from "../../contexts/EmployerContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function View() {
  const {
    viewEmployer,
    employerList,
    showEmployerView,
    closeEmployerViewModel,
  } = useContext(employerContext);
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={showEmployerView}
      onClose={closeEmployerViewModel}
      aria-labelledby="customized-dialog-title"
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          fontWeight: "bold",
          background: "rgb(35, 48, 68)",
          color: "White",
          textAlign: "center",
        }}
        id="customized-dialog-title"
      >
        EMPLOYER DETAILS
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeEmployerViewModel}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        // sx={{ width: 800, height: 700, marginLeft: "45px" }}
        dividers
      >
        <Box sx={{ flexGrow: 1 }}>
          <Stack spacing={0.1}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Contact person:</p>
                  <p>{viewEmployer?.companyDetails?.contactPerson}</p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Company Name: </p>
                  <p>{viewEmployer?.companyDetails?.companyName}</p>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Mobile Number:</p>
                  <p> {viewEmployer?.companyDetails?.contactNumber}</p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Location:</p>
                  <p>
                    {/* {viewEmployer?.companyDetails?.location?.city} */}
                    {viewEmployer?.companyDetails?.location?.city
                      ? viewEmployer?.companyDetails?.location?.city
                      : "N/A"}
                  </p>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Saved Resume:</p>
                  <p>
                    {/* {viewEmployer?.savedResumeCount} */}
                    {viewEmployer?.savedResumeCount
                      ? viewEmployer?.savedResumeCount
                      : "0"}
                  </p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Free Jobs:</p>
                  <p>
                    {/* {viewEmployer?.freeJobs} */}
                    {viewEmployer?.freeJobs ? viewEmployer?.freeJobs : "0"}
                  </p>
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Created at: </p>
                  <p>
                   
                    {viewEmployer?.createdAt
                      ? moment(viewEmployer?.createdAt).format("D/MM/YYYY")
                      : ""}
                  </p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Updated at: </p>
                  <p>
                    {viewEmployer?.updatedAt
                      ? moment(viewEmployer?.updatedAt).format("D/MM/YYYY")
                      : ""}
                  </p>
                </Stack>
              </Grid>
            </Grid>

          

            <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Paid Job:</p>
                  <p>
                    {/* {viewEmployer?.companyDetails?.address} */}
                    {viewEmployer?.paidJob ? viewEmployer?.paidJob : "0"}
                  </p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Email:</p>
                  <p>{viewEmployer?.companyDetails?.emailAddress}</p>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={20}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Address:</p>
                  <p>
                    {/* {viewEmployer?.companyDetails?.address} */}
                    {viewEmployer?.companyDetails?.address
                      ? viewEmployer?.companyDetails?.address
                      : "N/A"}
                  </p>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6} className="Bold">
                Employer Jobs({employerList?.length})
              </Grid>
            </Grid>
          </Stack>
        </Box>

        <div className="TablePadding">
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      background: "rgb(35, 48, 68)",
                      color: "White",
                      textAlign: "center",
                      fontSize: "15px",
                      minWidth: "1px",
                    }}
                  >
                   
                    #
                  </TableCell>
                  <TableCell
                    style={{
                      background: "rgb(35, 48, 68)",
                      color: "White",

                      fontSize: "15px",
                      minWidth: "1px",
                    }}
                    align="left"
                  >
                    Job Name
                  </TableCell>
                  <TableCell
                    style={{
                      background: "rgb(35, 48, 68)",
                      color: "White",

                      fontSize: "15px",
                      minWidth: "1px",
                    }}
                    align="left"
                  >
                    Company Name
                  </TableCell>

                  <TableCell
                    style={{
                      background: "rgb(35, 48, 68)",
                      color: "White",

                      fontSize: "15px",
                      minWidth: "1px",
                    }}
                    align="left"
                  >
                    Response
                  </TableCell>

                  <TableCell
                    style={{
                      background: "rgb(35, 48, 68)",
                      color: "White",

                      fontSize: "15px",
                      minWidth: "1px",
                    }}
                    align="left"
                  >
                    Job Vacancy
                  </TableCell>
                  <TableCell
                    style={{
                      background: "rgb(35, 48, 68)",
                      color: "White",

                      fontSize: "15px",
                      minWidth: "1px",
                    }}
                    align="left"
                  >
                    Job Type
                  </TableCell>
                  <TableCell
                    style={{
                      background: "rgb(35, 48, 68)",
                      color: "White",
                      textAlign: "center",
                      fontSize: "15px",
                      minWidth: "1px",
                    }}
                    align="center"
                  >
                    Posted on
                  </TableCell>
                  <TableCell
                    style={{
                      background: "rgb(35, 48, 68)",
                      color: "White",
                      textAlign: "center",
                      fontSize: "15px",
                      minWidth: "1px",
                    }}
                    align="center"
                  >
                    Job Link
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {employerList?.map((row, index) => {
                  return (
                    <TableRow key={index} className="tableRow">
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="left"> {row?.name}</TableCell>
                      <TableCell align="left"> {row?.companyName}</TableCell>
                      <TableCell align="center">
                       
                        {row?.numApplications}
                      </TableCell>
                      <TableCell align="center">
                       
                        {row?.jobCategory === "Normal"
                          ? row?.jobVacancy
                          : row?.numberOfEmployees}
                      </TableCell>

                      <TableCell align="center">
                       
                      {row?.paidJob === 1 ? "Paid" : "Free"}
                     </TableCell>
                      <TableCell align="center">
                       
                        {row?.createdAt
                          ? moment(row?.createdAt).format("D/MM/YYYY")
                          : ""}
                      </TableCell>
                      <TableCell align="center">
                       
                        <Link
                          target="_blank"
                          to={
                            "https://www.theincircle.com/employerzone/viewjobpost/" +
                            row._id
                          }
                        >
                         
                          Job Link
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </DialogContent>
    </Dialog>
  );
}
