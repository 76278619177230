// import * as React from "react";
// import {
//   Button,
//   TextField,
//   Stack,
//   Typography,
//   Modal,
//   Box,
// } from "@mui/material";

// const style = {
//   position: "absolute",
//   top: "100%",
//   left: "100%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
// };

// export default function EmployeeCreateModal() {
//   const [open, setOpen] = React.useState(false);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   return (
//     <div>
//       <Button variant="contained" className="reset-button" onClick={handleOpen}>
//         Create
//       </Button>
//       <Modal open={open} onClose={handleClose}>
//         <Box sx={style}>
//           <Typography
//             sx={{
//               fontWeight: "bold",
//               background: "rgb(35, 48, 68)",
//               color: "White",
//               textAlign: "center",
//             }}
//             id="modal-modal-title"
//             variant="h6"
//             component="h2"
//           >
//             Create Order for 299 perks
//           </Typography>
//           <Stack
//             direction="row"
//             spacing={2}
//             justifyContent="space-evenly"
//             marginTop={1}
//           >
//             <Stack direction="column">
//               <label>Mobile Number: </label>
//               <TextField
//                 sx={{ width: "20vw" }}
//                 size="small"
//                 placeholder="Mobile Number"
//               />
//             </Stack>
//           </Stack>
//           <Stack
//             direction="row"
//             spacing={2}
//             justifyContent="space-evenly"
//             marginTop={1}
//             padding={2}
//           >
//             <Button variant="contained" className="reset-button">
//               Submit
//             </Button>
//           </Stack>
//         </Box>
//       </Modal>
//     </div>
//   );
// }

import React, { useContext } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Box,
  Slide,
  TextField,
  Button,
} from "@mui/material";
import { employeeOrderContext } from "../../contexts/EmployeeOrderContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function EmployeeOrderView() {
  const { closeEmployeeCreateOrderViewModel, showEmployeeCreateOrderView } =
    useContext(employeeOrderContext);
  return (
    <Dialog
      fullWidth
      open={showEmployeeCreateOrderView}
      onClose={closeEmployeeCreateOrderViewModel}
      aria-labelledby="customized-dialog-title"
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          fontWeight: "bold",
          background: "rgb(35, 48, 68)",
          color: "White",
          textAlign: "center",
        }}
        id="customized-dialog-title"
      >
        Create Order for 299 perks
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeEmployeeCreateOrderViewModel}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ width: 600, height: 300 }} dividers>
        <Box sx={{ flexGrow: 1 }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-evenly"
            marginTop={1}
          >
            <Stack direction="column">
              <label>Mobile Number: </label>
              <TextField
                sx={{ width: "20vw" }}
                size="small"
                placeholder="Mobile Number"
              />
            </Stack>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-evenly"
            marginTop={1}
            padding={2}
          >
            <Button variant="contained" className="reset-button">
              Submit
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
