import React, { createContext, useState } from "react";
import axios from "axios";
import { backendUrl } from "../contants/contants";

import { toast } from "react-toastify";
export const planContext = createContext({});
const PlanProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
    const [plansData, setPlansData] = useState([]);
  const [plansCount, setPlansCount] = useState(0);
  const [viewPlans, setViewPlans] = useState([]);
  const [showEditPlans, setShowEditPlans] = useState(false);
  const [showPlan, setShowplan] = useState(false);
  const [showPlanEdit, setShowPlanEdit] = useState(false);
  const closePlanEditModel = () => {
    setShowPlanEdit(false);
  };
  const showPlanEditModel = () => {
    setShowPlanEdit(true);
  };

  const showPlanModel = () => {
    setShowplan(true);
  };
  const closePlanModel = () => {
    setShowplan(false);
  };
  const handleSearchPlans = (data) => {
    setLoading(true);
    axios
      .post(backendUrl + "/api/crm/plan/list", data)
      .then((response) => {
        setShowplan(false);
        setPlansData(response.data.data.plan);
        setPlansCount(response.data.data.count);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const plansEdit = (id) => {
    axios
      .get(backendUrl + "/api/crm/plan?id=" + id)
      .then((response) => {
        setViewPlans(response.data.data.plan);
        setShowPlanEdit(true);
      })
      .catch((e) => {});
  };

  const handleEditPlans = (data, _id) => {
    axios
      .put(
        backendUrl + "/api/crm/plan/update?id=" + _id,
        data
      )
      .then((response) => {
        setShowEditPlans(false);
        toast.success("Successfully Updated!");
      })
      .catch((e) => {});
  };
  return (
    <planContext.Provider
      value={{
        loading,
        showPlanModel,
        closePlanModel,
        showPlan,
        handleSearchPlans,
        viewPlans,
        plansEdit,
        showEditPlans,
        handleEditPlans,
        plansData,showPlanEditModel,
        plansCount,showPlanEdit,closePlanEditModel,
      }}
    >
      {children}
    </planContext.Provider>
  );
};
export default PlanProvider