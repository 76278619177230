import React, { useContext } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Slide,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from "@mui/material";
import moment from "moment";
import { jobPostedContext } from "../../contexts/JobPostedContext";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function JobResponses() {
  const { jobResponses, closeJobResponseModel, showJobResponse } =
    useContext(jobPostedContext);

  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      open={showJobResponse}
      onClose={closeJobResponseModel}
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          fontWeight: "bold",
          background: "rgb(35, 48, 68)",
          color: "White",
          textAlign: "center",
        }}
        id="customized-dialog-title"
      >
        JOB Responses
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeJobResponseModel}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Box sx={{ flexGrow: 1 }}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      background: "rgb(35, 48, 68)",
                      color: "White",
                      textAlign: "center",
                      fontSize: "17px",
                    }}
                  >
                    #
                  </TableCell>
                  <TableCell
                    style={{
                      background: "rgb(35, 48, 68)",
                      color: "White",
                      textAlign: "left",
                      fontSize: "17px",
                    }}
                    align="center"
                  >
                    Name
                  </TableCell>
                  <TableCell
                    style={{
                      background: "rgb(35, 48, 68)",
                      color: "White",
                      textAlign: "left",
                      fontSize: "17px",
                    }}
                    align="center"
                  >
                    Mobile
                  </TableCell>
                  <TableCell
                    style={{
                      background: "rgb(35, 48, 68)",
                      color: "White",
                      textAlign: "left",
                      fontSize: "17px",
                    }}
                    align="center"
                  >
                    Profile
                  </TableCell>
                  <TableCell
                    style={{
                      background: "rgb(35, 48, 68)",
                      color: "White",
                      textAlign: "left",
                      fontSize: "17px",
                    }}
                    align="center"
                  >
                    SubProfile
                  </TableCell>
                  <TableCell
                    style={{
                      background: "rgb(35, 48, 68)",
                      color: "White",
                      textAlign: "left",
                      fontSize: "17px",
                    }}
                    align="center"
                  >
                    Location
                  </TableCell>
                  <TableCell
                    style={{
                      background: "rgb(35, 48, 68)",
                      color: "White",
                      textAlign: "center",
                      fontSize: "17px",
                    }}
                    align="center"
                  >
                    Applied on
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jobResponses?.map((row, index) => {
                  return (
                    <TableRow key={index} className="tableRow">
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="left">
                        {" "}
                        {row?.user?.basicInfo?.name}
                      </TableCell>
                      <TableCell align="left">{row?.user?.mobile}</TableCell>
                      <TableCell align="left">
                        {row?.user?.profile?.name}
                      </TableCell>
                      <TableCell align="left">
                        {row?.user?.subProfile?.name}
                      </TableCell>
                      <TableCell align="left">
                        {row?.user?.workLocation
                          ? row?.user?.workLocation?.city
                          : row?.user?.workLocality
                          ? row?.user?.workLocality?.city
                          : row?.user?.location?.city}
                      </TableCell>
                      <TableCell align="center">
                        {row?.createdAt
                          ? moment(row?.createdAt).format("D/MM/YYYY")
                          : ""}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
