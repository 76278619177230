import React, { useEffect, useContext, useState } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Stack } from "@mui/material";
import View from "./View";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Checkbox,
  Pagination,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Paper,
  Box,
  FormControl,
  Autocomplete,
} from "@mui/material";
import { styled } from "@mui/system";
import EmployerTable from "./Table";
import { appContext } from "../../contexts/AppContext";
import { employerContext } from "../../contexts/EmployerContext";

const CustomCheckbox = styled(Checkbox)({
  color: "rgb(35, 48, 68)",
  "&.Mui-checked": {
    color: "#9155fd",
  },
});

export default function Employer() {
  const { employeeLocation, locations } = useContext(appContext);

  const { handleSearchEmployer, employerCount, loading } =
    useContext(employerContext);

  const [values, setValues] = useState({
    fromDate: null,
    toDate: null,
    contactNumber: "",
    email: "",
    location: "",
    isActive: true,
    jobPosted: false,
    page: 1,
  });

  useEffect(() => {
    employeeLocation();
    handleSearchEmployer({ ...values });
  }, []);

  const handlePageChange = (event, value) => {
    setValues({ ...values, page: value });
    handleSearchEmployer({ ...values, page: value });
  };

  const handleReset = () => {
    setValues({
      fromDate: null,
      toDate: null,
      contactNumber: "",
      email: "",
      location: "",
      page: 1,
      isActive: true,
      jobPosted: false,
    });
    handleSearchEmployer({
      fromDate: null,
      toDate: null,
      contactNumber: "",
      email: "",
      location: "",
      page: 1,
      isActive: true,
      jobPosted: false,
    });
  };

  const handleChange = (prop) => (event) => {
    if (prop === "fromDate" || prop === "toDate") {
      setValues({ ...values, [prop]: event });
    } else {
      setValues({ ...values, [prop]: event.target.value });
    }
  };

  const handleChangeAutoComplete = (prop, value) => {
    setValues({ ...values, [prop]: value });
  };

  const handleCheck = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.checked });
  };
  const handleEnterKeyPress = (event, value) => {
    if (event.key === "Enter") {
      handleSearchEmployer({ ...values, page: value });
    }
  };

  return (
    <Paper
      sx={{
        textAlign: "center",
        padding: "5px",
      }}
    >
      <Box
        sx={{ justifyContent: "center", borderRadius: "5px" }}
        className="heading"
      >
        <Typography variant="h5">
          Employer(
          {employerCount ? parseInt(employerCount).toLocaleString() : "NA"})
        </Typography>
      </Box>
      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={2}
        sx={{
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <DesktopDatePicker
          disabled={loading}
          sx={{
            width: "14vw",
            textAlign: "center",
            justifyContent: "center",
            marginLeft: "10px",
          }}
          value={values.fromDate}
          onChange={handleChange("fromDate")}
          label="From*"
          format="DD/MM/YYYY"
          maxDate={values.toDate}
          slotProps={{ textField: { size: "small" } }}
          onKeyPress={handleEnterKeyPress}
        />

        <DesktopDatePicker
          disabled={loading}
          sx={{
            width: "14vw",
            textAlign: "center",
            justifyContent: "center",
            marginLeft: "10px",
          }}
          value={values.toDate}
          onChange={handleChange("toDate")}
          label="To*"
          minDate={values.fromDate}
          format="DD/MM/YYYY"
          slotProps={{ textField: { size: "small" } }}
          onKeyPress={handleEnterKeyPress}
        />
        <TextField
          inputProps={{
            maxLength: 10,
          }}
          disabled={loading}
          size="small"
          sx={{
            width: "14vw",
            textAlign: "center",
            justifyContent: "center",
            marginRight: "20px",
          }}
          label="Mobile Number"
          value={values.contactNumber}
          onChange={handleChange("contactNumber")}
          onKeyDown={handleEnterKeyPress}
        />
        <TextField
          disabled={loading}
          size="small"
          sx={{
            width: "14vw",
            textAlign: "center",
            justifyContent: "center",
            marginRight: "20px",
          }}
          label="Email"
          value={values.email}
          onChange={handleChange("email")}
          onKeyDown={handleEnterKeyPress}
        />
        <FormControl disabled={loading} size="small">
          <Autocomplete
            sx={{ width: "14vw" }}
            size="small"
            options={locations}
            getOptionLabel={(option) => option.city ?? ""}
            value={
              locations.find(
                (locations) => locations._id === values?.location
              ) ?? ""
            }
            onChange={(event, value) => {
              handleChangeAutoComplete("location", value?._id);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Locations" />
            )}
            onKeyDown={handleEnterKeyPress}
          />
        </FormControl>
      </Stack>
      <Stack
        className="SearchButton"
        spacing={2}
        direction="row"
        justifyContent={"flex-middle"}
        marginBottom={1}
        marginTop={1}
      >
        <Stack direction="row" spacing={2}>
          <Box
            sx={{
              border: "1px solid #c18eda",
              paddingRight: "20px",
              paddingLeft: "10px",
              borderRadius: "5px",
              color: "rgb(35, 48, 68)",
            }}
          >
            <CustomCheckbox
              color="default"
              size="small"
              checked={values.isActive}
              onChange={handleCheck("isActive")}
            />
            IsActive
          </Box>

          <Box
            sx={{
              border: "1px solid #c18eda",
              paddingRight: "20px",
              paddingLeft: "10px",
              borderRadius: "5px",
              color: "rgb(35, 48, 68)",
            }}
          >
            <CustomCheckbox
              color="default"
              size="small"
              checked={values.jobPosted}
              onChange={handleCheck("jobPosted")}
            />
            Job Posted
          </Box>
        </Stack>

        <LoadingButton
          disabled={loading}
          loadingPosition="center"
          onClick={() => {
            setValues({ ...values, page: 1 });
            handleSearchEmployer({ ...values, page: 1 });
          }}
          variant="contained"
          className="search-button"
        >
          Search
        </LoadingButton>
        <Button
          variant="contained"
          className="reset-button"
          onClick={handleReset}
        >
          Reset
        </Button>
      </Stack>
      {loading ? (
        <CircularProgress
          className="Loading"
          loading={"true"}
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <EmployerTable pageNumber={values.page} />
      )}
      <Pagination
        sx={{
          marginLeft: "10px",
          marginRight: "10px",
          marginTop: "10px",
          paddingBottom: "10px",
          "Button.MuiPaginationItem-rounded.Mui-selected": {
            bgcolor: "rgb(35, 48, 68)",
            color: "rgb(238, 238, 238)",
          },
        }}
        // defaultPage={1}
        count={parseInt(employerCount / 10) + (employerCount % 10 > 0 ? 1 : 0)}
        page={values.page}
        onChange={handlePageChange}
        shape="rounded"
        variant="outlined"
      />
      <View />
    </Paper>
  );
}
