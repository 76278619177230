import React, { useContext, useState, useEffect } from "react";
import { appContext } from "../../contexts/AppContext";
import { orderContext } from "../../contexts/OrderContext";
import { useParams, useNavigate } from "react-router-dom";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  Dialog,
  DialogContent,
  Button,
  Slide,
  DialogTitle,
  CircularProgress,
  Stack,
  Box,
  TextField,
  Autocomplete,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
export default function OrderEdit() {
  const {
    editPlan,
  } = useContext(appContext);
  const {
    loading,
    viewOrders,
    showOrderEdit,
    closeOrderEditModel,
    orderEdit,
    handleUpdateOrder,
  } = useContext(orderContext);
  let navigate = useNavigate();
  const handleChangeAutoComplete = (prop, value) => {
    setValues({ ...values, [prop]: value });
  };
  const params = useParams();
  useEffect(() => {
    editPlan();
    orderEdit();
  }, []);

  useEffect(() => {
    setValues({
      plan: viewOrders.planId?.name ? viewOrders?.planId?.name : " ",
      orderKey: viewOrders?.orderKey ? viewOrders?.orderKey : "",
      razorPayOrderId: viewOrders?.razorPayOrderId
        ? viewOrders?.razorPayOrderId
        : "",
      receipt: viewOrders?.receipt ? viewOrders?.receipt : "",
      razorPayPaymentId: viewOrders?.razorPayPaymentId
        ? viewOrders?.razorPayPaymentId
        : "",
      razorPaySignature: viewOrders?.razorPaySignature
        ? viewOrders?.razorPaySignature
        : "",
      type: viewOrders?.type ? viewOrders?.type : "",
      qty: viewOrders?.qty ? viewOrders?.qty : "",
      additionalPlanId: viewOrders?.additionalPlanId
        ? viewOrders?.additionalPlanId
        : null,
      additionalPlanRDays: viewOrders?.additionalPlanRDays
        ? viewOrders?.additionalPlanRDays
        : "",
      rResume: viewOrders?.rResume ? viewOrders?.rResume : "",
      gstTax: viewOrders?.gstTax ? viewOrders?.gstTax : "",
      payment: viewOrders?.payment ? viewOrders?.payment : "",
      terms: viewOrders.terms ? viewOrders.terms : "",
      status: viewOrders?.status ? viewOrders?.status : 0,
      sendMail: viewOrders?.sendMail ? viewOrders?.sendMail : "",
      companyName: viewOrders?.employerId?.companyDetails?.companyName
        ? viewOrders?.employerId?.companyDetails?.companyName
        : "",
      contactNumber: viewOrders?.employerId?.companyDetails?.contactNumber
        ? viewOrders?.employerId?.companyDetails?.contactNumber
        : "",
      amount: viewOrders?.amount ? viewOrders?.amount : "",
      isUsed: viewOrders?.isUsed ? viewOrders?.isUsed : 0,
      job: viewOrders?.job ? viewOrders?.job : null,
    });
  }, [viewOrders]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleSubmit = () => {
    handleUpdateOrder(values, viewOrders?._id);
    closeOrderEditModel();
  };

  const [values, setValues] = useState({});
  return (
    <Dialog
      maxWidth={"lg"}
      open={showOrderEdit}
      onClose={closeOrderEditModel}
      aria-labelledby="customized-dialog-title"
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          fontWeight: "bold",
          background: "rgb(35, 48, 68)",
          color: "White",
          textAlign: "center",
        }}
        id="customized-dialog-title"
      >
        ORDER EDIT
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeOrderEditModel}
        sx={{
          position: "absolute",
          right: 8,
          top: 5,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers sx={{ height: "100vh" }}>
        {loading ? (
          <CircularProgress
            className="Loading"
            loading={loading}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
            sx={{ marginLeft: "40%" }}
          />
        ) : (
          <Box>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-evenly"
              sx={{ marginTop: "10px" }}
            >
              <Stack direction="column">
                <label>Payment: </label>
                <Autocomplete
                  sx={{ width: "16vw" }}
                  size="small"
                  options={payment}
                  getOptionLabel={(option) => option.label}
                  value={payment.find(
                    (payment) => payment.value === values?.payment
                  )}
                  onChange={(event, value) => {
                    handleChangeAutoComplete("payment", value?.value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Payment" />
                  )}
                />
              </Stack>
              <Stack direction="column">
                <label>RemainingNumberOfResume: </label>
                <TextField
                  inputProps={{ min: 0 }}
                  sx={{ width: "18vw" }}
                  size="small"
                  placeholder="rResume"
                  type="number"
                  value={values.rResume}
                  onChange={handleChange("rResume")}
                />
              </Stack>
              <Stack direction="column">
                <label>Status: </label>
                <Autocomplete
                  sx={{ width: "16vw" }}
                  size="small"
                  options={Status}
                  getOptionLabel={(option) => option.label}
                  value={Status.find(
                    (status) => status.value === values?.status
                  )}
                  onChange={(event, value) => {
                    handleChangeAutoComplete("status", value?.value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="status" />
                  )}
                />
              </Stack>
              <Stack direction="column">
                <label>IsUsed: </label>
                <Autocomplete
                  sx={{ width: "16vw" }}
                  size="small"
                  options={IsUsed}
                  getOptionLabel={(option) => option.label}
                  value={IsUsed.find(
                    (IsUsed) => IsUsed.value === values?.isUsed
                  )}
                  onChange={(event, value) => {
                    handleChangeAutoComplete("isUsed", value?.value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="IsUsed" />
                  )}
                />
              </Stack>
            </Stack>

            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-evenly"
              sx={{ marginTop: "10px" }}
            >
              <Stack direction="column">
                <label>Company Name: </label>
                <TextField
                  disabled
                  sx={{ width: "17vw" }}
                  size="small"
                  placeholder="companyName"
                  value={values.companyName}
                />
              </Stack>
              <Stack direction="column">
                <label>Contact Number: </label>
                <TextField
                  disabled
                  sx={{ width: "17vw" }}
                  size="small"
                  placeholder="Contact Number"
                  value={values.contactNumber}
                  onChange={handleChange("contactNumber")}
                />
              </Stack>
              <Stack direction="column">
                <label>OrderKey: </label>
                <TextField
                  disabled
                  sx={{ width: "16vw" }}
                  size="small"
                  placeholder="orderKey"
                  value={values.orderKey}
                  onChange={handleChange("orderKey")}
                />
              </Stack>
              <Stack direction="column">
                <label>Job: </label>
                <TextField
                  inputProps={{ min: 0 }}
                  sx={{ width: "16vw" }}
                  size="small"
                  placeholder="Job"
                  type="number"
                  value={values.job}
                  onChange={handleChange("job")}
                />
              </Stack>
            </Stack>

            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-evenly"
              sx={{ marginTop: "10px" }}
            >
              <Stack direction="column">
                <label>Reciept: </label>
                <TextField
                  disabled
                  sx={{ width: "35vw" }}
                  size="small"
                  placeholder="Reciept"
                  value={values.receipt}
                  onChange={handleChange("receipt")}
                />
              </Stack>
              <Stack direction="column">
                <label>RazorPaySignature: </label>
                <TextField
                  sx={{ width: "33vw" }}
                  size="small"
                  placeholder="RazorPaySignature"
                  value={values.razorPaySignature}
                  onChange={handleChange("razorPaySignature")}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-evenly"
              sx={{ marginTop: "10px" }}
            >
              <Stack direction="column">
                <label>RazorPayPaymentId : </label>
                <TextField
                  sx={{ width: "35vw" }}
                  size="small"
                  placeholder="RazorPayPaymentId"
                  value={values.razorPayPaymentId}
                  onChange={handleChange("razorPayPaymentId")}
                />
              </Stack>
              <Stack direction="column">
                <label>RazorPayOrderId : </label>
                <TextField
                  sx={{ width: "33vw" }}
                  size="small"
                  placeholder="razorPayOrderId"
                  value={values.razorPayOrderId}
                  onChange={handleChange("razorPayOrderId")}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-evenly"
              sx={{ marginTop: "10px" }}
            >
              <Stack direction="column">
                <label>AdditionalPlanRDays: </label>
                <TextField
                  disabled
                  sx={{ width: "35vw" }}
                  size="small"
                  placeholder="additionalPlanRDays"
                  value={values.additionalPlanRDays}
                  onChange={handleChange("additionalPlanRDays")}
                />
              </Stack>

              <Stack direction="column">
                <label>Amount: </label>
                <TextField
                  disabled
                  sx={{ width: "33vw" }}
                  size="small"
                  placeholder="Amount"
                  value={values.amount}
                  onChange={handleChange("amount")}
                />
              </Stack>
            </Stack>

            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-evenly"
              sx={{ marginTop: "10px" }}
            >
              <Stack direction="column">
                <label>Plan: </label>
                <TextField
                  sx={{ width: "17.5vw" }}
                  size="small"
                  placeholder="Plan"
                  value={values.plan}
                  onChange={handleChange("plan")}
                />
              </Stack>
              <Stack direction="column">
                <label>Type: </label>
                <TextField
                  inputProps={{ min: 0 }}
                  sx={{ width: "16vw" }}
                  size="small"
                  disabled
                  placeholder="type"
                  value={values.type}
                  onChange={handleChange("type")}
                />
              </Stack>
              <Stack direction="column">
                <label>Quantity: </label>
                <TextField
                  disabled
                  inputProps={{ min: 0 }}
                  sx={{ width: "16vw" }}
                  size="small"
                  placeholder="qty"
                  type="number"
                  value={values.qty}
                  onChange={handleChange("qty")}
                />
              </Stack>
              <Stack direction="column">
                <label>AdditionalPlanId: </label>
                <TextField
                  disabled
                  inputProps={{ min: 0 }}
                  sx={{ width: "16vw" }}
                  size="small"
                  placeholder="qty"
                  type="number"
                  value={values.additionalPlanId}
                  onChange={handleChange("additionalPlanId")}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-evenly"
              sx={{ marginTop: "10px" }}
            >
              <Stack direction="column">
                <label>GstTax: </label>
                <TextField
                  disabled
                  sx={{ width: "17.5vw" }}
                  size="small"
                  placeholder="gstTax"
                  value={values.gstTax}
                  onChange={handleChange("gstTax")}
                />
              </Stack>
              <Stack direction="column">
                <label>Terms: </label>
                <TextField
                  disabled
                  sx={{ width: "16vw" }}
                  size="small"
                  placeholder="terms"
                  value={values.terms}
                  onChange={handleChange("terms")}
                />
              </Stack>

              <Stack direction="column">
                <label>SendMail: </label>
                <TextField
                  disabled
                  sx={{ width: "16vw" }}
                  size="small"
                  placeholder="sendMail"
                  value={values.sendMail}
                  onChange={handleChange("sendMail")}
                />
              </Stack>
              <Stack direction="column">
                <label>Expiry Date: </label>
                <DesktopDatePicker
                  sx={{
                    width: "16vw",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                  label="Expiry Date*"
                  format="DD/MM/YYYY"
                  slotProps={{ textField: { size: "small" } }}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              margin={5}
            >
              <Button
                className="search-button"
                variant="contained"
                onClick={handleSubmit}
              >
                update
              </Button>
              <Button
                className="reset-button"
                variant="contained"
                onClick={closeOrderEditModel}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}

const Status = [
  { label: "Active", value: 1 },
  { label: "Inactive", value: 0 },
];

const IsUsed = [
  { label: 1, value: "true" },
  { label: 0, value: "false" },
];

const payment = [
  { label: 1, value: 1 },
  { label: 0, value: 0 },
];
