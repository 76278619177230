import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { industryContext } from "../../contexts/IndustryContext";
import {
  Dialog,
  DialogContent,
  Button,
  Slide,
  DialogTitle,
  CircularProgress,
  Stack,
  Box,
  TextField,
  Autocomplete,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function IndustryEdit() {
  const {
    loading,
    viewIndustries,
    handleUpdateIndustry,
    closeIndustryEditModel,
    showIndustryEdit,
  } = useContext(industryContext);
  const params = useParams();
  useEffect(() => {
    handleUpdateIndustry(viewIndustries?._id);
  }, []);
  useEffect(() => {
    setValues({
      name: viewIndustries?.name ? viewIndustries?.name : "",
      alias: viewIndustries?.alias ? viewIndustries?.alias : "",
      status: viewIndustries?.status ? viewIndustries?.status : 0,
      sort: viewIndustries?.sort ? viewIndustries?.sort : "",
      English: viewIndustries?.multilingual?.English
        ? viewIndustries?.multilingual?.English
        : "",
      Hindi: viewIndustries?.multilingual?.Hindi
        ? viewIndustries?.multilingual?.Hindi
        : "",
      Marathi: viewIndustries?.multilingual?.Marathi
        ? viewIndustries?.multilingual?.Marathi
        : "",
      Tamil: viewIndustries?.multilingual?.Tamil
        ? viewIndustries?.multilingual?.Tamil
        : "",
      Telugu: viewIndustries?.multilingual?.Telugu
        ? viewIndustries?.multilingual?.Telugu
        : "",
      Kannada: viewIndustries?.multilingual?.Kannada
        ? viewIndustries?.multilingual?.Kannada
        : "",
    });
  }, [viewIndustries]);
  const [values, setValues] = useState({});

  let navigate = useNavigate();

  const handleChangeAutoComplete = (prop, value) => {
    setValues({ ...values, [prop]: value });
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = () => {
    handleUpdateIndustry(values, viewIndustries?._id);
    closeIndustryEditModel();
  };

  return (
    <Dialog
      maxWidth={"lg"}
      open={showIndustryEdit}
      onClose={closeIndustryEditModel}
      aria-labelledby="customized-dialog-title"
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          fontWeight: "bold",
          background: "rgb(35, 48, 68)",
          color: "White",
          textAlign: "center",
        }}
        id="customized-dialog-title"
      >
        UPDATE INDUSTRIES
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeIndustryEditModel}
        sx={{
          position: "absolute",
          right: 8,
          top: 5,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers sx={{ height: "100vh" }}>
        {loading ? (
          <CircularProgress
            className="Loading"
            loading={loading}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
            sx={{ marginLeft: "40%" }}
          />
        ) : (
          <Box>
            <Stack
              direction="row"
              spacing={3}
              justifyContent="space-evenly"
              sx={{ marginTop: "10px" }}
            >
              <Stack direction="column">
                <label>Name: </label>
                <TextField
                  value={values?.name}
                  onChange={handleChange("name")}
                  sx={{ width: "35vw" }}
                  size="small"
                  placeholder="Name"
                />
              </Stack>

              <Stack direction="column">
                <label>Alias:</label>
                <TextField
                  sx={{ width: "35vw" }}
                  size="small"
                  placeholder="Alias"
                  value={values.alias}
                  onChange={handleChange("alias")}
                />
              </Stack>
            </Stack>

            <Stack
              direction="row"
              spacing={3}
              justifyContent="space-evenly"
              sx={{ marginTop: "10px" }}
            >
              <Stack direction="column">
                <label>Status: </label>

                <Autocomplete
                  sx={{ width: "35vw" }}
                  size="small"
                  options={status}
                  getOptionLabel={(option) => option.label ?? ""}
                  value={
                    status.find((status) => status.value === values?.status) ??
                    ""
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("status", value?.value);
                  }}
                  // getOptionSelected={(option, value) => {
                  //   if (value === "") {
                  //     return true;
                  //   } else if (value === option) {
                  //     return true;
                  //   }
                  // }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="status" />
                  )}
                />
              </Stack>

              <Stack direction="column">
                <label>Sort: </label>
                <TextField
                  inputProps={{ min: 0 }}
                  type="number"
                  sx={{ width: "35vw" }}
                  size="small"
                  placeholder="Sort"
                  value={values.sort}
                  onChange={handleChange("sort")}
                />
              </Stack>
            </Stack>

            <div>
              <Typography
                sx={{
                  fontSize: "25px",
                  paddingLeft: "33px",
                  marginTop: "20px",
                }}
              >
                Languages
              </Typography>
            </div>

            <Stack
              direction="row"
              spacing={3}
              justifyContent="space-evenly"
              sx={{ marginTop: "10px" }}
            >
              <Stack direction="column">
                <label>English: </label>
                <TextField
                  sx={{ width: "35vw" }}
                  size="small"
                  placeholder="English"
                  value={values.English}
                  onChange={handleChange("English")}
                />
              </Stack>
              <Stack direction="column">
                <label>Hindi: </label>
                <TextField
                  sx={{ width: "35vw" }}
                  size="small"
                  placeholder="हिंदी"
                  value={values.Hindi}
                  onChange={handleChange("Hindi")}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              spacing={3}
              justifyContent="space-evenly"
              sx={{ marginTop: "10px" }}
            >
              <Stack direction="column">
                <label>Marathi: </label>
                <TextField
                  sx={{ width: "35vw" }}
                  size="small"
                  placeholder="मराठी"
                  value={values.Marathi}
                  onChange={handleChange("Marathi")}
                />
              </Stack>
              <Stack direction="column">
                <label>Telugu: </label>
                <TextField
                  sx={{ width: "35vw" }}
                  size="small"
                  placeholder="తెలుగు"
                  value={values.Telugu}
                  onChange={handleChange("Telugu")}
                />
              </Stack>
            </Stack>

            <Stack
              direction="row"
              spacing={3}
              justifyContent="space-evenly"
              sx={{ marginTop: "10px" }}
            >
              <Stack direction="column">
                <label>Tamil: </label>
                <TextField
                  sx={{ width: "35vw" }}
                  size="small"
                  placeholder="தமிழ்"
                  value={values.Tamil}
                  onChange={handleChange("Tamil")}
                />
              </Stack>
              <Stack direction="column">
                <label>Kannada: </label>
                <TextField
                  sx={{ width: "35vw" }}
                  size="small"
                  placeholder="ಕನ್ನಡ"
                  value={values.Kannada}
                  onChange={handleChange("Kannada")}
                />
              </Stack>
            </Stack>

            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={3}
              margin={5}
            >
              <Button
                 className="search-button"
                 variant="contained"
                onClick={handleSubmit}
              >
                update
              </Button>
              <Button
               className="reset-button"
               variant="contained"
                onClick={closeIndustryEditModel}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}

const status = [
  { label: "Active", value: 1 },
  { label: "Inactive", value: 0 },
];
