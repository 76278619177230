import React, { useEffect, useContext, useState } from "react";
import { planContext } from "../../contexts/PlanContext";
import { Paper,CircularProgress, Pagination, Typography, Box } from "@mui/material";

import PlansTable from "./Table";
import PlanEdit from "./PlanEdit";

export default function SmsLogs() {
  const [page, setPage] = useState(1);
  const { handleSearchPlans, plansCount, loading } = useContext( planContext );
  useEffect(() => {
    handleSearchPlans({ page });
  }, [page]);

  return (
    <div>
      <Paper
        sx={{
          textAlign: "center",
          padding: "5px",
        }}
      >
        <Box
          sx={{ justifyContent: "center", borderRadius: "5px" }}
          className="heading"
        >
          <Typography variant="h5">
            Plans(
            {plansCount ? parseInt(plansCount).toLocaleString() : "NA"})
          </Typography>
        </Box>

        {loading ? (
          <CircularProgress
            className="Loading"
            loading={"true"}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <PlansTable pageNumber={page} />
        )}
        <Pagination
          sx={{
            marginLeft: "10px",
            marginRight: "10px",
            marginTop: "10px",
            paddingBottom: "10px",
            "Button.MuiPaginationItem-rounded.Mui-selected": {
              bgcolor: "rgb(35, 48, 68)",
              color: "rgb(238, 238, 238)",
            },
          }}
          count={parseInt(plansCount / 10) + (plansCount % 10 > 0 ? 1 : 0)}
          onChange={(event, value) => setPage(value)}
          shape="rounded"
          variant="outlined"
        />
      </Paper>
    <PlanEdit/>
    </div>
  );
}
