import * as React from 'react';

function PublicRoute({ component: Component, ...rest }) {
    return (
        <div>
            <Component />
        </div>
    )
}
export default PublicRoute;




