import React, { useContext } from "react";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  tableCellClasses,
  Box,
  Tooltip,
  Chip,
  MenuItem,
  Menu,
  IconButton,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { industryContext } from "../../contexts/IndustryContext";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#233044",
    color: "#eeeeee",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default function IndustriesTable({ pageNumber }) {
  const { industryData, industriesView,industriesEdit } = useContext(industryContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRecordId, setSelectedRecordId] = React.useState(null);
  const open = Boolean(anchorEl);
  let navigate = useNavigate();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <div className="TablePadding">
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center" style={{ fontSize: "17px", width: "4vw" }}>
                #
              </StyledTableCell>
              <StyledTableCell align="left" style={{ fontSize: "17px", width: "15vw" }}>
                Industry
              </StyledTableCell>
              <StyledTableCell align="center" style={{ fontSize: "17px", width: "12vw" }}>
                Posted on
              </StyledTableCell>
              <StyledTableCell align="center" style={{ fontSize: "17px", width: "12vw" }}>
                Status
              </StyledTableCell>
              <StyledTableCell align="left" style={{ fontSize: "17px", width: "2vw" }}>
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {industryData?.map((row, index) => (
              <StyledTableRow key={index}  
              className="tableRow cell hove">
                <StyledTableCell align="center">
                  {index + 1 + (pageNumber - 1) * 10}
                </StyledTableCell>
                <StyledTableCell align="left"> {row?.name}</StyledTableCell>
                <StyledTableCell align="center">
                 
                  {row?.updatedAt
                    ? moment(row?.updatedAt).format("D/MM/YYYY")
                    : ""}{" "}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Chip
                    size="small"
                    sx={{
                      backgroundColor:
                      row?.status === 1
                          ? "#97c53c"
                        
                          : "#e48813",
                      color:
                      row?.status === 1
                          ? "white"
                          : "#fff",
                    }}
                    label={row?.status === 1 ? "Active" : "InActive"}
                  />{" "}
                </StyledTableCell>
                <StyledTableCell align="center" style={{ minWidth: "5vw" }}>
                  <div>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Tooltip title="Action">
                        <IconButton
                          onClick={(event) => {
                            handleMenu(event)
                            setSelectedRecordId(row?._id)
                          }}
                          size="small"
                          sx={{ ml: 2 }}
                          aria-controls={open ? "account-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                    
                      keepMounted
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 1px 1px rgb(211, 211, 211))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            left: 19,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: "left", vertical: "top" }}
                      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                    >
                      <MenuItem
                        sx={{ color: "rgb(35, 48, 68)" }}
                        onClick={() => {
                          handleClose();
                          industriesEdit(selectedRecordId);
                        }}
                      >
                        <EditIcon sx={{ color: "#228B22", marginRight: "10px" }} />
                        Edit
                      </MenuItem>
                   

                      <MenuItem
                        sx={{ color: "rgb(35, 48, 68)" }}
                        onClick={() => {
                          handleClose();
                          industriesView(selectedRecordId);
                        }}
                      >
                        <SendIcon
                          sx={{ color: "#5072A7", marginRight: "10px" }}
                        />
                        View
                      </MenuItem>
                    </Menu>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}