import React, { createContext, useState } from "react";
import axios from "axios";
import { backendUrl } from "../contants/contants";
import { toast } from "react-toastify";
export const industryContext = createContext({});
const IndustryProvider = ({ children }) => {
  const [viewIndustries, setViewIndustries] = useState([]);
  // const [showSearchEmployer, setShowSearchEmployer] = useState(false);
  const [showUpdateIndustry, setShowUpdateIndustry] = useState(false);
  const [showIndustryView, setShowIndustryView] = useState(false);
  const [industryCount, setIndustryCount] = useState(0);
  const [industryData, setIndustryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showIndustryEdit, setShowIndustryEdit] = useState(false);
  const closeIndustryEditModel = () => {
    setShowIndustryEdit(false);
  };
  const showIndustryEditModel = () => {
    setShowIndustryEdit(true);
  };
  const showIndustryViewModel = () => {
    setShowIndustryView(true);
  };
  const closeIndustryViewModel = () => {
    setShowIndustryView(false);
  };
  const handleSearchIndustry = (data) => {
    setLoading(true);
    axios
      .post(backendUrl + "/api/crm/industry/list", data)
      .then((response) => {
        // setShowSearchEmployer(false);
        setIndustryData(response.data.data.industry);
        setIndustryCount(response.data.data.count);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const industriesEdit = (id) => {
    axios
      .get(backendUrl + "/api/crm/industry?id=" + id)
      .then((response) => {
        setViewIndustries(response.data.data.industry);
        setShowIndustryEdit(true)
      })
      .catch((e) => {});
  };
  const industriesView = (id) => {
    axios
      .get(backendUrl + "/api/crm/industry?id=" + id)
      .then((response) => {
        setShowIndustryView(true);
        setViewIndustries(response.data.data.industry);
      })
      .catch((e) => {});
  };
  const handleUpdateIndustry = (data, _id) => {
    axios
      .put(backendUrl + "/api/crm/industry/update?id=" + _id, data)
      .then((response) => {
        setShowUpdateIndustry(false);
        toast.success("Successfully Updated!");
      })
      .catch((e) => {});
  };

  return (
    <industryContext.Provider
      value={{
        showUpdateIndustry,
        loading,
        industriesEdit,
        showIndustryView,
        industryCount,
        industryData,
        handleSearchIndustry,
        industriesView,
        handleUpdateIndustry,
        showIndustryViewModel,
        closeIndustryViewModel,
        viewIndustries,
        showIndustryEdit,
        closeIndustryEditModel,
        showIndustryEditModel
      }}
    >
      {children}
    </industryContext.Provider>
  );
};
export default IndustryProvider
