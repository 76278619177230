import React, { createContext, useState } from "react";
import axios from "axios";
import { backendUrl } from "../contants/contants";
export const smsLogsContext = createContext({});
const SmsLogsProvider = ({ children }) => {
  const [smsData, setSmsData] = useState([]);
  const [smsCount, setSmsCount] = useState(0);
  const [viewSms, setViewSms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showEditSms, setShowEditSms] = useState(false);

  const [showSmsView, setShowSmsView] = useState(false);
  const [showSearchSms, setShowSearchSms] = useState(false);

  const showEditSmsModel = () => {
    setShowEditSms(true);
  };
  const closeEditSmsModel = () => {
    setShowEditSms(false);
  };
  const showSmsViewModel = () => {
    setShowSmsView(true);
  };
  const closeSmsViewModel = () => {
    setShowSmsView(false);
  };
  const handleSearchSms = (data) => {
    setLoading(true);
    axios
      .post(backendUrl + "/api/crm/sms/list", data)
      .then((response) => {
        setShowSearchSms(false);
        setShowSmsView(false);
        setSmsData(response.data.data.smslogs);
        setSmsCount(response.data.data.count);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const smsLogsView = (id) => {
    axios
      .get(backendUrl + "/api/crm/sms?id=" + id)
      .then((response) => {
        setViewSms(response.data.data.smsLogs);
        setShowSmsView(true);
      })
      .catch((e) => {});
  };
  return (
    <smsLogsContext.Provider
      value={{
        loading,
        handleSearchSms,
        smsData,
        smsCount,
        viewSms,
        smsLogsView,
        showSmsView,
        showSearchSms,
        showSmsViewModel,
        closeSmsViewModel,
        closeEditSmsModel,
        showEditSmsModel,
        showEditSms
      }}
    >
      {children}
    </smsLogsContext.Provider>
  );
};
export default SmsLogsProvider;
