import React, { createContext, useState } from "react";
import axios from "axios";
import { backendUrl } from "../contants/contants";
import { toast } from "react-toastify";
export const skillContext = createContext({});
const SkillProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [skillsData, setSkillsData] = useState([]);
  const [skillsCount, setSkillsCount] = useState(0);
  const [viewSkill, setViewSkill] = useState([]);
  const [textFieldValue, setTextFieldValue] = useState("");
  const [showSearchEmployer, setShowSearchEmployer] = useState(false);
  const [showEditSkill, setShowEditSkill] = useState(false);
  const [showSkillEdit, setShowSkillEdit] = useState(false);
  const [showSkillView, setShowSkillView] = useState(false);
  const closeSkillEditModel = () => {
    setShowSkillEdit(false);
  };
  const showSkillEditModel = () => {
    setShowSkillEdit(true);
  };
  const closeSkillViewModel = () => {
    setShowSkillView(false);
  };
  const showSkillViewModel = () => {
    setShowSkillView(true);
  };
  const handleSearchSkills = (data) => {
    setLoading(true);
    axios
      .post(backendUrl + "/api/crm/skill/list", data)
      .then((response) => {
        setShowSearchEmployer(false);
        setSkillsData(response.data.data.skill);
        setSkillsCount(response.data.data.count);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const skillEdit = (id) => {
    axios
      .get(backendUrl + "/api/crm/skill?id=" + id)
      .then((response) => {
        setViewSkill(response.data.data.skill);
        setTextFieldValue(response.data.data.skill.isParent);
        setShowSkillEdit(true);
      })
      .catch((e) => {});
  };
 

  const handleEditSkill = (data, _id) => {
    axios
      .put(backendUrl + "/api/crm/skill/update?id=" + _id, data)
      .then((response) => {
        setShowEditSkill(false);
        toast.success("Successfully Updated!");
      })
      .catch((e) => {});
  };
  const skillView = (id) => {
    axios
      .get(backendUrl + "/api/crm/skill?id=" + id)
      .then((response) => {
        setViewSkill(response.data.data.skill);
        setShowSkillView(true);
      })
      .catch((e) => {});
  };
  return (
    <skillContext.Provider
      value={{
        loading,
        skillsData,
        textFieldValue,
        handleSearchSkills,
        skillsCount,
        skillView,
        viewSkill,
        skillEdit,
        showEditSkill,
        handleEditSkill,
        closeSkillViewModel,
        showSkillViewModel,
        showSkillView,
        showSkillEdit,
        showSkillEditModel,
        closeSkillEditModel,
        showSearchEmployer
      }}
    >
      {children}
    </skillContext.Provider>
  );
};
export default SkillProvider;
