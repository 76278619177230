import React, { createContext, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { backendUrl } from "../contants/contants";

export const jobPostedContext = createContext({});

const JobPostedProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [showNormalJobView, setShowNormalJobView] = useState(false);
  const [jobPostedData, setJobPostedData] = useState([]);
  const [jobPostedCount, setJobPostedCount] = useState(0);
  const [viewJob, setViewJob] = useState([]);
  const [jobResponses, setJobResponses] = useState([]);
  const [showJobResponse, setShowJobResponse] = useState(false);
  const [showNormalJobEdit, setShowNormalJobEdit] = useState(false);
  const [showContractJobEdit, setShowContractJobEdit] = useState(false);
  const [showContractView, setShowContractView] = useState(false);

  const showNormalJobViewModel = () => {
    setShowNormalJobView(true);
  };
  const closeNormalJobViewModel = () => {
    setShowNormalJobView(false);
  };
  const showNormalJobEditModel = () => {
    setShowNormalJobEdit(true);
  };
  const closeNormalJobEditModel = () => {
    setShowNormalJobEdit(false);
  };
  const closeContractView = () => {
    setShowContractView(false);
  };
  const showContractJobEditModel = () => {
    setShowContractJobEdit(true);
  };
  const closeContractJobEditModel = () => {
    setShowContractJobEdit(false);
  };
  const closeJobResponseModel = () => {
    setShowJobResponse(false);
  }
  const jobPostedEdit = (id) => {
    axios
      .get(backendUrl + "/api/crm/job/job-listings?id=" + id)
      .then((response) => {
        setViewJob(response.data.data.jobsListing);

        if (response.data.data.jobsListing.jobCategory === "Contract") {
          setShowContractJobEdit(true);
        } else {
          setShowNormalJobEdit(true);
        }
      })
      .catch((e) => {});
  };

  const handleEditJob = (data, _id, handleSearch) => {
    axios
      .put(backendUrl + "/api/crm/job/update?id=" + _id, data)
      .then((response) => {
        setShowNormalJobEdit(false);
        toast.success("Successfully Updated!");
        handleSearch();
      })
      .catch((e) => {});
  };

  const handleSearchJobPosted = (data) => {
    setLoading(true);
    axios
      .post(backendUrl + "/api/crm/job/list", data)
      .then((response) => {
        setJobPostedData(response.data.data.jobs);
        setJobPostedCount(response.data.data.count);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const handlejobPostedStatus = (data, id) => {
    axios
      .put(backendUrl + "/api/crm/job/update?id=" + id, data)
      .then((response) => {
        // setJobPostedData(
        //   jobPostedData.map((jobPosted) => {
        //     if (jobPosted._id === id) {
        //       jobPosted.status = data.status;
        //     }
        //     return jobPosted;
        //   })
        // );
        toast.success(
          data.status === 0 ? "Successfully Inactive" : "Successfully Active"
        );
      })
      .catch((e) => {});
  };

  const jobPostedView = (id) => {
    axios
      .get(backendUrl + "/api/crm/job/job-listings?id=" + id)
      .then((response) => {
        setViewJob(response.data.data.jobsListing);
        if (response.data.data.jobsListing.jobCategory === "Contract") {
          setShowContractView(true);
        } else {
          setShowNormalJobView(true);
        }
      })
      .catch((e) => {});
  };

  const handleJobResponses = (id) => {
    axios
      .get(backendUrl + "/api/crm/job/job-responses?id=" + id)
      .then((response) => {
        setJobResponses(response.data.data.jobResponse);
        setShowJobResponse(true)
      })
      .catch((e) => {});
  };

  return (
    <jobPostedContext.Provider
      value={{
        loading,
        jobPostedView,
        handlejobPostedStatus,
        handleSearchJobPosted,
        handleEditJob,
        jobPostedEdit,
        closeContractJobEditModel,
        showContractJobEditModel,
        showNormalJobViewModel,
        closeNormalJobViewModel,
        jobPostedCount,
        showNormalJobEdit,
        closeNormalJobEditModel,
        showContractJobEdit,
        showContractView,
        viewJob,
        jobPostedData,
        closeContractView,
        showNormalJobView,
        showNormalJobEditModel,
        handleJobResponses,
        closeJobResponseModel,
        showJobResponse,
        jobResponses
      }}
    >
      {children}
    </jobPostedContext.Provider>
  );
};
export default JobPostedProvider;
