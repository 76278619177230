import React, { useContext } from "react";

import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PauseIcon from "@mui/icons-material/Pause";
import { jobPostedContext } from "../../contexts/JobPostedContext";
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  tableCellClasses,
  Box,
  Tooltip,
  Chip,
  MenuItem,
  Menu,
  IconButton,
} from "@mui/material";
import moment from "moment";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#233044",
    color: "#eeeeee",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function JobPostedTable({ pageNumber, handleSearch }) {
  const { jobPostedData, jobPostedView, handlejobPostedStatus, jobPostedEdit, handleJobResponses } =
    useContext(jobPostedContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRecordId, setSelectedRecordId] = React.useState(null);
  const [selectedRecord, setSelectedRecord] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className="TablePadding">
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell
                align="center"
                style={{ fontSize: "14px", maxWidth: "2vw" }}
              >
                #
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{ fontSize: "14px", maxWidth: "10vw" }}
              >
                Job Title
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{ fontSize: "14px", maxWidth: "11vw" }}
              >
                Company Name
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ fontSize: "14px", minWidth: "9vw" }}
              >
                Number
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{ fontSize: "14px", maxWidth: "8vw" }}
              >
                Location
              </StyledTableCell>

              <StyledTableCell
                align="center"
                style={{ fontSize: "14px", maxWidth: "6vw" }}
              >
                Posted
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ fontSize: "14px", maxWidth: "11vw" }}
              >
                Job Category
              </StyledTableCell>

              <StyledTableCell
                align="center"
                style={{ fontSize: "14px", maxWidth: "5vw" }}
              >
                Status
              </StyledTableCell>

              <StyledTableCell
                align="center"
                style={{ fontSize: "14px", maxWidth: "8vw" }}
              >
                Response
              </StyledTableCell>

              <StyledTableCell
                align="left"
                style={{ fontSize: "14px", maxWidth: "3vw" }}
              >
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobPostedData?.map((row, index) => (
              <StyledTableRow
                key={index}
                className="tableRow cell tableDataRow hove"
              >
                <StyledTableCell
                  align="center"
                  style={{ fontSize: "14px", maxWidth: "3vw" }}
                >
                  {index + 1 + (pageNumber - 1) * 10}
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  style={{ fontSize: "14px", maxWidth: "10vw" }}
                >
                  <div>{row?.name}</div>
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  style={{ fontSize: "14px", maxWidth: "10vw" }}
                >
                  <div> {row?.companyName}</div>
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ fontSize: "14px", minWidth: "10vw" }}
                >
                  <div>{row?.contactNumber}</div>
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  style={{ fontSize: "14px", maxWidth: "8vw" }}
                >
                  <div>{row?.location?.city} </div>
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                  style={{ fontSize: "14px", maxWidth: "14vw" }}
                >
                  {row?.createdAt
                    ? moment(row?.createdAt).format("D/MM/YY,h:mm:ss a")
                    : ""}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ fontSize: "14px", maxWidth: "4vw" }}
                >
                  <div>{row?.jobCategory} </div>
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                  style={{ fontSize: "14px", maxWidth: "4vw" }}
                >
                  <Chip
                    size="small"
                    sx={{
                      backgroundColor:
                        row?.status === 1 ? "#97c53c" : "#e48813",
                      color: row?.status === 1 ? "white" : "#fff",
                    }}
                    label={row?.status === 1 ? "Active" : "InActive"}
                  />
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                  style={{
                    fontSize: "14px",
                    maxWidth: "8vw",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    handleJobResponses(row._id)
                    console.log("Job ID", row._id);
                  }}
                >
                  {row?.numApplications}
                </StyledTableCell>

                <StyledTableCell
                  align="left"
                  style={{ fontSize: "14px", minWidth: "4vw" }}
                >
                  <div>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Tooltip title="Action">
                        <IconButton
                          onClick={(event) => {
                            handleMenu(event);
                            setSelectedRecordId(row?._id);
                            setSelectedRecord(row);
                          }}
                          size="small"
                          sx={{ ml: 2 }}
                          aria-controls={open ? "account-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      keepMounted
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 1px 1px rgb(211, 211, 211))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            left: 32,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: "left", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      {selectedRecord?.jobCategory === "Normal" ? (
                        <MenuItem
                          sx={{ color: "rgb(35, 48, 68)" }}
                          onClick={() => {
                            // navigate("/jobPosted/" + selectedRecordId);
                            jobPostedEdit(selectedRecordId);
                            handleClose();
                          }}
                        >
                          <EditIcon
                            sx={{ color: "#228B22", marginRight: "10px" }}
                          />
                          Edit
                        </MenuItem>
                      ) : (
                        <MenuItem
                          sx={{ color: "rgb(35, 48, 68)" }}
                          onClick={() => {
                            jobPostedEdit(selectedRecordId);
                            handleClose();
                          }}
                        >
                          <EditIcon
                            sx={{ color: "#228B22", marginRight: "10px" }}
                          />
                          Edit
                        </MenuItem>
                      )}
                      {selectedRecord?.jobCategory === "Normal" ? (
                        <MenuItem
                          sx={{ color: "rgb(35, 48, 68)" }}
                          onClick={() => {
                            handleClose();
                            jobPostedView(selectedRecordId);
                          }}
                        >
                          <SendIcon
                            sx={{ color: "#5072A7", marginRight: "10px" }}
                          />
                          View
                        </MenuItem>
                      ) : (
                        <MenuItem
                          sx={{ color: "rgb(35, 48, 68)" }}
                          onClick={() => {
                            handleClose();
                            jobPostedView(selectedRecordId);
                          }}
                        >
                          <SendIcon
                            sx={{ color: "#5072A7", marginRight: "10px" }}
                          />
                          View
                        </MenuItem>
                      )}
                      {selectedRecord?.status === 0 ? (
                        <MenuItem
                          sx={{ color: "rgb(35, 48, 68)" }}
                          onClick={() => {
                            handlejobPostedStatus(
                              { status: 1 },
                              selectedRecord._id
                            );
                            handleSearch();
                            handleClose();
                          }}
                        >
                          <PauseIcon
                            sx={{ color: "green", marginRight: "10px" }}
                          />
                          <Chip
                            size="small"
                            sx={{
                              backgroundColor: "#97c53c",
                              color: "white",
                            }}
                            label="Activate"
                          />
                        </MenuItem>
                      ) : (
                        <MenuItem
                          sx={{ color: "rgb(35, 48, 68)" }}
                          onClick={() => {
                            handlejobPostedStatus(
                              { status: 0 },
                              selectedRecord._id
                            );
                            handleSearch();
                            handleClose();
                          }}
                        >
                          <PauseIcon
                            sx={{ color: "#e48813", marginRight: "10px" }}
                          />
                          <Chip
                            size="small"
                            sx={{
                              backgroundColor: "#e48813",
                              color: "white",
                            }}
                            label="Inactivate"
                          />
                        </MenuItem>
                      )}
                    </Menu>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
