import React, { useContext } from "react";
import { skillContext } from "../../contexts/SkillContext";
import CloseIcon from "@mui/icons-material/Close";
import {Dialog, IconButton,DialogTitle,DialogContent,Grid, Stack, Box, Chip, Slide } from "@mui/material";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
export default function SkillView() {
  const { viewSkill, closeSkillViewModel, showSkillView } =
    useContext(skillContext);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={showSkillView}
      onClose={closeSkillViewModel}
      aria-labelledby="customized-dialog-title"
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          fontWeight: "bold",
          background: "rgb(35, 48, 68)",
          color: "white",
          textAlign: "center",
        }}
        id="customized-dialog-title"
      >
        SKILL DETAILS
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeSkillViewModel}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent  dividers>
        <Box sx={{ flexGrow: 1 }}>
          <Stack spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Skill Name:</p>
                  <p>{viewSkill?.name} </p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Parent:</p>
                  <p>{viewSkill?.parent?.name} </p>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">View:</p>
                  <p>{viewSkill?.view ? viewSkill?.view : "null"} </p>
                </Stack>
              </Grid>

              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Status: </p>
              
                    <Chip
                      size="small"
                      sx={{
                        backgroundColor:
                          viewSkill?.status === 1 ? "#97c53c" : "#e48813",
                        color: viewSkill?.status === 1 ? "white" : "#fff",
                      }}
                      label={viewSkill?.status === 1 ? "Active" : "InActive"}
                    />
                
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Created on: </p>
                  <p>
                    {viewSkill?.createdAt
                      ? moment(viewSkill?.createdAt).format("D/MM/YYYY")
                      : ""}
                  </p>
                </Stack>
              </Grid>

              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Updated on: </p>
                  <p>
                    {viewSkill?.updatedAt
                      ? moment(viewSkill?.updatedAt).format("D/MM/YYYY")
                      : ""}
                  </p>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={10}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Functional Area:</p>
                  <p>
                    {/* {viewSkill?.functionalArea?.name} */}
                    {viewSkill?.functionalArea?.name
                      ? viewSkill?.functionalArea?.name
                      : "N/A"}
                  </p>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={10}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Alias:</p>
                  <p>{viewSkill?.alias} </p>
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={10}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Profile Image:</p>
                  <p>
                    {/* {viewSkill?.profileImage ?? 'null'} */}
                    {viewSkill?.profileImage
                      ? viewSkill?.profileImage
                      : "N/A"}{" "}
                  </p>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
