import React, { useContext } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {IconButton, DialogContent,Grid, Dialog,Stack,DialogTitle, Box,  Slide,  } from "@mui/material";
import { feedbackContext } from "../../contexts/FeedbackContext";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function FeedbackView() {
  const { viewFeedback, closeFeedbackViewModel, showFeedbackView, 
     } = useContext(feedbackContext);
  return (
    <Dialog
      fullWidth
      open={showFeedbackView}
      onClose={closeFeedbackViewModel}
      aria-labelledby="customized-dialog-title"
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          fontWeight: "bold",
          background: "rgb(35, 48, 68)",
          color: "White",
          textAlign: "center",
        }}
        id="customized-dialog-title"
      >
        FEEDBACK DETAILS
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeFeedbackViewModel}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{ width: 600, height: 150 }}
        dividers
      >
        <Box sx={{ flexGrow: 1 }}>
          <Stack spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Reason:</p>
                  <p>{viewFeedback?.reasons }   </p>
                </Stack>
              </Grid>
              

              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Comment:</p>
                  <p>{viewFeedback?.comment }   </p>
                </Stack>
              </Grid>
            </Grid>
 

          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
