import React, { useContext } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Box,
  Chip,
  Slide,
} from "@mui/material";
import moment from "moment";
import { employeeOrderContext } from "../../contexts/EmployeeOrderContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function EmployeeOrderView() {
  const {
    closeEmployeeOrderViewModel,
    showEmployeeOrderView,
    employeeOrderviewMore,
  } = useContext(employeeOrderContext);
  return (
    <Dialog
      fullWidth
      open={showEmployeeOrderView}
      onClose={closeEmployeeOrderViewModel}
      aria-labelledby="customized-dialog-title"
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          fontWeight: "bold",
          background: "rgb(35, 48, 68)",
          color: "White",
          textAlign: "center",
        }}
        id="customized-dialog-title"
      >
        EMPLOYEE ORDER DETAILS
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeEmployeeOrderViewModel}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ width: 600, height: 300 }} dividers>
        <Box sx={{ flexGrow: 1 }}>
          <Stack spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Plan:</p>
                  <p> {employeeOrderviewMore?.employeePlanId?.planName} </p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Mobile:</p>
                  <p> {employeeOrderviewMore?.userId?.mobile} </p>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Status:</p>
             
                    <Chip
                      size="small"
                      sx={{
                        backgroundColor:
                          employeeOrderviewMore?.status === true
                            ? "#97c53c"
                            : "#e48813",
                        color:
                          employeeOrderviewMore?.status === true
                            ? "white"
                            : "#fff",
                      }}
                      label={
                        employeeOrderviewMore?.status === true
                          ? "Active"
                          : "InActive"
                      }
                    />
                  
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Paid:</p>
                  <p>{employeeOrderviewMore?.paid === true ? "Yes" : "No"}</p>
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                <p className="Bold">TotalCall: </p>
                <p> {employeeOrderviewMore?.userId?.perksPlan?.totalCall} </p>
               
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">RemainingCall: </p>
                  <p> {employeeOrderviewMore?.userId?.perksPlan?.remainingCall} </p>
                </Stack>
              </Grid>
             
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">createdAt:</p>
                  <p>
                    {employeeOrderviewMore?.createdAt
                      ? moment(employeeOrderviewMore?.createdAt).format(
                          "D/MM/YYYY"
                        )
                      : ""}
                  </p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> updatedAt: </p>
                  <p>
                    {employeeOrderviewMore?.updatedAt
                      ? moment(employeeOrderviewMore?.updatedAt).format(
                          "D/MM/YYYY"
                        )
                      : "N/A"}
                  </p>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
