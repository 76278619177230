import React, { useContext } from "react";
import {
  Chip,
  MenuItem,
  Menu,
  IconButton,
  Tooltip,
  TableCell,
  tableCellClasses,
  TableContainer,
  Paper,
  TableRow,
  Table,
  Box,
  TableBody,
  TableHead,
} from "@mui/material";
import { smsLogsContext } from "../../contexts/SmsLogsContext";
import moment from "moment";
import SendIcon from "@mui/icons-material/Send";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#233044",
    color: "#eeeeee",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function SmsTable({ pageNumber }) {
  const { smsData, smsLogsView } = useContext(smsLogsContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRecordId, setSelectedRecordId] = React.useState(null);
  const open = Boolean(anchorEl);
  let navigate = useNavigate();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className="TablePadding">
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell
                align="center"
                style={{ fontSize: "14px", maxWidth: "4vw" }}
              >
                #
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{ fontSize: "14px", maxWidth: "10vw" }}
              >
                Job Title
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{ fontSize: "14px", maxWidth: "10vw" }}
              >
                Company Name
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ fontSize: "14px", minWidth: "10vw" }}
              >
                Contact Number
              </StyledTableCell>

              <StyledTableCell
                align="left"
                style={{ fontSize: "14px", maxWidth: "7vw" }}
              >
                Location
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ fontSize: "14px", maxWidth: "10vw" }}
              >
                Profile
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ fontSize: "14px", minWidth: "7vw" }}
              >
                Employee Location
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ fontSize: "14px", maxWidth: "8vw" }}
              >
                Registered
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ fontSize: "14px", maxWidth: "6vw" }}
              >
                Status
              </StyledTableCell>

              <StyledTableCell
                align="left"
                style={{ fontSize: "14px", maxWidth: "3vw" }}
              >
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {smsData?.map((row, index) => (
              <StyledTableRow
                key={index}
                className="tableRow cell tableDataRow hove"
              >
                <StyledTableCell
                  align="center"
                  style={{ fontSize: "14px", maxWidth: "4vw" }}
                >
                  {index + 1 + (pageNumber - 1) * 10}
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  style={{ fontSize: "14px", maxWidth: "10vw" }}
                >
                  <div>{row?.jobId?.name}</div>
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  style={{ fontSize: "14px", maxWidth: "10vw" }}
                >
                  <div>{row?.jobId?.companyName}</div>
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ fontSize: "14px", maxWidth: "10vw" }}
                >
                  <div>{row?.jobId?.contactNumber}</div>
                </StyledTableCell>

                <StyledTableCell
                  align="left"
                  style={{ fontSize: "14px", maxWidth: "7vw" }}
                >
                  <div> {row?.jobId?.location?.city}</div>
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  style={{ fontSize: "14px", maxWidth: "4vw" }}
                >
                  <div> {row?.userId?.profile?.name}</div>
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ fontSize: "14px", minWidth: "7vw" }}
                >
                  <div>
                    {row?.userId?.workLocation
                      ? row?.userId?.workLocation?.city
                      : row?.userId?.workLocality
                      ? row?.userId?.workLocality?.city
                      : row?.userId?.location?.city}
                  </div>
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                  style={{ fontSize: "14px", maxWidth: "10vw" }}
                >
                  <div>
                    {row?.updatedAt
                      ? moment(row?.updatedAt).format("D/MM/YYYY,h:mm:ss a")
                      : ""}
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ fontSize: "14px", maxWidth: "6vw" }}
                >
                  <Chip
                    size="small"
                    sx={{
                      backgroundColor: row?.status ? "#97c53c" : "#e48813",
                      color: row?.status ? "white" : "#fff",
                    }}
                    label={row?.status ? "Applied" : "Pending"}
                  />
                </StyledTableCell>

                <StyledTableCell align="center" sx={{ width: "4vw" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Tooltip title="Action">
                      <IconButton
                        onClick={(event) => {
                          handleMenu(event);
                          setSelectedRecordId(row?._id);
                        }}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 1px 1px rgb(211, 211, 211))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          left: 40,
                          width: 10,
                          height: 10,

                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "left", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem
                      sx={{ color: "rgb(35, 48, 68)" }}
                      onClick={() => {
                        handleClose();
                        smsLogsView(selectedRecordId);
                      }}
                    >
                      <SendIcon
                        sx={{ color: "#5072A7", marginRight: "10px" }}
                      />
                      View
                    </MenuItem>
                  </Menu>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
