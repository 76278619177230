import React, { useEffect, useContext, useState } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  Stack,
  Paper,
  CircularProgress,
  Button,
  Typography,
  Box,
  TextField,
  Pagination,
} from "@mui/material";
import JobPostedTable from "./Table";
import LoadingButton from "@mui/lab/LoadingButton";
import Autocomplete from "@mui/material/Autocomplete";
import NormalJobView from "./NormalJobView";
import ContractView from "./ContractView";
import NormalJobEdit from "./NormalJobEdit";
import ContractEdit from "./ContractEdit";
import JobResponses from "./JobResponses";

import { jobPostedContext } from "../../contexts/JobPostedContext";
export default function JobPosted() {
  const { handleSearchJobPosted, jobPostedCount, loading } =
    useContext(jobPostedContext);

  const [values, setValues] = useState({
    fromDate: null,
    toDate: null,
    companyName: "",
    contactNumber: "",
    contactEmail: "",
    filter: "",
    page: 1,
  });

  useEffect(() => {
    handleSearchJobPosted({ ...values });
  }, []);

  const handleReset = () => {
    setValues({
      fromDate: null,
      toDate: null,
      companyName: "",
      contactNumber: "",
      contactEmail: "",
      filter: "",
      page: 1,
    });
    handleSearchJobPosted({
      fromDate: null,
      toDate: null,
      companyName: "",
      contactNumber: "",
      contactEmail: "",
      filter: "",
      page: 1,
    });
  };

  const handlePageChange = (event, value) => {
    setValues({ ...values, page: value });
    handleSearchJobPosted({ ...values, page: value });
  };

  const handleChange = (prop) => (event) => {
    if (prop === "fromDate" || prop === "toDate") {
      setValues({ ...values, [prop]: event });
    } else {
      setValues({ ...values, [prop]: event.target.value });
    }
  };

  const handleChangeAutoComplete = (prop, value) => {
    setValues({ ...values, [prop]: value });
  };

  const handleEnterKeyPress = (event, value) => {
    if (event.key === "Enter") {
      handleSearchJobPosted({ ...values, page: value });
    }
  };

  return (
    <div>
      <Paper
        sx={{
          textAlign: "center",
          padding: "5px",
        }}
      >
        <Box
          sx={{ justifyContent: "center", borderRadius: "5px" }}
          className="heading"
        >
          <Typography variant="h5">
            JobPosted(
            {jobPostedCount ? parseInt(jobPostedCount).toLocaleString() : "NA"})
          </Typography>
        </Box>
        <p className="Bold">Employer count who posted job(s) : </p>
        <div className="Textfield">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <DesktopDatePicker
              sx={{
                width: "12vw",
                textAlign: "center",
                justifyContent: "center",
              }}
              value={values.fromDate}
              onChange={handleChange("fromDate")}
              label="From*"
              format="DD/MM/YYYY"
              maxDate={values.toDate}
              slotProps={{ textField: { size: "small" } }}
              onKeyPress={handleEnterKeyPress}
            />
            <DesktopDatePicker
              sx={{
                width: "12vw",
                textAlign: "center",
                justifyContent: "center",
              }}
              value={values.toDate}
              onChange={handleChange("toDate")}
              label="To*"
              format="DD/MM/YYYY"
              minDate={values.fromDate}
              slotProps={{ textField: { size: "small" } }}
              onKeyPress={handleEnterKeyPress}
            />
            <TextField
              inputProps={{
                maxLength: 10,
              }}
              size="small"
              sx={{
                width: "12vw",
                textAlign: "center",
                justifyContent: "center",
              }}
              label="Contact Number"
              value={values.contactNumber}
              onChange={handleChange("contactNumber")}
              onKeyDown={handleEnterKeyPress}
            ></TextField>
            <Autocomplete
              sx={{ width: "40vw" }}
              size="small"
              multiple
              options={filter}
              getOptionLabel={(option) => option.label}
              filterSelectedOptions
              value={
                filter.filter((filter) =>
                  values?.filter?.includes(filter.value)
                ) ?? ""
              }
              onChange={(event, value) => {
                handleChangeAutoComplete(
                  "filter",
                  value.map((filter) => filter.value)
                );
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="filter" />
              )}
              onKeyDown={handleEnterKeyPress}
            />
          </Stack>
        </div>

        <Stack
          justifyContent="center"
          alignItems="center"
          direction="row"
          spacing={3}
        >
          <LoadingButton
            // loading={searchLoading}
            loadingPosition="center"
            onClick={() => {
              setValues({ ...values, page : 1})
              handleSearchJobPosted({ ...values, page: 1 });
            }}
            variant="contained"
            className="search-button"
          >
            Search
          </LoadingButton>
          <Button
            variant="contained"
            className="reset-button"
            onClick={handleReset}
          >
            Reset
          </Button>
        </Stack>

        {loading ? (
          <CircularProgress
            className="Loading"
            loading={"true"}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <JobPostedTable
            pageNumber={values.page}
            handleSearch={() => handleSearchJobPosted(values)}
          />
        )}

        <Pagination
          sx={{
            marginLeft: "10px",
            marginRight: "10px",
            marginTop: "10px",
            paddingBottom: "10px",
            "Button.MuiPaginationItem-rounded.Mui-selected": {
              bgcolor: "rgb(35, 48, 68)",
              color: "rgb(238, 238, 238)",
            },
          }}
          page={values.page}
          count={
            parseInt(jobPostedCount / 10) + (jobPostedCount % 10 > 0 ? 1 : 0)
          }
          onChange={handlePageChange}
          shape="rounded"
          variant="outlined"
        />
      </Paper>
      <NormalJobEdit handleSearch={() => handleSearchJobPosted(values)} />
      <NormalJobView />
      <ContractView />
      <ContractEdit />
      <JobResponses />
    </div>
  );
}

const filter = [
  { label: "Normal", value: "Normal" },
  { label: "Contract", value: "Contract" },
  { label: "Paid", value: "Paid" },
  { label: "UnPaid ", value: "UnPaid" },
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
  { label: "All jobs retain", value: "All jobs retain" },
];
