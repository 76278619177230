import React, { useContext, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import {
  Stack,
  Box,
  Typography,
  TextField,
  Autocomplete,
  Slide,Dialog,CircularProgress,DialogTitle,DialogContent,
} from "@mui/material";
import { appContext } from "../../contexts/AppContext";
import { pendingContext } from "../../contexts/PendingContext";
import { useParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function PendingEdit({handleSearch}) {
  const {
    loading,
    locations,
    skills,
    editSkill,
    industry,
    employeeLocation,
    editIndustry,
    qualification,
    editQualification,
  } = useContext(appContext);
  const {
    viewPending,
    pendingEdit,
    updatePending,
    closeEditPendingViewModel,
    showEditPendingView,
  } = useContext(pendingContext);

  const params = useParams();
  useEffect(() => {
    employeeLocation();
    editIndustry();
    editQualification();
    editSkill();
    if (viewPending._id) {
      pendingEdit(viewPending._id);
    }
  }, []);

  useEffect(() => {
    setValues({
      jobProfile: viewPending?.jobProfile?._id
        ? viewPending?.jobProfile?._id
        : null,
      industry: viewPending?.industry?._id ? viewPending?.industry?._id : "",
      location: viewPending?.location ? viewPending?.location : "",
      qualification: viewPending?.qualification?._id
        ? viewPending?.qualification?._id
        : null,
      companyName: viewPending?.companyName ? viewPending?.companyName : "",
      contactPerson: viewPending?.contactPerson
        ? viewPending?.contactPerson
        : "",
      contactNumber: viewPending?.contactNumber
        ? viewPending?.contactNumber
        : "",
      contactEmail: viewPending?.contactEmail ? viewPending?.contactEmail : "",
      description: viewPending?.description ? viewPending?.description : "",
      interviewAddress: viewPending?.interviewAddress
        ? viewPending?.interviewAddress
        : "",
      minSalary: viewPending?.minSalary ? viewPending?.minSalary : "",
      maxSalary: viewPending?.maxSalary ? viewPending?.maxSalary : "",
      experience: viewPending?.experience ? viewPending?.experience : "",
      gender: viewPending?.gender ? viewPending?.gender : 1,
      facilities: viewPending?.facilities ? viewPending?.facilities : [],
    });
  }, [viewPending]);
  const [values, setValues] = useState({});

  const handleChangeAutoComplete = (prop, value) => {
    console.log("props", prop + "" + value);
    setValues({ ...values, [prop]: value });
  };
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = () => {
    updatePending(values, viewPending?._id);
    closeEditPendingViewModel();
    handleSearch();
  };

  return (
    <Dialog
      maxWidth={"lg"}
      open={showEditPendingView}
      onClose={closeEditPendingViewModel}
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          fontWeight: "bold",
          background: "rgb(35, 48, 68)",
          color: "White",
          textAlign: "center",
        }}
        id="customized-dialog-title"
      >
        PENDING JOB Edit
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeEditPendingViewModel}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        {loading ? (
          <CircularProgress
            className="Loading"
            loading={"true"}
            size={100}
            sx={{ marginLeft: "40%" }}
          />
        ) : (
          <Box>
            <Stack
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={1}
            >
              <Stack direction="column">
                <label>Job Profile: </label>
                <Autocomplete
                  sx={{ width: "24.5vw" }}
                  size="small"
                  options={skills}
                  getOptionLabel={(option) => option.name || ""}
                  value={
                    skills.find(
                      (skills) => skills._id === values?.jobProfile
                    ) || values?.jobProfile
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("jobProfile", value?._id);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="job Profile" />
                  )}
                />
              </Stack>
              <Stack direction="column">
                <label>Industry: </label>
                <Autocomplete
                  sx={{ width: "22.5vw" }}
                  size="small"
                  options={industry}
                  getOptionLabel={(option) => option.name || ""}
                  value={
                    industry.find(
                      (industry) => industry._id === values?.industry
                    ) || ""
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("industry", value?._id);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Industry" />
                  )}
                />
              </Stack>
              <Stack direction="column">
                <label>Location: </label>
                <Autocomplete
                  sx={{ width: "22.5vw" }}
                  size="small"
                  options={locations}
                  getOptionLabel={(option) => option.city || ""}
                  value={
                    locations.find((loc) => loc._id === values?.location) || ""
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("location", value?._id);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Locations" />
                  )}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={1}
              marginTop={1}
            >
              <Stack direction="column">
                <label>Min Salary: </label>
                <Autocomplete
                  sx={{ width: "12vw" }}
                  size="small"
                  options={MinSalary}
                  getOptionLabel={(option) => option.label || values?.minSalary}
                  value={
                    MinSalary.find(
                      (minSalary) => minSalary.value === values?.minSalary
                    ) || values?.minSalary
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("minSalary", value?.value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="MinSalary" />
                  )}
                />
              </Stack>
              <Stack direction="column">
                <label>Max Salary: </label>

                <Autocomplete
                  sx={{ width: "12vw" }}
                  size="small"
                  options={MaxSalary.filter(
                    (salary) => salary.value > values.minSalary
                  )}
                  getOptionLabel={(option) => option.label || values?.maxSalary}
                  value={
                    MaxSalary.find(
                      (maxSalary) => maxSalary.value === values?.maxSalary
                    ) || values?.maxSalary
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("maxSalary", value?.value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="MaxSalary" />
                  )}
                />
              </Stack>
              <Stack direction="column">
                <label>Experience: </label>
                <Autocomplete
                  sx={{ width: "15vw" }}
                  size="small"
                  options={Experience}
                  getOptionLabel={(option) => option.label || ""}
                  value={
                    Experience.find(
                      (experience) => experience.value === values?.experience
                    ) || ""
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("experience", value?.value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Experience" />
                  )}
                />
              </Stack>
              <Stack direction="column">
                <label>Qualification: </label>
                <Autocomplete
                  sx={{ width: "16vw" }}
                  size="small"
                  options={qualification}
                  getOptionLabel={(option) => option.name || ""}
                  value={
                    qualification.find(
                      (qualification) =>
                        qualification._id === values?.qualification
                    ) || ""
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("qualification", value?._id);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Qualification" />
                  )}
                />
              </Stack>
              <Stack direction="column">
                <label>Gender: </label>
                <Autocomplete
                  sx={{ width: "13vw" }}
                  size="small"
                  options={Gender}
                  getOptionLabel={(option) => option.label}
                  value={
                    Gender.find((gender) => gender.value === values?.gender) ||
                    0
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("gender", value?.value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Gender" />
                  )}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-evenly"
              marginTop={1.5}
            >
              <Stack direction="column">
                <label>Facilities: </label>
                <Autocomplete
                  sx={{ width: "71vw" }}
                  size="small"
                  multiple
                  filterSelectedOptions
                  options={facilities}
                  getOptionLabel={(option) => option.label || ""}
                  value={
                    facilities.filter((facilities) =>
                      values?.facilities?.includes(facilities.value)
                    ) || ""
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete(
                      "facilities",
                      value.map((benefits) => benefits.value)
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Facilities" />
                  )}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-evenly"
              marginTop={1.5}
            >
              <Stack direction="column" sx={{ width: "71vw" }}>
                <label> Description</label>
                <textarea
                  placeholder="Description"
                  name="description"
                  rows={3}
                  className="Input"
                  value={values.description}
                  onChange={handleChange("description")}
                />
              </Stack>
            </Stack>
            <Typography
              sx={{
                fontSize: "25px",
                marginTop: 2,
              }}
            >
              Company Details
            </Typography>
            <Stack
              direction="row"
              justifyContent="space-evenly"
              spacing={1}
              marginTop={1}
            >
              <Stack direction="column">
                <label>Company Name: </label>
                <TextField
                  sx={{ width: "26vw" }}
                  size="small"
                  placeholder="Company Name"
                  value={values.companyName}
                  onChange={handleChange("companyName")}
                ></TextField>
              </Stack>
              <Stack direction="column">
                <label>Contact Person: </label>
                <TextField
                  sx={{ width: "15vw" }}
                  size="small"
                  placeholder="Contact Person"
                  value={values?.contactPerson}
                  onChange={handleChange("contactPerson")}
                />
              </Stack>
              <Stack direction="column">
                <label>Company Email: </label>
                <TextField
                  sx={{ width: "18vw" }}
                  size="small"
                  placeholder="Company Email"
                  value={values?.contactEmail}
                  onChange={handleChange("contactEmail")}
                />
              </Stack>
              <Stack direction="column">
                <label>Contact Number: </label>
                <TextField
                  sx={{ width: "10vw" }}
                  size="small"
                  placeholder="Contact Number"
                  value={values?.contactNumber}
                  onChange={handleChange("contactNumber")}
                />
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent="space-evenly" marginTop={1}>
              <Stack direction="column">
                <label> Interview Address: </label>
                <TextField
                  sx={{ width: "71vw" }}
                  size="small"
                  placeholder="Interview Address"
                  value={values?.interviewAddress}
                  onChange={handleChange(" Interview Address")}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing={7}
              justifyContent="center"
              marginTop={4}
            >
              <Button
                className="search-button"
                variant="contained"
                onClick={handleSubmit}
              >
                publish
              </Button>
              <Button
                className="reset-button"
                variant="contained"
                onClick={closeEditPendingViewModel}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
const facilities = [
  { label: "Esi/PF", value: "Esi/PF" },
  { label: "Lodge", value: "Lodge" },
  { label: "Food", value: "Food" },
  { label: "Bonus", value: "Bonus" },
];

const Gender = [
  { label: "Male", value: 1 },
  { label: "Female", value: 2 },
  { label: "Both", value: 3 },
];

const Experience = [
  { label: "Fresher", value: "0" },
  { label: "1+ Years", value: "1+" },
  { label: "2+ Years", value: "2+" },
  { label: "3+ Years", value: "3+" },
  { label: "4+ Years", value: "4+" },
  { label: "5+ Years", value: "5+" },
  { label: "6+ Years", value: "6+" },
  { label: "7+ Years", value: "7+" },
  { label: "8+ Years", value: "8+" },
  { label: "9+ Years", value: "9+" },
  { label: "10+ Years", value: "10+" },
];

const MinSalary = [
  { label: "10000", value: 10000 },
  { label: "12000", value: 12000 },
  { label: "14000", value: 14000 },
  { label: "16000", value: 16000 },
  { label: "18000", value: 18000 },
  { label: "20000", value: 20000 },
  { label: "22000", value: 22000 },
  { label: "24000", value: 24000 },
  { label: "26000", value: 26000 },
  { label: "28000", value: 28000 },
  { label: "30000", value: 30000 },
  { label: "35000", value: 35000 },
  { label: "40000", value: 40000 },
  { label: "45000", value: 45000 },
  { label: "50000", value: 50000 },
  { label: "60000", value: 60000 },
  { label: "70000", value: 70000 },
  { label: "80000", value: 80000 },
];

const MaxSalary = [
  { label: "12000", value: 12000 },
  { label: "14000", value: 14000 },
  { label: "16000", value: 16000 },
  { label: "18000", value: 18000 },
  { label: "20000", value: 20000 },
  { label: "22000", value: 22000 },
  { label: "24000", value: 24000 },
  { label: "26000", value: 26000 },
  { label: "28000", value: 28000 },
  { label: "30000", value: 30000 },
  { label: "12000", value: 12000 },
  { label: "14000", value: 14000 },
  { label: "16000", value: 16000 },
  { label: "18000", value: 18000 },
  { label: "20000", value: 20000 },
  { label: "22000", value: 22000 },
  { label: "24000", value: 24000 },
  { label: "26000", value: 26000 },
  { label: "28000", value: 28000 },
  { label: "30000", value: 30000 },
  { label: "35000", value: 35000 },
  { label: "40000", value: 40000 },
  { label: "45000", value: 45000 },
  { label: "50000", value: 50000 },
  { label: "60000", value: 60000 },
  { label: "70000", value: 70000 },
  { label: "80000", value: 80000 },
];
