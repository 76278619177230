import React, { useEffect, useContext } from "react";
import { appContext } from "../contexts/AppContext";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import WorkIcon from "@mui/icons-material/Work";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import DomainIcon from "@mui/icons-material/Domain";
import SmsRoundedIcon from "@mui/icons-material/SmsRounded";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PollIcon from '@mui/icons-material/Poll';
import {Box, Stack ,CircularProgress} from "@mui/material";

export default function Dashboard() {
  let navigate = useNavigate();
  const { dashboardCount, dashboard, loading } = useContext(appContext);

  useEffect(() => {
    dashboardCount();
  }, []);

  return (
    <Stack alignItems="center" sx={{ marginTop: "50px" }}>
      {loading ? (
        <CircularProgress
          className="Loader"
          loading={"true"}
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <Box className="DasboardBox">
          <Stack direction="row" justifyContent="space-evenly" spacing={6}>
            <Stack direction="row" spacing={2}>
              <PersonIcon
                onClick={() => {
                  navigate("/employee");
                }}
                sx={{
                  height: "90%",
                  width: "30%",
                  background: "#8F00FF",
                  color: "white",
                  borderRadius: "5px",
                  boxShadow: "1px 1px 1px 1px #8F00FF ",
                  cursor: "pointer",
                  padding: "10px"
                }}
              />
              <Stack spacing={1}>
                <p  className="Bold">Employee</p>
                <p className="FontSize">
                  {dashboard.Employee
                    ? parseInt(dashboard.Employee).toLocaleString()
                    : "NA"}
                </p>
              </Stack>
            </Stack>

            <Stack direction="row" spacing={2}>
              <PeopleAltIcon
                onClick={() => {
                  navigate("/employer");
                }}
                sx={{
                  height: "90%",
                  width: "35%",
                  background: "#82b74b",
                  color: "white",
                  borderRadius: "5px",
                  boxShadow: "1px 1px 1px 1px #82b74b ",
                  cursor: "pointer",
                  padding: "10px"
                }}
              />
              <Stack spacing={1}>
                <p className="Bold">Employer</p>
                <p className="FontSize">
                  {dashboard.Employer
                    ? parseInt(dashboard.Employer).toLocaleString()
                    : "NA"}
                </p>
              </Stack>
            </Stack>

            <Stack direction="row" spacing={2}>
              <WorkIcon
                onClick={() => {
                  navigate("/jobPosted");
                }}
                sx={{
                  height: "90%",
                  width: "35%",
                  background: "#cc99a2",
                  color: "white",
                  borderRadius: "5px",
                  boxShadow: "1px 1px 1px 1px #cc99a2 ",
                  cursor: "pointer",
                  padding: "10px"
                }}
              />
              <Stack spacing={1}>
                <p  className="Bold">Job Posted</p>
                <p className="FontSize">
                  {dashboard.JobsListing
                    ? parseInt(dashboard.JobsListing).toLocaleString()
                    : "NA"}
                </p>
              </Stack>
            </Stack>

            <Stack direction="row" spacing={2}>
              <ShoppingCartIcon
                onClick={() => {
                  navigate("/orders");
                }}
                sx={{
                  height: "90%",
                  width: "35%",
                  background: "#008170",
                  color: "white",
                  borderRadius: "5px",
                  boxShadow: "1px 1px 1px 1px #008170 ",
                  cursor: "pointer",
                  padding: "10px"
                }}
              />
              <Stack spacing={1}>
                <p  className="Bold">Orders</p>
                <p className="FontSize">
                  {dashboard.Order
                    ? parseInt(dashboard.Order).toLocaleString()
                    : "NA"}
                </p>
              </Stack>
            </Stack>
          </Stack>


          <Stack direction="row" justifyContent="space-evenly" spacing={6} sx={{ marginTop: "60px" }}>
          <Stack direction="row" spacing={2}>
              <SmsRoundedIcon
                onClick={() => {
                  navigate("/smslogs");
                }}
                sx={{
                  height: "90%",
                  width: "32%",
                  background: "#78cff2",
                  color: "white",
                  borderRadius: "5px",
                  boxShadow: "1px 1px 1px 1px #78cff2 ",
                  cursor: "pointer",
                  padding: "10px"
                }}
              />
              <Stack spacing={1}>
                <p  className="Bold">SMS Logs</p>
                <p className="FontSize">
                  {dashboard.SmsLogs
                    ? parseInt(dashboard.SmsLogs).toLocaleString()
                    : "NA"}
                </p>
              </Stack>
            </Stack>

            <Stack direction="row" spacing={2}>
              <WorkHistoryIcon
                onClick={() => {
                  navigate("/skill");
                }}
                sx={{
                  height: "90%",
                  width: "32%",
                  background: "#FF7676",
                  color: "white",
                  borderRadius: "5px",
                  boxShadow: "1px 1px 1px 1px #FF7676 ",
                  cursor: "pointer",
                  padding: "10px"
                }}
              />
              <Stack spacing={1}>
                <p  className="Bold">Skill</p>
                <p className="FontSize">
                  {dashboard.Skill
                    ? parseInt(dashboard.Skill).toLocaleString()
                    : "NA"}
                </p>
              </Stack>
            </Stack>

      
            <Stack direction="row" spacing={2}>
              <DomainIcon
                onClick={() => {
                  navigate("/industries");
                }}
                sx={{
                  height: "90%",
                  width: "35%",
                  background: "#704241",
                  color: "white",
                  borderRadius: "5px",
                  boxShadow: "1px 1px 1px 1px #704241 ",
                  cursor: "pointer",
                  padding: "10px"
                }}
              />
              <Stack spacing={1}>
                <p  className="Bold">Industries</p>
                <p className="FontSize">
                  {dashboard.Industry
                    ? parseInt(dashboard.Industry).toLocaleString()
                    : "NA"}
                </p>
              </Stack>
            </Stack>

            <Stack direction="row" spacing={2}>
              <BusinessCenterIcon
                onClick={() => {
                  navigate("/pendingjobpost");
                }}
                sx={{
                  height: "90%",
                  width: "35%",
                  background: "#deb887",
                  color: "white",
                  borderRadius: "5px",
                  boxShadow: "1px 1px 1px 1px #deb887 ",
                  cursor: "pointer",
                  padding: "10px"
                }}
              />
              <Stack spacing={1}>
                <p  className="Bold">Pending Jobs</p>
                <p className="FontSize">
                  {dashboard.PendingJobPost
                    ? parseInt(dashboard.PendingJobPost).toLocaleString()
                    : "NA"}
                </p>
              </Stack>
            </Stack>
          </Stack>


          <Stack direction="row" justifyContent="space-evenly" spacing={0} sx={{ marginTop: "60px", marginRight: "20%" }}>
            <Stack direction="row" spacing={1}>
              <LocationOnIcon
                onClick={() => {
                  navigate("/Location");
                }}
                sx={{
                  height: "85%",
                  width: "25%",
                  background: "#ae0e52",
                  color: "white",
                  borderRadius: "5px",
                  boxShadow: "1px 1px 1px 1px #ae0e52 ",
                  cursor: "pointer",
                  padding: "10px"
                }}
              />
              <Stack spacing={1}>
                <p  className="Bold">Locations</p>
                <p className="FontSize">
                  {dashboard.Location
                    ? parseInt(dashboard.Location).toLocaleString()
                    : "NA"}
                </p>
              </Stack>
            </Stack>

            <Stack direction="row" spacing={2} sx={{ marginRight: "40vh" }}>
              <PollIcon
                onClick={() => {
                  navigate("/plans");
                }}
                sx={{
                  height: "85%",
                  width: "28%",
                  background: "#d0ae8b",
                  color: "white",
                  borderRadius: "5px",
                  boxShadow: "1px 1px 1px 1px #d0ae8b ",
                  cursor: "pointer",
                  padding: "10px"
                }}
              />
              <Stack spacing={1}>
                <p  className="Bold">Plans</p>
                <p className="FontSize">
                  {dashboard.Plan
                    ? parseInt(dashboard.Plan).toLocaleString()
                    : "NA"}
                </p>
              </Stack>
            </Stack>


          </Stack>
        </Box>


      )}
    </Stack>
  );
}
