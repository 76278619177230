import "./App.css";
import * as React from "react";
import AppRouter from "./routers/index";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { createTheme, styled } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppProvider } from "./contexts/AppContext";
import { AuthProvider } from "./contexts/AuthContext";
import EmployeeOrderProvider from "./contexts/EmployeeOrderContext";
import OrderProvider from "./contexts/OrderContext";
import EmployeeProvider from "./contexts/EmployeeContext";
import EmployerProvider from "./contexts/EmployerContext";
import PendingProvider from "./contexts/PendingContext";
import FeedbackProvider from "./contexts/FeedbackContext";
import IndustryProvider from "./contexts/IndustryContext";
import JobApplicationProvider from "./contexts/JobApplicationContext";
import SkillProvider from "./contexts/SkillContext";
import SearchResumeLogProvider from "./contexts/SearchResumeLogContext";
import PlanProvider from "./contexts/PlanContext"
import JobPostedProvider from "./contexts/JobPostedContext";
import SmsLogsProvider from "./contexts/SmsLogsContext";
import LocationProvider from "./contexts/LocationContext";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(145, 85, 253)",
    },
    secondary: {
      main: "#ff1744",
    },
  },
  components: {
    MuiDatePicker: {
      styleOverrides: {
        root: {
          backgroundColor: "#3f51b5",
        },
      },
    },
  },
});

function App() {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <AuthProvider>
            <EmployeeOrderProvider>
              <OrderProvider>
                <EmployeeProvider>
                  <EmployerProvider>
                    <PendingProvider>
                      <FeedbackProvider>
                        <IndustryProvider>
                          <JobApplicationProvider>
                            <SkillProvider>
                              <SearchResumeLogProvider>
                                <PlanProvider>
                                  <JobPostedProvider>
                                    <SmsLogsProvider>
                                      <LocationProvider>
                              <AppProvider>
                                <AppRouter />
                                <ToastContainer
                                  position="top-right"
                                  autoClose={2000}
                                  hideProgressBar={false}
                                  newestOnTop={false}
                                  closeOnClick
                                  rtl={false}
                                  pauseOnFocusLoss
                                  draggable
                                  pauseOnHover={false}
                                  theme="colored"
                                />
                              </AppProvider>
                              </LocationProvider>
                              </SmsLogsProvider>
                              </JobPostedProvider>
                              </PlanProvider>
                              </SearchResumeLogProvider>
                            </SkillProvider>
                          </JobApplicationProvider>
                        </IndustryProvider>
                      </FeedbackProvider>
                    </PendingProvider>
                  </EmployerProvider>
                </EmployeeProvider>
              </OrderProvider>
            </EmployeeOrderProvider>
          </AuthProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </React.Fragment>
  );
}
export default App;
