import React, { useEffect, useContext, useState } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import EmployeeTable from "./Table";
import EmployeeView from "./EmployeeView";
import EmployeeEdit from "./EmployeeEdit";
import {
  Typography,
  TextField,
  Pagination,
  Stack,
  Button,
  Box,
  Paper,
  Autocomplete,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/system";
import { employeeContext } from "../../contexts/EmployeeContext";
import { appContext } from "../../contexts/AppContext";
const CustomCheckbox = styled(Checkbox)({
  color: "rgb(35, 48, 68)",
  "&.Mui-checked": {
    color: "#9155fd",
  },
});

export default function Employee() {
  const {
    profile,
    employeeProfile,
    loading,
    employeeLocation,
    locations,
    handleSkillSuggestion,
    skillSuggestion,
  } = useContext(appContext);
  const { handleSearchEmployee, employeeCount } = useContext(employeeContext);
  const [values, setValues] = useState({
    fromDate: null,
    toDate: null,
    profile: "",
    skills: [],
    location: "",
    mobile: "",
    isActive: true,
    page: 1,
  });

  useEffect(() => {
    employeeProfile();
    employeeLocation();
    handleSearchEmployee({ ...values });
  }, []);

  const handlePageChange = (event, value) => {
    setValues({ ...values, page: value });
    handleSearchEmployee({ ...values, page: value });
  };

  const handleChange = (prop) => (event) => {
    if (prop === "fromDate" || prop === "toDate") {
      setValues({ ...values, [prop]: event });
    } else {
      setValues({ ...values, [prop]: event.target.value });
    }
  };

  const handleChangeAutoComplete = (prop, value) => {
    setValues({ ...values, [prop]: value });
  };

  const handleCheck = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.checked });
  };

  const handleReset = () => {
    setValues({
      fromDate: null,
      toDate: null,
      profile: "",
      skills: [],
      location: "",
      mobile: "",
      isActive: true,
      page: 1,
    });
    handleSearchEmployee({
      fromDate: null,
      toDate: null,
      profile: "",
      skills: [],
      location: "",
      mobile: "",
      isActive: true,
      page: 1,
    });
  };

  const handleEnterKeyPress = (event, value) => {
    if (event.key === "Enter") {
      handleSearchEmployee({ ...values, page: value });
    }
  };

  return (
    <div>
      <Paper
        sx={{
          textAlign: "center",
          padding: "5px",
          marginTop: "10px",
        }}
      >
        <Box
          sx={{ justifyContent: "center", borderRadius: "5px" }}
          className="heading"
        >
          <Typography variant="h5">
            Employee(
            {employeeCount ? parseInt(employeeCount).toLocaleString() : "NA"})
          </Typography>
        </Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          sx={{
            marginLeft: "10px",
            marginRight: "10px",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <DesktopDatePicker
            sx={{
              width: "12vw",
              textAlign: "center",
              justifyContent: "center",
            }}
            value={values.fromDate}
            onChange={handleChange("fromDate")}
            label="From*"
            format="DD/MM/YYYY"
            maxDate={values.toDate}
            slotProps={{ textField: { size: "small" } }}
            onKeyPress={handleEnterKeyPress}
          />
          <DesktopDatePicker
            sx={{
              width: "12vw",
              textAlign: "center",
              justifyContent: "center",
            }}
            value={values.toDate}
            onChange={handleChange("toDate")}
            label="To*"
            format="DD/MM/YYYY"
            minDate={values.fromDate}
            slotProps={{ textField: { size: "small" } }}
            onKeyPress={handleEnterKeyPress}
          />
          <Autocomplete
            sx={{ width: "12vw" }}
            size="small"
            options={locations}
            getOptionLabel={(option) => option.city ?? ""}
            value={
              locations.find((location) => location._id === values?.location) ??
              ""
            }
            onChange={(event, value) => {
              handleChangeAutoComplete("location", value?._id);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Locations" />
            )}
            onKeyDown={handleEnterKeyPress}
          />
          <TextField
            inputProps={{
              maxLength: 10,
            }}
            size="small"
            sx={{
              width: "12vw",
              textAlign: "center",
              justifyContent: "center",
            }}
            label="Mobile Number"
            value={values.mobile}
            onChange={handleChange("mobile")}
            onKeyDown={handleEnterKeyPress}
          />
          <Autocomplete
            sx={{ width: "16vw" }}
            size="small"
            options={profile}
            getOptionLabel={(option) => option.name || ""}
            value={
              profile.find((profile) => profile._id === values?.profile) || ""
            }
            onChange={(event, value) => {
              handleChangeAutoComplete("profile", value?._id);
            }}
            renderInput={(params) => <TextField {...params} label="Profile" />}
            onKeyDown={handleEnterKeyPress}
          />
          <Box
            sx={{
              border: "1px solid #c18eda",
              paddingRight: "20px",
              paddingLeft: "10px",
              borderRadius: "5px",
              color: "rgb(35, 48, 68)",
            }}
          >
            <CustomCheckbox
              color="default"
              size="small"
              checked={values.isActive}
              onChange={handleCheck("isActive")}
            />
            IsActive
          </Box>
        </Stack>
        <Stack className="SearchButton" spacing={2} direction="row">
          <Autocomplete
            sx={{ width: "55vw" }}
            size="small"
            multiple
            onInputChange={(event, newInputValue) => {
              handleSkillSuggestion({
                skillSearch: newInputValue,
                parentProfileId: values?.profile || "",
              });
            }}
            onOpen={() => {
              handleSkillSuggestion({
                skillSearch: "",
                parentProfileId: values?.profile || "",
              });
            }}
            options={skillSuggestion}
            getOptionLabel={(option) => option.name || ""}
            filterOptions={(options, { inputValue }) =>
              options.filter(
                (option) =>
                  !values.skills.map((skill) => skill._id).includes(option._id)
              )
            }
            value={values?.skills}
            onChange={(event, value) => {
              handleChangeAutoComplete("skills", value);
            }}
            renderInput={(params) => (
              <TextField {...params} placeholder="Skills" />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option?._id}>
                {option.name}
              </li>
            )}
            onKeyDown={handleEnterKeyPress}
          />
          <LoadingButton
            disabled={loading}
            loadingPosition="center"
            onClick={() => {
              setValues({ ...values, page: 1 });
              handleSearchEmployee({ ...values, page: 1 });
            }}
            variant="contained"
            className="search-button"
          >
            Search
          </LoadingButton>
          <Button
            variant="contained"
            className="reset-button"
            onClick={handleReset}
          >
            Reset
          </Button>
        </Stack>
        {loading ? (
          <CircularProgress className="Loading" loading={"true"} size={100} />
        ) : (
          <EmployeeTable pageNumber={values.page} />
        )}
        <Pagination
          sx={{
            marginLeft: "10px",
            marginRight: "10px",
            marginTop: "10px",
            paddingBottom: "10px",
            "Button.MuiPaginationItem-rounded.Mui-selected": {
              bgcolor: "rgb(35, 48, 68)",
              color: "rgb(238, 238, 238)",
            },
          }}
          count={
            parseInt(employeeCount / 10) + (employeeCount % 10 > 0 ? 1 : 0)
          }
          page={values.page}
          onChange={handlePageChange}
          shape="rounded"
          variant="outlined"
        />
      </Paper>
      <EmployeeView />
      <EmployeeEdit handleSearch={() => handleSearchEmployee({ ...values })}/>
    </div>
  );
}
