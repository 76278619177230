import React, { useContext, useState, useEffect } from "react";
import { locationContext } from "../../contexts/LocationContext";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
    Dialog,
    DialogContent,
    Typography,
    Button,
    Slide,
    DialogTitle,
    CircularProgress,
    Stack,
    Box,
    TextField,
    Autocomplete,
  } from "@mui/material";
  import IconButton from "@mui/material/IconButton";
  import CloseIcon from "@mui/icons-material/Close";
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });
export default function LocationEdit() {
  const {
    loading,
    viewLocations,
    locationsEdit,
    handleEditLocations,
    closeLocationEditModel,showLocationEdit
  } = useContext(locationContext);
  const params = useParams();
  useEffect(() => {
    locationsEdit(viewLocations._id);
  }, []);

  useEffect(() => {
    setValues({
      location: viewLocations?.location ? viewLocations?.location : "",
      city: viewLocations?.city ? viewLocations?.city : "",
      country: viewLocations?.country ? viewLocations?.country : "",
      type: viewLocations?.type ? viewLocations?.type : "",
      status: viewLocations?.status ? viewLocations?.status : "",
      state: viewLocations?.state ? viewLocations?.state : "",
      district: viewLocations?.district ? viewLocations?.district : "",
      alias: viewLocations?.alias ? viewLocations?.alias : "",
      countryFlagUrl: viewLocations?.countryFlagUrl
        ? viewLocations?.countryFlagUrl
        : "",
      postalCode: viewLocations?.postalCode ? viewLocations?.postalCode : "",

      English: viewLocations?.multilingual?.English
        ? viewLocations?.multilingual?.English
        : "",
      Hindi: viewLocations?.multilingual?.Hindi
        ? viewLocations?.multilingual?.Hindi
        : "",
      Marathi: viewLocations?.multilingual?.Marathi
        ? viewLocations?.multilingual?.Marathi
        : "",
      Tamil: viewLocations?.multilingual?.Tamil
        ? viewLocations?.multilingual?.Tamil
        : "",
      Telugu: viewLocations?.multilingual?.Telugu
        ? viewLocations?.multilingual?.Telugu
        : "",
      Kannada: viewLocations?.multilingual?.Kannada
        ? viewLocations?.multilingual?.Kannada
        : "",
    });
  }, [viewLocations]);

  const [values, setValues] = useState({});

  const handleSubmit = () => {
    handleEditLocations(values, viewLocations._id);
    closeLocationEditModel()
  };
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeAutoComplete = (prop, value) => {
    setValues({ ...values, [prop]: value });
  };
  let navigate = useNavigate();

  return (
    <Dialog
    maxWidth={"lg"}
    open={showLocationEdit}
    onClose={closeLocationEditModel}
    aria-labelledby="customized-dialog-title"
    TransitionComponent={Transition}
    keepMounted
  >
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        fontWeight: "bold",
        background: "rgb(35, 48, 68)",
        color: "White",
        textAlign: "center",
      }}
      id="customized-dialog-title"
    >
      UPDATE Location
    </DialogTitle>
    <IconButton
      aria-label="close"
      onClick={closeLocationEditModel}
      sx={{
        position: "absolute",
        right: 8,
        top: 5,
        color: "white",
      }}
    >
      <CloseIcon />
    </IconButton>
    <DialogContent dividers sx={{ height: "100vh" }}>
      {loading ? (
        <CircularProgress
          className="Loading"
          loading={loading}
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"
          sx={{ marginLeft: "40%" }}
        />
      ) : (
        <Box
        >
          <Stack
            direction="row"
            spacing={3}
            justifyContent="space-evenly"
            sx={{ marginTop: "10px" }}
          >
            <Stack direction="column">
              <label>Location: </label>
              <TextField
                sx={{ width: "35vw" }}
                size="small"
                placeholder="Location"
                value={values.location}
                onChange={handleChange("location")}
              ></TextField>
            </Stack>
            <Stack direction="column">
              <label>City: </label>
              <TextField
                sx={{ width: "35vw" }}
                size="small"
                placeholder="City"
                value={values?.city}
                onChange={handleChange("city")}
              />
            </Stack>
          </Stack>

          <Stack
            direction="row"
            spacing={3}
            justifyContent="space-evenly"
            sx={{ marginTop: "10px" }}
          >
            <Stack direction="column">
              <label>Country: </label>
              <TextField
                sx={{ width: "35vw" }}
                size="small"
                placeholder="Country"
                value={values.country}
                onChange={handleChange("country")}
              ></TextField>
            </Stack>
            <Stack direction="column">
              <label>State: </label>
              <TextField
                sx={{ width: "35vw" }}
                size="small"
                placeholder="State"
                value={values?.state}
                onChange={handleChange("state")}
              />
            </Stack>
          </Stack>

          <Stack
            direction="row"
            spacing={3}
            justifyContent="space-evenly"
            sx={{ marginTop: "10px" }}
          >
            <Stack direction="column">
              <label>District: </label>
              <TextField
                sx={{ width: "35vw" }}
                size="small"
                placeholder="District"
                value={values.district}
                onChange={handleChange("district")}
              ></TextField>
            </Stack>
            <Stack direction="column">
              <label>Alias: </label>
              <TextField
                sx={{ width: "35vw" }}
                size="small"
                placeholder="Alias"
                value={values?.alias}
                onChange={handleChange("alias")}
              />
            </Stack>
          </Stack>

          <Stack
            direction="row"
            spacing={3}
            justifyContent="space-evenly"
            sx={{ marginTop: "10px" }}
          >
            <Stack direction="column">
              <label>Status: </label>
              <Autocomplete
                sx={{ width: "22vw" }}
                size="small"
                options={Status}
                getOptionLabel={(option) => option.label}
                value={Status.find((status) => status.value === values?.status)}
                onChange={(event, value) => {
                  handleChangeAutoComplete("status", value?.value);
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="status" />
                )}
              />
            </Stack>
            <Stack direction="column">
              <label>Postal Code: </label>
              <TextField
                sx={{ width: "23vw" }}
                size="small"
                type="number"
                inputProps={{ min: 0 }}
                placeholder="Postal Code"
                value={values.postalCode}
                onChange={handleChange("postalCode")}
              />
            </Stack>
            <Stack direction="column">
              <label>Type: </label>
              <TextField
                sx={{ width: "23vw" }}
                size="small"
                type="number"
                inputProps={{ min: 0 }}
                placeholder="Type"
                value={values?.type}
                onChange={handleChange("type")}
              />
            </Stack>
          </Stack>
          <Stack
            direction="row"
            spacing={3}
            justifyContent="space-evenly"
            sx={{ marginTop: "10px" }}
          >
            <Stack direction="column">
              <label>Country Flag URL: </label>
              <TextField
                sx={{ width: "72vw" }}
                size="small"
                placeholder="Country Flag URL"
                value={values.countryFlagUrl}
                onChange={handleChange("countryFlagUrl")}
              ></TextField>
            </Stack>
          </Stack>
          <div>
            <Typography
              sx={{ fontSize: "25px", paddingLeft: "33px", marginTop: "20px" }}
            >
              Languages
            </Typography>
          </div>

          <Stack
            direction="row"
            spacing={3}
            justifyContent="space-evenly"
            sx={{ marginTop: "10px" }}
          >
            <Stack direction="column">
              <label>English: </label>
              <TextField
                sx={{ width: "35vw" }}
                size="small"
                placeholder="English"
                value={values.English}
                onChange={handleChange("English")}
              />
            </Stack>
            <Stack direction="column">
              <label>Hindi: </label>
              <TextField
                sx={{ width: "35vw" }}
                size="small"
                placeholder="हिंदी"
                value={values.Hindi}
                onChange={handleChange("Hindi")}
              />
            </Stack>
          </Stack>
          <Stack
            direction="row"
            spacing={3}
            justifyContent="space-evenly"
            sx={{ marginTop: "10px" }}
          >
            <Stack direction="column">
              <label>Marathi: </label>
              <TextField
                sx={{ width: "35vw" }}
                size="small"
                placeholder="मराठी"
                value={values.Marathi}
                onChange={handleChange("Marathi")}
              />
            </Stack>
            <Stack direction="column">
              <label>Telugu: </label>
              <TextField
                sx={{ width: "35vw" }}
                size="small"
                placeholder="తెలుగు"
                value={values.Telugu}
                onChange={handleChange("Telugu")}
              />
            </Stack>
          </Stack>

          <Stack
            direction="row"
            spacing={3}
            justifyContent="space-evenly"
            sx={{ marginTop: "10px" }}
          >
            <Stack direction="column">
              <label>Tamil: </label>
              <TextField
                sx={{ width: "35vw" }}
                size="small"
                placeholder="தமிழ்"
                value={values.Tamil}
                onChange={handleChange("Tamil")}
              />
            </Stack>
            <Stack direction="column">
              <label>Kannada: </label>
              <TextField
                sx={{ width: "35vw" }}
                size="small"
                placeholder="ಕನ್ನಡ"
                value={values.Kannada}
                onChange={handleChange("Kannada")}
              />
            </Stack>
          </Stack>
          <Stack
           direction="row"
           alignItems="center"
           spacing={2}
           sx={{
             marginTop: "10px",
             justifyContent: "center",
           }}
          >
            <Button
              className="search-button"
              variant="contained"
              onClick={handleSubmit}
            >
              update
            </Button>
            <Button
              className="reset-button"
              variant="contained"
              onClick={closeLocationEditModel}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      )}
    </DialogContent>
  </Dialog>
);
}


  

const Status = [
  { label: "Active", value: 1 },
  { label: "Inactive", value: 0 },
];
