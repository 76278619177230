import React, { createContext, useState } from "react";
import axios from "axios";
import { backendUrl } from "../contants/contants";
import { toast } from "react-toastify";
export const pendingContext = createContext({});
const PendingProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
  const [showEditPending, setShowEditPending] = useState(false);
  const [pendingData, setPendingData] = useState([]);
  const [pendingCount, setPendingCount] = useState(0);
  const [showSearchPending, setShowSearchPending] = useState(false);
  const [viewPending, setViewPending] = useState([]);
  const [showEditPendingView, setShowEditPendingView] = useState(false);
  const [showPendingView, setShowPendingView] = useState(false);
  const showPendingViewModel = () => {
    setShowPendingView(true);
  };
  const closePendingViewModel = () => {
    setShowPendingView(false);
  };
  const showEditPendingViewModel = () => {
    setShowEditPendingView(true);
  };
  const closeEditPendingViewModel = () => {
    setShowEditPendingView(false);
  };
  const showSearchPendingModel = () => {
    setShowSearchPending(true);
  };
  const closeSearchPendingModel = () => {
    setShowSearchPending(false);
  };
  const handleSearchPending = (data) => {
    setLoading(true);
    axios
      .post(backendUrl + "/api/crm/pending/list", data)
      .then((response) => {
        setShowSearchPending(false);
        setPendingData(response.data.data.pendingJob);
        setPendingCount(response.data.data.count);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const pendingView = (id) => {
    axios
      .get(backendUrl + "/api/crm/pending?id=" + id)
      .then((response) => {
        setViewPending(response.data.data.pendingJob);
        setShowPendingView(true);
      })
      .catch((e) => {});
  };

  const pendingEdit = (id) => {
    axios
      .get(backendUrl + "/api/crm/pending?id=" + id)
      .then((response) => {
        setViewPending(response.data.data.pendingJob);
        setShowEditPendingView(true);
      })

      .catch((e) => {});
  };

  const updatePending = (data, _id) => {
    axios
      .put(backendUrl + "/api/crm/pending/update?id=" + _id, data)
      .then((response) => {
        setShowEditPending(false);
        toast.success("Successfully Updated!");
      })
      .catch((e) => {});
  };
  return (
    <pendingContext.Provider
      value={{
        loading,
        pendingData,
        pendingCount,
        handleSearchPending,
        showSearchPendingModel,
        closeSearchPendingModel,
        showSearchPending,
        showEditPending,
        updatePending,
        viewPending,
        closePendingViewModel,
        showPendingViewModel,
        showPendingView,
        showEditPendingView,
        showEditPendingViewModel,
        closeEditPendingViewModel,
        pendingView,
        pendingEdit,
      }}
    >
      {children}
    </pendingContext.Provider>
  );
};
export default PendingProvider;
