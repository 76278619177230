import React, { useContext } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {Dialog,DialogTitle,DialogContent,IconButton, Grid, Stack, Box, Chip, Slide, Paper } from "@mui/material";
import moment from "moment";
import { jobPostedContext } from "../../contexts/JobPostedContext";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function NormalJobView() {
  const { viewJob, closeNormalJobViewModel, showNormalJobView } = useContext(jobPostedContext);
  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      open={showNormalJobView}
      onClose={closeNormalJobViewModel}
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          fontWeight: "bold",
          background: "rgb(35, 48, 68)",
          color: "White",
          textAlign: "center",
        }}
        id="customized-dialog-title"
      >
        JOB POSTED DETAILS - {viewJob?._id}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeNormalJobViewModel}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        // sx={{ height: "100vh"  }}
        dividers
      >
        <Box sx={{ flexGrow: 1 }}>
          <Stack spacing={1}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Job Title:</p>
                  <p>{viewJob?.name} </p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Company Name:</p>
                  <p> {viewJob?.companyName} </p>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Contact Person:</p>
                  <p>{viewJob?.contactPerson} </p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Experience:</p>
                  <p> {viewJob?.experience} years</p>
                </Stack>
              </Grid>
            </Grid>



            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Mobile Number: </p>
                  <p>
                    {/* {viewJob?.contactNumber}  */}
                    {viewJob?.contactNumber
                      ? (viewJob?.contactNumber)
                      : ("N/A")}
                  </p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Location: </p>
                  <p>
                    {viewJob?.location?.location}
                  </p>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">PaidJob:</p>
                  <p>
                    {viewJob?.paidJob}

                  </p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> order: </p>
                  <p>
                    {/* {viewJob?.location?.location} */}
                    {viewJob?.order
                      ? (viewJob?.order)
                      : ("null")}
                  </p>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Min Salary: </p>
                  <p>{viewJob?.minSalary} </p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Max Salary: </p>
                  <p>{viewJob?.maxSalary} </p>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Updated at:</p>
                  <p>
                    {viewJob?.updatedAt
                      ? moment(viewJob?.updatedAt).format("D/MM/YYYY")
                      : ""}{" "}
                  </p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Status:</p>
                    <Chip
                      size="small"
                      sx={{
                        backgroundColor:
                          viewJob?.status === 1 ? "#97c53c" : "#e48813",
                        color: viewJob?.status === 1 ? "white" : "#fff",
                      }}
                      label={viewJob?.status === 1 ? "Active" : "InActive"}
                    />
                 
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Created at:</p>
                  <p>
                    {viewJob?.createdAt
                      ? moment(viewJob?.createdAt).format("D/MM/YYYY")
                      : ""}{" "}
                  </p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Expired at:</p>
                  <p>
                    {viewJob?.expiredAt
                      ? moment(viewJob?.expiredAt).format("D/MM/YYYY")
                      : "N/A"}

                  </p>
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={2}>

              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Job vacancy: </p>
                  <p>{viewJob?.jobVacancy} </p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Contact Email:</p>
                  <p> {viewJob?.contactEmail} </p>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={20}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Interview Address:</p>
                  <p> {viewJob?.interviewAddress} </p>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
