import React, { useContext, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import {
  Stack,
  Box,
  Typography,
  TextField,
  Autocomplete,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { jobPostedContext } from "../../contexts/JobPostedContext";
import { appContext } from "../../contexts/AppContext";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function ContractEdit() {
  const {
    
    profile,
    employeeProfile,
    loading,
    employeeLocation,
    locations,
    editFunctional,
    editIndustry,
    editQualification,
    skills,
    editSkill,
    handleSkillSuggestion,
    skillSuggestion,
  } = useContext(appContext);

  const {
    viewJob,
    handleEditJob,
    closeContractJobEditModel,
    showContractJobEdit,
  } = useContext(jobPostedContext);

  const [values, setValues] = useState({});

  useEffect(() => {
    employeeProfile();
    employeeLocation();
    editFunctional();
    editIndustry();
    editQualification();
    handleSkillSuggestion();
    editSkill();
    setValues({
      parentSkill: viewJob?.parentSkill ? viewJob?.parentSkill : null,
      location: viewJob?.location ? viewJob?.location : "",
      name: viewJob?.name ? viewJob?.name : "",
      companyName: viewJob?.companyName ? viewJob?.companyName : "",
      contactPerson: viewJob?.contactPerson ? viewJob?.contactPerson : "",
      contactNumber: viewJob?.contactNumber ? viewJob?.contactNumber : "",
      contactEmail: viewJob?.contactEmail ? viewJob?.contactEmail : "",
      interviewAddress: viewJob?.interviewAddress
        ? viewJob?.interviewAddress
        : "",
      shiftTimings_eight: viewJob?.shiftTimings_eight
        ? viewJob?.shiftTimings_eight
        : "",
      shiftTimings_twelve: viewJob?.shiftTimings_twelve
        ? viewJob?.shiftTimings_twelve
        : "",
      experience: viewJob?.experience ? viewJob?.experience : "",
      gender: viewJob?.gender ? viewJob?.gender : "",
      skills: viewJob?.skills ? viewJob?.skills : [],
      benefits: viewJob?.benefits ? viewJob?.benefits : [],
      description: viewJob?.description ? viewJob?.description : "",
      jobDurations: viewJob?.jobDurations ? viewJob?.jobDurations : "8 Hours",
      numberOfEmployees: viewJob?.numberOfEmployees
        ? viewJob?.numberOfEmployees
        : "",
      status: viewJob?.status ? viewJob?.status : 0,
      order: viewJob?.order ? viewJob?.order : null,
      paidJob: viewJob?.paidJob ? viewJob?.paidJob : 0,
    });
  }, [viewJob]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeAutoComplete = (prop, value) => {
    setValues({ ...values, [prop]: value });
  };

  const handleSubmit = () => {
    handleEditJob(values, viewJob?._id);
    closeContractJobEditModel();
  };

  return (
    <Dialog
      maxWidth={"lg"}
      open={showContractJobEdit}
      onClose={closeContractJobEditModel}
      aria-labelledby="customized-dialog-title"
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          fontWeight: "bold",
          background: "rgb(35, 48, 68)",
          color: "White",
          textAlign: "center",
        }}
        id="customized-dialog-title"
      >
        UPDATE CONTRACT JOB
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeContractJobEditModel}
        sx={{
          position: "absolute",
          right: 8,
          top: 5,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers sx={{ height: "100vh" }}>
        {loading ? (
          <CircularProgress
            className="Loading"
            loading={"true"}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
            sx={{ marginLeft: "40%" }}
          />
        ) : (
          <Box>
            <Stack
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={2}
            >
              <Stack direction="column">
                <label>Job Profile: </label>
                <TextField
                  sx={{ width: "27vw" }}
                  size="small"
                  placeholder="Job Profile"
                  value={values.name}
                  onChange={handleChange("name")}
                />
              </Stack>
              <Stack direction="column">
                <label>Parent Profile: </label>
                <Autocomplete
                  sx={{ width: "27vw" }}
                  size="small"
                  options={profile}
                  getOptionLabel={(option) => option.name || ""}
                  value={
                    profile.find(
                      (profile) => profile._id === values?.parentSkill
                    ) || ""
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("parentSkill", value?._id);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Parent Profile" />
                  )}
                />
              </Stack>
              <Stack direction="column">
                <label>Shift Timing: </label>
                <Autocomplete
                  sx={{ width: "19vw" }}
                  size="small"
                  options={shiftTimings}
                  getOptionLabel={(option) => option.label || ""}
                  value={
                    shiftTimings.find(
                      (shiftTimings) =>
                        shiftTimings.value === values?.jobDurations
                    ) || ""
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("jobDurations", value?.value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Shift Timing" />
                  )}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-evenly"
              marginTop={1}
            >
              <Stack direction="column">
                <label>Skills: </label>
                {/* <Autocomplete
                  sx={{ width: "76vw" }}
                  size="small"
                  multiple
                  filterSelectedOptions
                  options={skills}
                  getOptionLabel={(option) => option.name || ""}
                  value={
                    skills.filter((skills) =>
                      values?.skills?.includes(skills._id)
                    ) || ""
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete(
                      "skills",
                      value.map((skills) => skills._id)
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Skills" />
                  )}
                /> */}
                <Autocomplete
                  sx={{ width: "76vw" }}
                  size="small"
                  multiple
                  filterOptions={(options, { inputValue }) =>
                  options.filter((option) =>
                    !values.skills.map((skill) => skill.name).includes(option.name)
                  )
                }
                  onInputChange={(event, newInputValue) => {
                    handleSkillSuggestion({
                      skillSearch: newInputValue,
                      parentProfileId: values.parentSkill,
                    });
                  }}
                  onOpen={() => {
                    handleSkillSuggestion({
                      skillSearch: "",
                      parentProfileId: values.parentSkill,
                    });
                  }}
                  options={skillSuggestion}
                  getOptionLabel={(option) => option.name}
                  value={values?.skills}
                  onChange={(event, value) => {
                    handleChangeAutoComplete("skills", value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Skills" />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option?._id}>
                      {option?.name}
                    </li>
                  )}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-evenly"
              marginTop={1}
            >
              <Stack direction="column">
                <label>Locations: </label>
                <Autocomplete
                  sx={{ width: "18vw" }}
                  size="small"
                  options={locations}
                  getOptionLabel={(option) => option.city || ""}
                  value={
                    locations.find(
                      (locations) => locations._id === values?.location
                    ) || ""
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("location", value?._id);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Locations" />
                  )}
                />
              </Stack>
              {values.jobDurations === "8 Hours" ? (
                <Stack direction="column">
                  <label>Salary For 8 Hours: </label>
                  <Autocomplete
                    sx={{ width: "18vw" }}
                    size="small"
                    options={ShiftTimingsEight}
                    getOptionLabel={(option) => option.label}
                    value={
                      ShiftTimingsEight.find(
                        (ShiftTimingsEight) =>
                          ShiftTimingsEight.value === values?.shiftTimings_eight
                      ) || values?.shiftTimings_eight
                    }
                    onChange={(event, value) => {
                      handleChangeAutoComplete(
                        "shiftTimings_eight",
                        value?.value
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Salary For 8 Hours" />
                    )}
                  />
                </Stack>
              ) : (
                <Stack direction="column">
                  <label>Salary For 12 Hours: </label>
                  <Autocomplete
                    sx={{ width: "18vw" }}
                    size="small"
                    options={ShiftTimingsTwelve.filter(
                      (salary) => salary.value > values.ShiftTimingsEight
                    )}
                    getOptionLabel={(option) => option.label}
                    value={
                      ShiftTimingsTwelve.find(
                        (ShiftTimingsTwelve) =>
                          ShiftTimingsTwelve.value ===
                          values?.shiftTimings_twelve
                      ) || values?.shiftTimings_twelve
                    }
                    onChange={(event, value) => {
                      handleChangeAutoComplete(
                        "shiftTimings_twelve",
                        value?.value
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Salary For 12 Hours"
                      />
                    )}
                  />
                </Stack>
              )}
              <Stack direction="column">
                <label> Job Vacancy :</label>
                <Autocomplete
                  sx={{ width: "18vw" }}
                  size="small"
                  options={numberOfEmployees}
                  getOptionLabel={(option) => option.label}
                  value={
                    numberOfEmployees.find(
                      (numberOfEmployees) =>
                        numberOfEmployees.value === values?.numberOfEmployees
                    ) || values?.numberOfEmployees
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("numberOfEmployees", value?.value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Job Vacancy" />
                  )}
                />
              </Stack>
              <Stack direction="column">
                <label>Status: </label>
                <Autocomplete
                  sx={{ width: "18vw" }}
                  size="small"
                  options={Status}
                  getOptionLabel={(option) => option.label}
                  value={
                    Status.find((status) => status.value === values?.status) ||
                    0
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("status", value?.value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Status" />
                  )}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-evenly"
              marginTop={1}
            >
              <Stack direction="column">
                <label>Facility & Benefits: </label>
                <Autocomplete
                  sx={{ width: "40vw" }}
                  size="small"
                  multiple
                  filterSelectedOptions
                  options={benefits}
                  getOptionLabel={(option) => option.label || ""}
                  value={
                    benefits.filter((benefits) =>
                      values?.benefits?.includes(benefits.value)
                    ) || ""
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete(
                      "benefits",
                      value.map((benefits) => benefits.value)
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Facility & Benefits:" />
                  )}
                />
              </Stack>
              <Stack direction="column">
                <label>Order: </label>
                <TextField
                  sx={{ width: "19vw" }}
                  size="small"
                  placeholder="Order"
                  value={values?.order}
                  onChange={handleChange("order")}
                />
              </Stack>
              <Stack direction="column">
                <label> PaidJob :</label>
                <Autocomplete
                  sx={{ width: "14vw" }}
                  size="small"
                  options={PaidJob}
                  getOptionLabel={(option) => option.label}
                  value={PaidJob.find(
                    (paidJob) => paidJob.value === values?.paidJob
                  )}
                  onChange={(event, value) => {
                    handleChangeAutoComplete("paidJob", value?.value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="PaidJob" />
                  )}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-evenly"
              marginTop={1.5}
            >
              <Stack direction="column" sx={{ width: "76vw" }}>
                <label> Description</label>
                <textarea
                  placeholder="Description"
                  name="description"
                  rows={2}
                  className="Input"
                  value={values.description}
                  onChange={handleChange("description")}
                />
              </Stack>
            </Stack>
            <Typography
              sx={{
                fontSize: "25px",
                marginTop: "20px",
              }}
            >
              Company Details
            </Typography>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-evenly"
              marginTop={1}
            >
              <Stack direction="column">
                <label>Company Name: </label>
                <TextField
                  sx={{ width: "20vw" }}
                  size="small"
                  placeholder="Company Name"
                  value={values.companyName}
                  onChange={handleChange("companyName")}
                ></TextField>
              </Stack>
              <Stack direction="column">
                <label>Contact Person: </label>
                <TextField
                  sx={{ width: "16vw" }}
                  size="small"
                  placeholder="Contact Person"
                  value={values?.contactPerson}
                  onChange={handleChange("contactPerson")}
                />
              </Stack>
              <Stack direction="column">
                <label>Contact Number: </label>
                <TextField
                  sx={{ width: "12vw" }}
                  size="small"
                  placeholder="Contact Number"
                  value={values?.contactNumber}
                  onChange={handleChange("contactNumber")}
                />
              </Stack>

              <Stack direction="column">
                <label>Company Email: </label>
                <TextField
                  sx={{ width: "22vw" }}
                  size="small"
                  placeholder="Company Email"
                  value={values?.contactEmail}
                  onChange={handleChange("contactEmail")}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-evenly"
              marginTop={1}
            >
              <Stack direction="column">
                <label> Interview Address</label>
                <TextField
                  sx={{ width: "76vw" }}
                  size="small"
                  placeholder="Interview Address"
                  value={values?.interviewAddress}
                  onChange={handleChange("interviewAddress")}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{
                marginTop: "10px",
                justifyContent: "center",
              }}
            >
              <Button
                className="search-button"
                variant="contained"
                onClick={handleSubmit}
              >
                update
              </Button>
              <Button
                className="reset-button"
                variant="contained"
                onClick={closeContractJobEditModel}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}

const shiftTimings = [
  { label: "8 Hours", value: "8 Hours" },
  { label: "12 Hours", value: "12 Hours" },
];

const ShiftTimingsEight = [
  { label: "400", value: "400" },
  { label: "500", value: "500" },
  { label: "600", value: "600" },
  { label: "700", value: "700" },
  { label: "800", value: "800" },
  { label: "900", value: "900" },
  { label: "1000", value: "1000" },
];

const ShiftTimingsTwelve = [
  { label: "700", value: "700" },
  { label: "800", value: "800" },
  { label: "900", value: "900" },
  { label: "1000", value: "1000" },
  { label: "1100", value: "1100" },
  { label: "1200", value: "1200" },
  { label: "1300", value: "1300" },
  { label: "1400", value: "1400" },
];
const benefits = [
  { label: "Esi", value: "Esi" },
  { label: "PF", value: "PF" },
  { label: "Lodge", value: "Lodge" },
  { label: "Food", value: "Food" },
  { label: "Bonus", value: "Bonus" },
  { label: "Over Time", value: "Over Time" },
  { label: "Medical Health", value: "Medical Health" },
  { label: "Insurance", value: "Insurance" },
];

const Status = [
  { label: "Active", value: 1 },
  { label: "Inactive", value: 0 },
];

const numberOfEmployees = [
  { label: "10-20", value: "10-20" },
  { label: "20-30", value: "20-30" },
  { label: "30-40", value: "30-40" },
  { label: "40-50", value: "40-50" },
];

const PaidJob = [
  { label: 0, value: 0 },
  { label: 1, value: 1 },
];