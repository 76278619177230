import React, { useContext } from "react";
import {tableCellClasses,TableContainer,Paper,TableRow,Table,TableHead,TableBody,TableCell,} from "@mui/material";
import moment from "moment";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { jobApplicationContext } from "../../contexts/JobApplicationContext";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#233044",
    color: "#eeeeee",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default function JobApplicationTable({ pageNumber }) {
  const { jobApplicationData } = useContext(jobApplicationContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRecordId, setSelectedRecordId] = React.useState(null);
  const open = Boolean(anchorEl);
  let navigate = useNavigate();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <div className="TablePadding">
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left" style={{ maxWidth: "2vw" }}>
                #
              </StyledTableCell>
              <StyledTableCell style={{ maxWidth: "11vw" }} align="left">
                Company Name
              </StyledTableCell>
              <StyledTableCell style={{ maxWidth: "10vw" }} align="left">
                Job Title
              </StyledTableCell>
              <StyledTableCell style={{ maxWidth: "11vw" }} align="left">
                Job Location
              </StyledTableCell>
              <StyledTableCell style={{ maxWidth: "9vw" }} align="center">
                Mobile
              </StyledTableCell>
              <StyledTableCell style={{ maxWidth: "9vw" }} align="left">
                Profile
              </StyledTableCell>
              
              <StyledTableCell style={{ maxWidth: "9vw" }} align="left">
                 Location
              </StyledTableCell>
              <StyledTableCell style={{ maxWidth: "7vw" }} align="center">
                 Date
              </StyledTableCell>
              <StyledTableCell style={{ maxWidth: "5vw" }} align="center">
                Vacancy
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobApplicationData?.map((row, index) => (
              <StyledTableRow
                key={index}
                className="tableRow cell tableDataRow hove"
              >
                <StyledTableCell align="center" style={{ maxWidth: "4vw" }}>
                  {index + 1 + (pageNumber - 1) * 10}
                </StyledTableCell>
                <StyledTableCell align="left" style={{ maxWidth: "9vw" }}>
                  <div> {row?.job?.companyName}</div>
                </StyledTableCell>
                <StyledTableCell align="left" style={{ maxWidth: "11vw" }}>
                  <div> {row?.job?.name}</div>
                </StyledTableCell>
                <StyledTableCell align="left" style={{ maxWidth: "7vw" }}>
                  <div>{row?.job?.location?.location}</div>
                </StyledTableCell>
                <StyledTableCell align="center" style={{ maxWidth: "9vw" }}>
                  <div> {row?.user?.mobile}</div>
                </StyledTableCell>
                <StyledTableCell align="left" style={{ maxWidth: "9vw" }}>
                  <div> {row?.user?.profile?.name}</div>
                </StyledTableCell>
             
                <StyledTableCell align="left" style={{ maxWidth: "9vw" }}>
                  <div>
                    {row?.user?.workLocation
                      ? row?.user?.workLocation?.city
                      : row?.user?.workLocality
                      ? row?.user?.workLocality?.city
                      : row?.user?.location?.city}
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ fontSize: "14px", maxWidth: "14vw" }}
                >

                  {row?.createdAt
                    ? moment(row?.createdAt).format("D/MM/YY,h:mm:ss a")
                    : ""}

                </StyledTableCell>

                <StyledTableCell align="center">
                  <div> {row?.job?.jobVacancy}</div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
