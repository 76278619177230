import React, { createContext, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { backendUrl } from "../contants/contants";
export const orderContext = createContext({});
const OrderProvider = ({ children }) => {
  const [orderCount, setOrderCount] = useState(0);
  const [showSearchEmployer, setShowSearchEmployer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [OrderData, setOrderData] = useState([]);
  const [showUpdateOrder, setShowUpdateOrder] = useState(false);
  const [showOrderView, setShowOrderView] = useState(false);
  const [viewOrders, setViewOrders] = useState([]);
  const [showOrderEdit, setShowOrderEdit] = useState(false);
  const closeOrderEditModel = () => {
    setShowOrderEdit(false);
  };
  const showOrderEditModel = () => {
    setShowOrderEdit(true);
  };
  const closeOrderViewModel = () => {
    setShowOrderView(false);
  };
  const showOrderViewModel = () => {
    setShowOrderView(true);
  };
  const handleSearchOrder = (data) => {
    setLoading(true);
    axios
      .post(backendUrl + "/api/crm/order/list", data)
      .then((response) => {
        setShowSearchEmployer(false);
        setOrderData(response.data.data.order);
        setOrderCount(response.data.data.count);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const ordersView = (id) => {
    axios
      .get(backendUrl + "/api/crm/order?id=" + id)
      .then((response) => {
        setShowOrderView(true);
        setViewOrders(response.data.data.order);
       
      })
      .catch((e) => {});
  };

  const orderEdit = (id) => {
    axios
      .get(backendUrl + "/api/crm/order?id=" + id)
      .then((response) => {
        setViewOrders(response.data.data.order);
        setShowOrderEdit(true);
      })
      .catch((e) => {});
  };

  const handleUpdateOrder = (data, _id) => {
    axios
      .put(backendUrl + "/api/crm/order/update?id=" + _id, data)
      .then((response) => {
        setShowUpdateOrder(false);
        toast.success("Successfully Updated!");
      })
      .catch((e) => {});
  };
  return (
    <orderContext.Provider
      value={{
        loading,
        closeOrderViewModel,
        showOrderViewModel,
        showOrderView,
        showOrderEdit,
        showOrderEditModel,
        closeOrderEditModel,
        showUpdateOrder,
        handleUpdateOrder,
        ordersView,
        OrderData,
        viewOrders,
        orderCount,
        orderEdit,
        handleSearchOrder,
        showSearchEmployer,
      }}
    >
      {children}
    </orderContext.Provider>
  );
};
export default OrderProvider;
