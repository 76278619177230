import React, { useEffect, useContext, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  TableContainer,
  Grid,
  Box,
  DialogContent,
  DialogTitle,
  Slide,
  Dialog,
  Stack,
  Table,
  TableBody,
  Paper,
  Chip,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import { employeeContext } from "../../contexts/EmployeeContext";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
export default function EmployeeView() {
  const {
    employeeViewMore,
    appliedJob,
    closeEmployeeViewModel,
    showEmployeeView,
  } = useContext(employeeContext);
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={showEmployeeView}
      onClose={closeEmployeeViewModel}
      TransitionComponent={Transition}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          fontWeight: "bold",
          background: "rgb(35, 48, 68)",
          color: "White",
          textAlign: "center",
        }}
        id="customized-dialog-title"
      >
        EMPLOYEE DETAILS
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeEmployeeViewModel}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box sx={{ flexGrow: 1 }}>
          <Stack spacing={0.1}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Name:</p>
                  <p>{employeeViewMore?.basicInfo?.name} </p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Profile:</p>
                  <p> {employeeViewMore?.profile?.name}</p>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Mobile Number: </p>
                  <p>{employeeViewMore?.mobile}</p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Gender: </p>

                  <Chip
                    size="small"
                    sx={{
                      backgroundColor:
                        employeeViewMore?.basicInfo?.gender === 2
                          ? "#ff4c511f"
                          : "#16b1ff1f",
                      color:
                        employeeViewMore?.basicInfo?.gender === 2
                          ? "red"
                          : "#16b1ff",
                    }}
                    label={
                      employeeViewMore?.basicInfo?.gender === 2
                        ? "Female"
                        : "Male"
                    }
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Sub Profile:</p>
                  <p>{employeeViewMore?.subProfile?.name}</p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Location:</p>
                  <p>
                    {employeeViewMore?.workLocation
                      ? employeeViewMore?.workLocation?.city
                      : employeeViewMore?.workLocality
                      ? employeeViewMore?.workLocality?.city
                      : employeeViewMore?.location?.city}
                  </p>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Current Salary:</p>
                  <p> {employeeViewMore?.currentSalary}</p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Experience:</p>
                  <p>
                    {employeeViewMore?.experience > 1
                      ? employeeViewMore?.experience + " Years"
                      : employeeViewMore?.experience + " Year"}{" "}
                  </p>
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Created at: </p>
                  <p>
                    {employeeViewMore?.createdAt
                      ? moment(employeeViewMore?.createdAt).format("D/MM/YYYY")
                      : ""}
                  </p>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Updated at: </p>
                  <p>
                    {employeeViewMore?.updatedAt
                      ? moment(employeeViewMore?.updatedAt).format("D/MM/YYYY")
                      : ""}
                  </p>
                </Stack>
              </Grid>
            </Grid>
            {employeeViewMore.geo_with_lat_lon && (
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <Stack direction="row" spacing={0.6}>
                    <p className="Bold">Geo City :</p>
                    <p>{employeeViewMore?.geo_with_lat_lon?.city}</p>
                  </Stack>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Stack direction="row" spacing={0.6}>
                    <p className="Bold">Geo Address :</p>
                    <p>{employeeViewMore?.geo_with_lat_lon?.address}</p>
                  </Stack>
                </Grid>
              </Grid>
            )}
            <Grid container spacing={2}>
              <Grid item xs={6} md={10}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Skills:</p>

                  {employeeViewMore?.skills?.map((skill, index) => {
                    return (
                      <Chip
                        key={index}
                        size="small"
                        label={skill.name}
                        sx={{
                          backgroundColor: "#9155fd1f",
                          color: "#9155fd",
                          margin: "1px",
                        }}
                      />
                    );
                  })}
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={10}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Industry:</p>{" "}
                  <p>
                    {employeeViewMore?.industry?.map((industry, index) => {
                      return <div key={index}> {industry.name}</div>;
                    })}
                  </p>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6} className="Bold">
                Applied Jobs({appliedJob?.length})
              </Grid>
            </Grid>
          </Stack>
        </Box>

        <div className="TablePadding">
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      background: "rgb(35, 48, 68)",
                      color: "White",
                      textAlign: "center",
                      fontSize: "17px",
                    }}
                  >
                    #
                  </TableCell>
                  <TableCell
                    style={{
                      background: "rgb(35, 48, 68)",
                      color: "White",
                      textAlign: "left",
                      fontSize: "17px",
                    }}
                    align="center"
                  >
                    Job Name
                  </TableCell>
                  <TableCell
                    style={{
                      background: "rgb(35, 48, 68)",
                      color: "White",
                      textAlign: "left",
                      fontSize: "17px",
                    }}
                    align="center"
                  >
                    Company Name
                  </TableCell>
                  <TableCell
                    style={{
                      background: "rgb(35, 48, 68)",
                      color: "White",
                      textAlign: "center",
                      fontSize: "17px",
                    }}
                    align="center"
                  >
                    Applied on
                  </TableCell>
                  <TableCell
                    style={{
                      background: "rgb(35, 48, 68)",
                      color: "White",
                      textAlign: "center",
                      fontSize: "17px",
                    }}
                    align="center"
                  >
                    Job Link
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {appliedJob?.map((row, index) => {
                  return (
                    <TableRow key={index} className="tableRow">
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="left"> {row?.job?.name}</TableCell>
                      <TableCell align="left">
                        {row?.job?.companyName}
                      </TableCell>
                      <TableCell align="center">
                        {row?.createdAt
                          ? moment(row?.createdAt).format("D/MM/YYYY")
                          : ""}
                      </TableCell>
                      <TableCell align="center">
                        <Link
                          target="_blank"
                          to={
                            "https://www.theincircle.com/employerzone/viewjobpost/" +
                            row?.job._id
                          }
                        >
                          Job Link
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </DialogContent>
    </Dialog>
  );
}
