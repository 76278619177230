import React, { useContext } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {Dialog, DialogTitle,DialogContent,Grid,IconButton, Stack, Box, Chip, Slide } from "@mui/material";
import { industryContext } from "../../contexts/IndustryContext";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
export default function IndustriesView() {
  const { viewIndustries, closeIndustryViewModel, showIndustryView } =
    useContext(industryContext);
  return (
    <Dialog
      fullWidth
      open={showIndustryView}
      onClose={closeIndustryViewModel}
      aria-labelledby="customized-dialog-title"
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          fontWeight: "bold",
          background: "rgb(35, 48, 68)",
          color: "White",
          textAlign: "center",
        }}
        id="customized-dialog-title"
      >
        INDUSTRIES DETAILS
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={closeIndustryViewModel}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ width: 600, height: 300 }} dividers>
        <Box sx={{ flexGrow: 1 }}>
          <Stack spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={10}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Industry Name:</p>
                  <p> {viewIndustries?.name} </p>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={10}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Alias:</p>
                  <p> {viewIndustries?.alias} </p>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold"> Status: </p>

                  <Chip
                    size="small"
                    sx={{
                      backgroundColor:
                        viewIndustries?.status === 1 ? "#fce6dc" : "orange",
                      color: viewIndustries?.status === 1 ? "#cc6c19" : "#fff",
                    }}
                    label={viewIndustries?.status === 1 ? "Active" : "InActive"}
                  />
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack direction="row" spacing={0.6}>
                  <p className="Bold">Sort:</p>
                  <p>
                    {" "}
                    {viewIndustries?.sort ? viewIndustries?.sort : "null"}{" "}
                  </p>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
