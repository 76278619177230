import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Login from "../components/login";
import Dashboard from "../components/Dashboard";
import Employee from "../components/Employee/index";
import SmsLogs from "../components/SmsLogs";
import Industries from "../components/Industries";
import Skills from "../components/Skills";
import Orders from "../components/Orders";
import VideoUpload from "../components/VideoUpload";
import Employer from "../components/Employer";
import JobPosted from "../components/JobPosted";
import PendingJobPost from "../components/Pending";
import Location from "../components/Location";
import Plans from "../components/Plans";
import EmployeeOrder from "../components/EmployeeOrder";
import JobApplication from "../components/JobApplication";
import Feedback from "../components/Feedback";
import SearchResumeLogs from "../components/SearchResumeLogs";

export default function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<PrivateRoute component={Dashboard} />} />
        <Route path="/login" element={<PublicRoute component={Login} />} />
        <Route
          path="/employee"
          element={<PrivateRoute component={Employee} />}
        />
        <Route
          path="/dashboard"
          element={<PrivateRoute component={Dashboard} />}
        />
        <Route path="/smslogs" element={<PrivateRoute component={SmsLogs} />} />
        <Route
          path="/industries"
          element={<PrivateRoute component={Industries} />}
        />
        <Route path="/skill" element={<PrivateRoute component={Skills} />} />
        <Route path="/orders" element={<PrivateRoute component={Orders} />} />
        <Route
          path="/videoUpload"
          element={<PrivateRoute component={VideoUpload} />}
        />
        <Route
          path="/employer"
          element={<PrivateRoute component={Employer} />}
        />
        <Route
          path="/jobPosted"
          element={<PrivateRoute component={JobPosted} />}
        />
        <Route
          path="/pendingjobpost"
          element={<PrivateRoute component={PendingJobPost} />}
        />
        <Route
          path="/location"
          element={<PrivateRoute component={Location} />}
        />
        <Route path="/plans" element={<PrivateRoute component={Plans} />} />
        <Route
          path="/employeeOrder"
          element={<PrivateRoute component={EmployeeOrder} />}
        />
        <Route
          path="/jobApplication"
          element={<PrivateRoute component={JobApplication} />}
        />
        <Route
          path="/feedback"
          element={<PrivateRoute component={Feedback} />}
        />
        <Route
          path="/searchResumeLogs"
          element={<PrivateRoute component={SearchResumeLogs} />}
        />
      </Routes>
    </Router>
  );
}
