import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import {Paper,TableContainer,Table,TableHead,TableRow,tableCellClasses,TableBody,TableCell, Box,MenuItem, Menu, IconButton, Tooltip, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MoreVertIcon  from "@mui/icons-material/MoreVert";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import NotificationsIcon from "@mui/icons-material/Notifications";
import moment from "moment";
 
import { employeeContext } from "../../contexts/EmployeeContext";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#233044",
    color: "#eeeeee",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default function EmployeeTable({ pageNumber }) {
  const { employeeData, employeeView , employeeEdit} = useContext(employeeContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRecordId, setSelectedRecordId] = React.useState(null);

  const open = Boolean(anchorEl);
  let navigate = useNavigate();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <div className="TablePadding">
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          size="small"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell
                align="center"
                style={{ minWidth: "4vw", fontSize: "16px" }}
              >
                #
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{ minWidth: "15vw", fontSize: "16px" }}
              >
                Name
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{ minWidth: "10vw", fontSize: "16px" }}
              >
                Number
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{ minWidth: "11vw", fontSize: "16px" }}
              >
                Profile
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{ minWidth: "11vw", fontSize: "16px" }}
              >
                SubProfile
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{ minWidth: "9vw", fontSize: "16px" }}
              >
                Location
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ minWidth: "7vw", fontSize: "16px" }}
              >
                Created Date
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ minWidth: "5vw", fontSize: "16px" }}
              >
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {employeeData?.map((row, index) => (
              <StyledTableRow
                className="tableRow cell tableDataRow hove"
                key={index}
              >
                <StyledTableCell align="center" style={{ minWidth: "4vw" }}>
                  {index + 1 + (pageNumber - 1) * 10}
                </StyledTableCell>
                <StyledTableCell align="left" style={{ minWidth: "15vw" }}>
                  <div>{row?.basicInfo?.name}</div>
                </StyledTableCell>
                <StyledTableCell align="left" style={{ minWidth: "10vw" }}>
                  <div>{row?.mobile}</div>
                </StyledTableCell>
                <StyledTableCell align="left" style={{ minWidth: "11vw" }}>
                  <div> {row?.profile?.name}</div>
                </StyledTableCell>
                <StyledTableCell align="left" style={{ minWidth: "11vw" }}>
                  <div>{row?.subProfile?.name}</div>
                </StyledTableCell>
                <StyledTableCell align="left" style={{ minWidth: "9vw" }}>
                  <div>
                    {row?.workLocation
                      ? row?.workLocation?.city
                      : row?.workLocality
                      ? row?.workLocality?.city
                      : row?.location?.city}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center" style={{ maxWidth: "4vw" }}>
                  {row?.createdAt
                    ? moment(row?.createdAt).format("D/MM/YYYY")
                    : ""}
                </StyledTableCell>
                <StyledTableCell align="center" style={{ minWidth: "5vw" }}>
                  <div>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Tooltip title="Action">
                        <IconButton
                          onClick={(event) => {
                            handleMenu(event);
                            setSelectedRecordId(row?._id);
                          }}
                          size="small"
                          sx={{ ml: 2 }}
                          aria-controls={open ? "account-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      keepMounted
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 1px 1px rgb(211, 211, 211))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            left: 30,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{
                        horizontal: "center",
                        vertical: "top",
                      }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      <MenuItem
                        sx={{ color: "rgb(35, 48, 68)" }}
                        onClick={() => {
                          handleClose();
                          employeeView(selectedRecordId);
                        }}
                      >
                        <SendIcon
                          sx={{ color: "#5072A7", marginRight: "10px" }}
                        />
                        View
                      </MenuItem>

                      <MenuItem
                          sx={{ color: "rgb(35, 48, 68)" }}
                          onClick={() => {
                            employeeEdit(selectedRecordId);
                            handleClose();
                          }}
                        >
                          <EditIcon
                            sx={{ color: "#228B22", marginRight: "10px" }}
                          />
                          Edit
                        </MenuItem>
                    </Menu>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
