import React, { createContext, useState } from "react";
import axios from "axios";
import { backendUrl } from "../contants/contants";
import { toast } from "react-toastify";

export const employeeContext = createContext({});

const EmployeeProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [appliedJob, setAppliedJob] = useState([]);
  const [showEmployeeView, setShowEmployeeView] = useState(false);
  const [showEmployeeEdit, setShowEmployeeEdit] = useState(false);
  const [viewEmployee, setViewEmployee] = useState({});
  const [showSearchEmployee, setShowSearchEmployee] = useState(false);
  const [employeeCount, setEmployeeCount] = useState(0);
  const [employeeViewMore, setEmployeeViewMore] = useState([]);

  const showSearchEmployeeModel = () => {
    setShowSearchEmployee(true);
  };

  const closeSearchEmployeeModel = () => {
    setShowSearchEmployee(false);
  };

  const closeEmployeeViewModel = () => {
    setShowEmployeeView(false);
  };

  const closeEmployeeEditModel = () => {
    setShowEmployeeEdit(false);
  };

  const showEmployeeViewModel = () => {
    setShowEmployeeView(true);
  };

  const handleSearchEmployee = (data) => {
    const updatedData = {
      ...data,
      skills: data?.skills.map((skill) => skill?._id),
    };
    setLoading(true);
    axios
      .post(backendUrl + "/api/crm/employee/list", updatedData)
      .then((response) => {
        setShowSearchEmployee(false);
        setEmployeeData(response.data.data.employee);
        setEmployeeCount(response.data.data.count);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const handleEditEmployee = (data, id, handleSearch) => {
    // const updatedData = {
    //   ...data,
    //   skills: data?.skills.map((skill) => skill?._id),
    // };
    setLoading(true);
    console.log('data : ',data)
    axios
      .put(backendUrl + "/api/crm/employee?id=" + id, data)
      .then((response) => {
        setShowEmployeeEdit(false);
        setLoading(false);
        handleSearch()
        toast.success("Successfully Updated!");
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const employeeView = (id) => {
    axios
      .get(backendUrl + "/api/crm/employee?id=" + id)
      .then((response) => {
        setShowEmployeeView(true);
        setEmployeeViewMore(response.data.data.employee);
        setAppliedJob(response.data.data.employeeAppliedJobList);
      })
      .catch((e) => {});
  };

  const employeeEdit = (id) => {
    axios
      .get(backendUrl + "/api/crm/employee?id=" + id)
      .then((response) => {
        setShowEmployeeEdit(true);
        setViewEmployee(response.data.data.employee);
      })
      .catch((e) => {});
  };

  return (
    <employeeContext.Provider
      value={{
        loading,
        appliedJob,
        employeeViewMore,
        handleSearchEmployee,
        employeeData,
        employeeCount,
        employeeView,
        showSearchEmployee,
        showSearchEmployeeModel,
        closeSearchEmployeeModel,
        closeEmployeeViewModel,
        showEmployeeViewModel,
        showEmployeeView,
        showEmployeeEdit,
        closeEmployeeEditModel,
        viewEmployee,
        handleEditEmployee,
        employeeEdit,
      }}
    >
      {children}
    </employeeContext.Provider>
  );
};
export default EmployeeProvider;
