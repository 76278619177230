import React, { useEffect, useContext, useState } from "react";
import { CircularProgress,Button, Box, Paper ,Stack,Typography,Pagination ,TextField} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import SmsTable from "./Table";
import LoadingButton from "@mui/lab/LoadingButton";
import SmsView from "./SmsView";
import { smsLogsContext } from "../../contexts/SmsLogsContext";

export default function SmsLogs() {
  const { smsCount, handleSearchSms, loading } = useContext( smsLogsContext );
  const [values, setValues] = useState({
    fromDate: null,
    toDate: null,
    contactNumber: "",
    page: 1,
  });

  useEffect(() => {
    handleSearchSms({ ...values });
  }, []);

  const handleChange = (prop) => (event) => {
    if (prop === "fromDate" || prop === "toDate") {
      setValues({ ...values, [prop]: event });
    } else {
      setValues({ ...values, [prop]: event.target.value });
    }
  };

  const handlePageChange = (event, value) => {
    setValues({ ...values, page: value });
    handleSearchSms({ ...values, page: value });
  };

  const handleReset = () => {
    setValues({
      fromDate: null,
      toDate: null,
      contactNumber: "",
      mobileNumber: "",
      page: 1,
    });
    handleSearchSms({
      fromDate: null,
      toDate: null,
      contactNumber: "",
      mobileNumber: "",
      page: 1,
    });
  };

  const handleEnterKeyPress = (event, value) => {
    if (event.key === "Enter") {
      handleSearchSms({ ...values, page: value });
    }
  };

  return (
    <div>
      <Paper
        sx={{
          textAlign: "center",
          padding: "5px",
        }}
      >
        <Box
          sx={{ justifyContent: "center", borderRadius: "5px" }}
          className="heading"
        >
          <Typography variant="h5">
            SMS Logs(
            {smsCount ? parseInt(smsCount).toLocaleString() : "NA"})
          </Typography>
        </Box>

        <Stack
          className="SearchButton"
          spacing={2}
          direction="row"
          justifyContent={"flex-middle"}
          marginBottom={1}
          marginTop={1}
        >
          <DesktopDatePicker
            disabled={loading}
            sx={{
              width: "12vw",
              textAlign: "center",
              justifyContent: "center",
              marginLeft: "10px",
            }}
            value={values.fromDate}
            onChange={handleChange("fromDate")}
            label="From*"
            // inputFormat="DD-MM-YYYY"
            format="DD/MM/YYYY"
            maxDate={values.toDate}
            slotProps={{ textField: { size: "small" } }}
            onKeyPress={handleEnterKeyPress}
          />

          <DesktopDatePicker
            disabled={loading}
            sx={{
              width: "12vw",
              textAlign: "center",
              justifyContent: "center",
              marginLeft: "10px",
            }}
            value={values.toDate}
            onChange={handleChange("toDate")}
            label="To*"
            format="DD/MM/YYYY"
            minDate={values.fromDate}
            slotProps={{ textField: { size: "small" } }}
            onKeyPress={handleEnterKeyPress}
          />
          <TextField
            disabled={loading}
            size="small"
            sx={{
              width: "12vw",
              textAlign: "center",
              justifyContent: "center",
              marginRight: "20px",
            }}
            label="Mobile Number"
            value={values.mobileNumber}
            onChange={handleChange("mobileNumber")}
            onKeyDown={handleEnterKeyPress}
          />

          <TextField
            disabled={loading}
            size="small"
            sx={{
              width: "12vw",
              textAlign: "center",
              justifyContent: "center",
              marginRight: "20px",
            }}
            label="Contact Number"
            value={values.contactNumber}
            onChange={handleChange("contactNumber")}
            onKeyDown={handleEnterKeyPress}
          />
          <LoadingButton
            loading={loading}
            loadingPosition="center"
            onClick={() => {
              setValues({ ...values, page : 1})
              handleSearchSms({ ...values, page: 1 });
            }}
            variant="contained"
            className="search-button"
          >
            Search
          </LoadingButton>
          <Button
            variant="contained"
            className="reset-button"
            onClick={handleReset}
          >
            Reset
          </Button>
        </Stack>

        {loading ? (
          <CircularProgress
            sx={{ marginTop: "15%", marginBottom: "14%" }}
            loading={"true"}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <SmsTable pageNumber={values.page} />
        )}

        <Pagination
          sx={{
            marginLeft: "10px",
            marginRight: "10px",
            marginTop: "10px",
            paddingBottom: "10px",
            "Button.MuiPaginationItem-rounded.Mui-selected": {
              bgcolor: "rgb(35, 48, 68)",
              color: "rgb(238, 238, 238)",
            },
          }}
          count={parseInt(smsCount / 10) + (smsCount % 10 > 0 ? 1 : 0)}
          page={values.page}
          onChange={handlePageChange}
          shape="rounded"
          variant="outlined"
        />
      </Paper>
      <SmsView />
    </div>
  );
}
